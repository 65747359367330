<template>
  <div v-if="isLoaded" class="workDetailPage">

    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />
    <header-component type='serviceDetail'
                      class='header--workDetail header--overlay'
                      :title=state.largeTitle
                      :image="state.bannerImage ? state.bannerImage : null"
                      :video="state.bannerVideo ? state.bannerVideo : null"
                      :code="state.bannerVideoCode ? state.bannerVideoCode : null"
                      :services=state.caseStudyServicesString
                      :intro=state.smallTitle
                      :class="{'header--overlay--solid': state.caseStudyData.overlay}"
                      id="header">
    </header-component>
    <main>
      <ModalComponent v-show="state.isModalVisible" @close-modal="state.playlist.stop(); state.isModalVisible = false">
        <template v-slot:body>
          <div :class=state.jsonData.content.showreel.colour>
          </div>
        </template>
      </ModalComponent>
      <div class="workDetailPage__intro">
        <work-details :caseStudySummary=caseStudyContentSummary
                      :style="{background: state.caseStudyGradient}">
        </work-details>
        <h1 :style="{color: state.caseStudyColor2}">
          {{ state.caseStudyData.case_study }}
        </h1>
      </div>

      <div class="workDetailPage__content">
        <template v-for="(contentItem, index) in state.caseStudyContent">
          <p v-if="contentItem.form">{{contentItem}}</p>

          <!-- CIRCLES (COLOURED / WHITE LAYOUTS) -->
          <div v-if="contentItem.circles" class="component-background">
            <div class="workDetailPage__circles"
                 :class="{'workDetailPage__circles--nopaddingtop': contentItem.circles.layout === 'coloured'}">
              <div :style="{paddingBottom: contentItem.circles.spacing + 'px'}">
                <div class="workDetailPage__circles__intro">
                  <h2 v-if="contentItem.circles.title">{{ contentItem.circles.title }}</h2>
                  <div v-if="contentItem.circles.text" v-html="contentItem.circles.text"></div>
                </div>
                <circles :circlesListItems="contentItem.circles.content"
                         :layout="contentItem.circles.layout"
                         :animation="contentItem.circles.animation"
                         :itemIndex="index"
                         :colours=contentItem.circles.colours>
                </circles>
                <svg v-if="contentItem.circles.layout == 'coloured'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 430.52 408.158" :id="'svgGroup9c_' + index" class="svgGroup9c">
                  <g>
                    <path name="path3" :id="'svgGroup9c_' + index + '__path3'" class="svgpath svgpath--mist" d="M204.1,204.1C92.7,204.1,2.5,294.3,2.5,405.7" />
                    <path name="path2" :id="'svgGroup9c_' + index + '__path2'" class="svgpath svgpath--fuschia" d="M428,2.5c0.1,53.5-21.2,104.8-59,142.5c-37.8,37.9-89.1,59.1-142.5,59" />
                    <path name="path1" :id="'svgGroup9c_' + index + '__path1'" class="svgpath svgpath--green" d="M95.3,133.6c72.4,72.4,189.8,72.4,262.3,0" />
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <!-- CTA -->
          <div v-if="contentItem.cta" class="workDetailPage__cta component-background">
            <div class="workDetailPage__cta__col1">
              <h2 v-if="contentItem.cta.accompanying.title" class="workDetailPage__cta__title">
                {{ contentItem.cta.accompanying.title }}
              </h2>
              <div class="workDetailPage__cta__text" v-html=contentItem.cta.accompanying.textarea>
              </div>
            </div>
            <div class="workDetailPage__cta__col2">
              <a :href=contentItem.cta[0].url target="_blank" rel="noreferrer noopener" class="outside_the_box">
                <div class="workDetailPage__cta__col2__wrapper" :style="{background: state.caseStudyGradient}">
                  <p v-html=contentItem.cta[0].text class="workDetailPage__cta__desc"></p>
                  <a :href=contentItem.cta[0].url target="_blank"
                     :style="{color: (contentItem.cta.link_colour) ? contentItem.cta.link_colour : '#e728ad'}"
                     rel="noreferrer noopener">Click here</a>

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.2 221.1" :id="'svgGroup47_' + index"
                       class="svgGroup47">
                    <g>
                      <path :id="'svgGroup47_' + index + '__path2'" class="svgpath svgpath--mist"
                            d="M4.2,4.7c62.1-4.9,123.2,17.7,167.2,61.8" />
                      <path :id="'svgGroup47_' + index + '__path1'" class="svgpath svgpath--fuschia"
                            d="M4.2,4.7C36.5,2.2,68.9,7.1,99.1,19" />
                      <path :id="'svgGroup47_' + index + '__path4'" class="svgpath svgpath--mist"
                            d="M22.6,19c74.5,0,142.6,41.9,176.2,108.3c14,27.6,21.3,58.2,21.2,89.1" />
                      <path :id="'svgGroup47_' + index + '__path3'" class="svgpath svgpath--green"
                            d="M42.9,20c45.1,4.6,87.3,24.7,119.3,56.8" />
                    </g>
                  </svg>
                </div>
              </a>
            </div>
          </div>

          <!-- GALLERY -->
          <div v-if="contentItem.gallery" class="component-background">
            <ul class="workDetailPage__gallery">
              <li v-for="(value, index) in contentItem.gallery.images"
                  class="workDetailPage__gallery__galleryItem"
                  :class="{'workDetailPage__gallery__galleryItem--two': contentItem.gallery.images_per_row == '2', 'workDetailPage__gallery__galleryItem--three': contentItem.gallery.images_per_row == '3'}">
                <picture-sources v-if="value && value.sizes && value.sizes.length"
                                 :targetWidth="window.innerWidth <= mobile ? 100 : window.innerWidth <= tablet ? 80 : 25"
                                 :sizes="value.sizes"
                                 :imgSrc="value.guid"
                                 :imgAlt="value.alt"
                                 imgClass="objectFitImage" />
              </li>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.3 549.8" :id="'svgGroup45_' + index"
                   class="svgGroup45">
                <g>
                  <path name="path2" :id="'svgGroup45_' + index + '__path2'" class="svgpath svgpath--green"
                        d="M17.8,72v473.5" />
                  <path name="path1" :id="'svgGroup45_' + index + '__path1'" class="svgpath svgpath--mist"
                        d="M4.8,4v166.4" />
                  <path name="path3" :id="'svgGroup45_' + index + '__path3'" class="svgpath svgpath--fuschia"
                        d="M4.3,111.5v81.3" />
                </g>
              </svg>
            </ul>
          </div>

          <!-- IMAGE -->
          <div v-if="contentItem.image" class="component-background">
            <div class="workDetailPage__image">
              <picture-sources v-if="contentItem.image.value && contentItem.image.value.sizes && contentItem.image.value.sizes.length"
                               :targetWidth="100"
                               :sizes="contentItem.image.value.sizes"
                               :imgSrc="contentItem.image.value.guid"
                               :imgAlt="state.caseStudyData.case_study"
                               imgClass="objectFitImage"
                               :imagefit="(contentItem.image.blurred === '1') ? 'width:unset;height:unset;' : (contentItem.image.stretch === 'width') ? 'width:unset;' : (contentItem.image.stretch === 'height') ? 'height:unset;' : ''" />
              <svg v-if="contentItem.image.animation && contentItem.image.animation.includes('Animation 1') && window.innerWidth >= state.desktop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 232.975 733.041" :id="'svgGroup42_' + index" class="svgGroup42">
                <g>
                  <path :id="'svgGroup42_' + index + '__path8'" class="svgpath svgpath--fuschia" d="M84.6,338.2v192.6" />
                  <path :id="'svgGroup42_' + index + '__path7'" class="svgpath svgpath--mist" d="M231.2,2.9c-97.3,18.3-171,103.7-171,206.3v312.9" />
                </g>
                <g>
                  <path :id="'svgGroup42_' + index + '__path1'" class="svgpath svgpath--mist" d="M231.5,2.9c-97.3,18.3-171,103.7-171,206.3V731" />
                  <path :id="'svgGroup42_' + index + '__path2'" class="svgpath svgpath--fuschia" d="M3.5,54.9c49.6,39.8,78.4,100,78.3,163.6v53.7" />
                  <path :id="'svgGroup42_' + index + '__path6'" class="svgpath svgpath--fuschia" d="M60.5,562.2v144" />
                  <path :id="'svgGroup42_' + index + '__path4'" class="svgpath svgpath--fuschia" d="M97.7,90c-24.3,35-37.2,76.7-37.2,119.3v102.8" />
                  <path :id="'svgGroup42_' + index + '__path5'" class="svgpath svgpath--fuschia" d="M84.9,368.2v256.7" />
                  <path :id="'svgGroup42_' + index + '__path3'" class="svgpath svgpath--green" d="M218.4,18c-59,18.4-106.9,61.9-131,118.8c-10.9,25.9-16.5,53.7-16.5,81.7v265.6" />
                </g>
              </svg>
              <svg v-if="contentItem.image.animation && contentItem.image.animation.includes('Animation 2') && window.innerWidth >= state.desktop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335.7 762.3" :id="'svgGroup43_' + index" class="svgGroup43">
                <g>
                  <path :id="'svgGroup43_' + index + '__path4'" class="svgpath svgpath--mist" d="M159.2,361.5c-85.6,0-155,69.4-155,155v241" />
                  <path :id="'svgGroup43_' + index + '__path1'" class="svgpath svgpath--mist" d="M331.2,2.5v203.8c0,85.7-69.4,155.2-155,155.2" />
                  <path :id="'svgGroup43_' + index + '__path2'" class="svgpath svgpath--green" d="M159.2,361.5c-85.6,0-155,69.4-155,155" />
                  <path :id="'svgGroup43_' + index + '__path3'" class="svgpath svgpath--fuschia" d="M58.2,415.5c55.5-55.8,145.7-56,201.4-0.6c0.2,0.2,0.4,0.4,0.6,0.6" />
                </g>
              </svg>
              <svg v-if="contentItem.image.animation && contentItem.image.animation.includes('Animation 3') && window.innerWidth >= state.desktop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.4 1752.3" :id="'svgGroup44_' + index" class="svgGroup44">
                <g>
                  <path :id="'svgGroup44_' + index + '__path1'" class="svgpath svgpath--mist" d="M175.4,4.3C78.1,22.6,4.5,108,4.5,210.7v587.9" />
                  <path :id="'svgGroup44_' + index + '__path5'" class="svgpath svgpath--green" d="M4.5,563.6v144" />
                  <path :id="'svgGroup44_' + index + '__path7'" class="svgpath svgpath--mist" d="M4.3,1187.7v559.8" />
                  <path :id="'svgGroup44_' + index + '__path3'" class="svgpath svgpath--fuschia" d="M41.6,91.4C17.4,126.4,4.4,168,4.5,210.7v102.8" />
                  <path :id="'svgGroup44_' + index + '__path4'" class="svgpath svgpath--fuschia" d="M15.3,518.1v338.7" />
                  <path :id="'svgGroup44_' + index + '__path6'" class="svgpath svgpath--green" d="M14.8,956.2v561" />
                  <path :id="'svgGroup44_' + index + '__path2'" class="svgpath svgpath--fuschia" d="M162.4,19.3c-59,18.4-106.9,61.9-131,118.8c-10.9,25.9-16.5,53.7-16.5,81.7v265.6" />
                  <path :id="'svgGroup44_' + index + '__path8'" class="svgpath svgpath--fuschia" d="M4.3,821.7v376.5" />
                </g>
              </svg>
            </div>
          </div>

          <!-- LIST NO LONGER USED-->
          <div v-if="contentItem.list" class="component-background">
            <div class="workDetailPage__listWrapper">
              <div :style="{paddingBottom: contentItem.spacing + 'px'}">
                <h2>{{ contentItem.list.introduction }}</h2>
                <ul class="outputList">
                  <li v-for="(value, index) in contentItem.items"
                      class="outputList__item"
                      :key="index">
                    <a v-if="!!value.link" :href="value.link" :title="value.item" :style="{color: ((index % 2) === 0) ? caseStudyColor1 :caseStudyColor2 }">{{ value.item }}</a>
                    <span v-else :style="{color: ((index % 2) === 0) ? caseStudyColor1 :caseStudyColor2 }">{{ value.item }}</span>
                    &nbsp;
                  </li>
                </ul>
                <!--<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 316.9 277.9" :id="'svgGroup58_' + index" class="svgGroup58">
              <g>
                  <path name="path1" :id="'svgGroup58_' + index + '__path1'" class="svgpath svgpath--mist" d="M2.5,2.5h53.4c0,0,0,0,0,0c71.4,0,129.3,57.9,129.3,129.3"/>
                  <path name="path3" :id="'svgGroup58_' + index + '__path3'" class="svgpath svgpath--fuschia" d="M185.1,146.1c0,71.4,57.9,129.3,129.3,129.3c0,0,0,0,0,0"/>
                  <path name="path2" :id="'svgGroup58_' + index + '__path2'" class="svgpath svgpath--green" d="M230.3,62c-16.8,16.7-28.2,38.1-32.7,61.4"/>
              </g>
            </svg>-->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 174 967.7" :id="'svgGroup61_' + index" class="svgGroup61" style="height:50rem">
                  <g>
                    <path name="path3" :id="'svgGroup61_' + index + '__path3'" class="svgpath svgpath--mist" d="M16.5,2.5v809.9v-2.2c0,85.6,69.4,155,155,155" />
                    <path name="path1" :id="'svgGroup61_' + index + '__path1'" class="svgpath svgpath--fuschia" d="M30.5,215.2v376.5" />
                    <path name="path2" :id="'svgGroup61_' + index + '__path2'" class="svgpath svgpath--green" d="M2.5,312.9v116.8" />
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <!-- QUOTE WALL -->
          <div v-if="contentItem.quote_wall" class="component-background">
            <div class="carousel carousel--quotes workDetailPage__quoteWall"
                 :class="{'carousel--quotes--addMarginRight' : contentItem.quote_wall.row == 2}">
              <h2 v-if="contentItem.quote_wall.title">{{ contentItem.quote_wall.title }}</h2>
              <div class="workDetailPage__quoteWall__text" v-html=contentItem.quote_wall.text></div>
              <div class="workDetailPage__quoteWall__sliderWrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 493.2 703.6" :id="'svgGroup48_' + index" class="svgGroup48">
                  <g>
                    <path :id="'svgGroup48_' + index + '__path4'" class="svgpath svgpath--mist" d="M316,231.2c-85.6,0-155,69.4-155,155c0,0,0,0,0,0v160.6" />
                    <path :id="'svgGroup48_' + index + '__path1'" class="svgpath svgpath--mist" d="M488.2,6.1v70.1c0,85.6-69.4,155-155,155" />
                    <path :id="'svgGroup48_' + index + '__path2'" class="svgpath svgpath--fuschia" d="M316,231.2c-85.6,0-155,69.4-155,155c0,0,0,0,0,0" />
                    <path :id="'svgGroup48_' + index + '__path3'" class="svgpath svgpath--green" d="M215.2,285.4c55.7-55.7,146-55.7,201.7,0" />
                    <path :id="'svgGroup48_' + index + '__path5'" class="svgpath svgpath--fuschia" d="M161.2,542.9c0.1,34-11.1,67.1-31.8,94.1c-29.3,38.4-74.9,61-123.2,60.9" />
                  </g>
                </svg>
                <carousel ref="slick"
                          :items-to-show="contentItem.quote_wall.row && contentItem.quote_wall.row == 2 ? state.slickOptions2.itemsToShow : state.slickOptions.itemsToShow"
                          :snap-align="contentItem.quote_wall.row && contentItem.quote_wall.row == 2 ? state.slickOptions2.snapAlign : state.slickOptions.snapAlign"
                          :breakpoints="contentItem.quote_wall.row && contentItem.quote_wall.row == 2 ? state.slickOptions2.breakpoints : state.slickOptions.breakpoints"
                          aria-hidden="null">
                  <slide v-for="(value, index) in contentItem.quote_wall.quotes"
                         class="carousel--quotes__slide">
                    <div class="carousel--quotes__slide__wrapper"
                         :style="[contentItem.quote_wall.gradient ? { background: state.caseStudyGradient} : { backgroundColor: getQuoteIndex(index, contentItem.quote_wall.colours)}]">
                      <div class="carousel--quotes__slide__info">
                        <h3 class="carousel--quotes__slide__info__heading">{{ value.source }}</h3>
                        <p>{{ value.quote }}</p>
                      </div>
                    </div>
                  </slide>
                </carousel>
              </div>
              <!--<img src="/src/assets/drag-arrows-grey.svg" alt="Drag Slider Icon" class="workDetailPage__quoteWall__dragIcon">-->
            </div>
          </div>

          <!-- STATISTICS -->
          <div v-if="contentItem.statistics" class="component-background">
            <div class="workDetailPage__statistics">
              <h2 v-if="contentItem.statistics.intro.title">{{ contentItem.statistics.intro.title }}</h2>
              <div class="workDetailPage__statistics__text" v-html=contentItem.statistics.intro.text></div>
              <ul class="workDetailPage__statistics__list"
                  :class="{'workDetailPage__statistics__list--four': contentItem.statistics.intro.num == '4', 'workDetailPage__statistics__list--three': contentItem.statistics.intro.num == '3'}">
                <li v-for="(value, index) in contentItem.statistics.stats"
                    class="workDetailPage__statistics__list__item">
                  <span :style="{color: state.caseStudyColor2}">{{ value.stat }}</span>
                  <span>{{ value.description }}</span>
                </li>
              </ul>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 321.4 282.3" :id="'svgGroup46_' + index" class="svgGroup46">
                <g>
                  <path :id="'svgGroup46_' + index + '__path1'" class="svgpath svgpath--mist" d="M4.4,4.6h53.3l0,0C129.1,4.6,187,62.5,187,133.8" />
                  <path :id="'svgGroup46_' + index + '__path3'" class="svgpath svgpath--fuschia" d="M187,148.2c0,71.4,57.9,129.3,129.3,129.3l0,0" />
                  <path :id="'svgGroup46_' + index + '__path2'" class="svgpath svgpath--green" d="M232.2,64.1c-16.8,16.8-28.2,38.1-32.7,61.4" />
                </g>
              </svg>
            </div>
          </div>

          <!-- TABLE -->
          <div v-if="contentItem.table"
               class="component-background"
               :style="{backgroundImage: 'url(' + contentItem.table.background_image.guid + ')'}">
            <div class="workDetailPage__listWrapper">
              <ul :class="{ 'workDetailPage__list--row' : contentItem.table.grid }"
                  class="workDetailPage__list">
                <li class="workDetailPage__list__listItem"
                    v-for="value in contentItem.table.rows">
                  <h3 :style="{color: state.caseStudyColor1}">{{ value.title }}</h3>
                  <div class="workDetailPage__list__listItem__text" v-html=value.content></div>
                  <picture-sources v-if="value.icon && value.icon.sizes && value.icon.sizes.length"
                                   :targetWidth="20"
                                   :sizes="value.icon.sizes"
                                   :imgSrc="value.icon.guid"
                                   :imgAlt="value.alt" />
                </li>
              </ul>
            </div>
          </div>

          <!-- TESTIMONAL -->
          <div v-if="contentItem.testimonial"
               class="component-background"
               :style="{ background: state.caseStudyGradient }">
            <div class="workDetailPage__testimonial">
              <div class="workDetailPage__testimonial__quotes">
                <div class="workDetailPage__testimonial__quotes__wrapper">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 321 483.2" :id="'svgGroup53_' + index" class="svgGroup53">
                    <g>
                      <path :id="'svgGroup53_' + index + '__path1'" class="svgpath svgpath--mist" d="M316.1,4.9v158.9c0,83.1-67.3,150.4-150.4,150.4c0,0,0,0,0,0h-52.3" />
                      <path :id="'svgGroup53_' + index + '__path2'" class="svgpath svgpath--green" d="M99.9,314.2c-25.2,0-49.4,10-67.2,27.8c-37.1,37.1-37.1,97.3,0,134.4c0,0,0,0,0,0" />
                      <path :id="'svgGroup53_' + index + '__path3'" class="svgpath svgpath--fuschia" d="M148.5,339.6c-38.7-25.8-91-15.4-116.8,23.3c-9.2,13.8-14.1,30-14.2,46.5" />
                    </g>
                  </svg>
                  <div class="workDetailPage__testimonial__quotes__circle"
                       :style="{ background: state.caseStudyGradient }">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.5 36.7" :id="'svgGroup54_' + index" class="svgGroup54">
                      <g>
                        <path :id="'svgGroup54_' + index + '__path1'" class="svgpath svgpath--white" d="M10.6,19.9c-4.7,0-8.5-3.9-8.5-8.6c0-4.7,3.8-8.6,8.5-8.6c4.7,0,8.2,3.9,8.5,8.6
                      C20.1,31.8,8.2,34.7,8.2,34.7v-4.9c5.2-2.2,4.5-10.1,4.5-10.1C12,19.9,11.3,19.9,10.6,19.9z" />
                        <path :id="'svgGroup54_' + index + '__path2'" class="svgpath svgpath--white" d="M33.6,19.9c-4.7,0-8.5-3.9-8.5-8.6c0-4.7,3.8-8.6,8.5-8.6c4.7,0,8.2,3.9,8.5,8.6
                      c1.1,20.4-10.9,23.4-10.9,23.4v-4.9c5.2-2.2,4.5-10.1,4.5-10.1C35,19.9,34.3,19.9,33.6,19.9z" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="workDetailPage__testimonial__wrapper">
                <div v-if=contentItem.testimonial[0].image class="workDetailPage__testimonial__imageWrapper">

                  <picture-sources v-if="contentItem.testimonial[0].image && contentItem.testimonial[0].image.sizes && contentItem.testimonial[0].image.sizes.length"
                                   :targetWidth="window.innerWidth <= mobile ? 20 : window.innerWidth <= tablet ? 20 : 80"
                                   :sizes="contentItem.testimonial[0].image.sizes"
                                   :imgSrc="contentItem.testimonial[0].image.guid"
                                   imgClass="objectFitImage"
                                   :imgAlt="contentItem.testimonial[0].image.alt" />

                </div>
                <div class="workDetailPage__testimonial__info">
                  <h2 v-if="contentItem.testimonial[0].hide < 1" class="workDetailPage__testimonial__info__title">client testimonial</h2>
                  <p class="workDetailPage__testimonial__info__text">{{ contentItem.testimonial[0].text }}</p>
                  <span class="workDetailPage__testimonial__info__name">{{ contentItem.testimonial[0].name }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- TEXT_BLOCK -->
          <div v-if="contentItem.text_block" class="component-background">
            <div class="workDetailPage__textBlock" v-html="contentItem.text_block.value"></div>
          </div>

          <!-- VIDEO -->
          <div v-if="contentItem.video" class="component-background">
            <div class="workDetailPage__video" itemscope itemtype="https://schema.org/VideoObject">
              <meta itemprop="contentUrl" :content="contentItem.video.url" />
              <div v-if="contentItem.video.code" style="position:relative; overflow:hidden;" :class=contentItem.video.colour>
                <div style="position:relative;overflow:hidden;padding-bottom:56.25%">
                  <iframe :src="'https://cdn.jwplayer.com/players/'+contentItem.video.code+'-p3ckDi0X.html'" width="100%" height="100%" style="position:absolute;" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>

          <!-- VIDEO TESTIMONAL -->
          <div v-if="contentItem.video_testimonial"
               class="workDetailPage__testimonial component-background"
               :style="{ background: state.caseStudyGradient}">


            <div class="workDetailPage__testimonial__button">
              <div class="workDetailPage__testimonial__button__wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 218 976" :id="'svgGroup55_' + index" class="svgGroup55">
                  <g>
                    <!-- <path :id="'svgGroup55_' + index + '__path1'" class="svgpath svgpath--mist" d="M7.5,6.5v809.9v-2.2c0,85.6,69.4,155,155,155"/>
                <path :id="'svgGroup55_' + index + '__path2'" class="svgpath svgpath--fuschia" d="M21.5,219.2v376.5"/> -->
                    <path :id="'svgGroup55_' + index + '__path3'" class="svgpath svgpath--green" d="M161,710.2c-25.2,0-49.4,10-67.2,27.8c-37.1,37-37.1,97-0.1,134.1c0,0,0.1,0.1,0.1,0.1" />
                    <path :id="'svgGroup55_' + index + '__path4'" class="svgpath svgpath--fuschia" d="M210,736.4c-38.7-25.8-91-15.4-116.8,23.3C84,773.5,79,789.6,79,806.2" />
                  </g>
                </svg>
                <div class="workDetailPage__testimonial__button__circle"
                     :style="{ background: state.caseStudyGradient }"
                     @click="state.isModalVisible = true">
                  <span>play</span>
                </div>
              </div>
            </div>
            <div class="workDetailPage__testimonial__wrapper workDetailPage__testimonial__wrapper--addPadding">
              <div v-if=contentItem.video_testimonial.image
                   class="workDetailPage__testimonial__imageWrapper workDetailPage__testimonial__imageWrapper--noMarginTop">
                <picture-sources v-if="contentItem.video_testimonial.image && contentItem.video_testimonial.image.sizes && contentItem.video_testimonial.image.sizes.length"
                                 :targetWidth="window.innerWidth <= mobile ? 20 : window.innerWidth <= tablet ? 20 : 80"
                                 :sizes="contentItem.video_testimonial.image.sizes"
                                 :imgSrc="contentItem.video_testimonial.image.guid"
                                 imgClass="objectFitImage"
                                 :imgAlt="contentItem.video_testimonial.image.alt" />
              </div>
              <div class="workDetailPage__testimonial__info"
                   v-html="contentItem.video_testimonial.text">
              </div>
            </div>
          </div>

        </template>

        <div v-if="state.CaseStudiesForm > 0" class="workDetailPage__contactForm component-background" id="contactForm">

          <div v-if="!state.sentModal" class="workDetailPage__contactForm-content">
            <div id="hubspotForm" v-once></div>
          </div>

          <div v-if="state.sentModal" class="workDetailPage__contactForm-content">
            <h2>
              Thank you
            </h2>
            <p id="workDetailPage-contact-sent">
              Your enquiry has been submitted. We will contact you shortly.
            </p>
          </div>

          <div v-if="state.notSentModal" class="workDetailPage__contactForm-content">
            <h2>
              Error
            </h2>
            <p id="workDetailPage-contact-not-sent">
              There was a problem sending your Enquiry. Please try again later.
            </p>
          </div>
        </div>

      </div>
      <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 326.6 456" id="svgGroup49">
      <g>
          <path id="svgGroup49__path1" class="svgpath svgpath--mist" d="M4.5,4.7v145.1c0,83.1,67.3,150.4,150.4,150.4"/>
          <path id="svgGroup49__path2" class="svgpath svgpath--fuschia" d="M171.6,300.2c39.9-0.1,78.2,15.8,106.4,44.1c28.3,28.2,44.1,66.5,44.1,106.4"/>
          <path id="svgGroup49__path3" class="svgpath svgpath--fuschia" d="M269.4,352.8c-54-54-141.6-54-195.7,0"/>
      </g>
  </svg> -->

    </main>
  </div>

</template>

<script setup>
  const emit = defineEmits([
    'isLoaded',
    'menu_items',
    'footer',
    'statement'
  ])

  import EventBus from '../eventbus.js';
  import { _toggleSpinner, api } from '../utils/api-gateway';
  import VueScrollTo from "vue-scrollto";
  import { useRoute, useRouter } from 'vue-router';
  import { ref, reactive, computed, onMounted, onBeforeMount, onUpdated, nextTick } from 'vue';
  import objectFitImages from "object-fit-images";
  import HeaderComponent from './widgets/header-component';
  import ModalComponent from './widgets/modal-component';
  import WorkDetails from './widgets/work-details';
  import { Carousel, Slide } from 'vue3-carousel';
  //import VueScrollTo from 'vue-scrollto';
  import HeaderBar from './widgets/header-bar';
  import PictureSources from './widgets/picture-sources';
  import Circles from './widgets/circles';
  import MyPluginCPS from '../plugins/MyPluginCPS.js';
  import { useInjects } from '../utils/globals-inject';
  const router = useRouter();
  let { window, desktop, mouseX, mouseY, backend, tablet, mobile, isIE } = useInjects();
  const { svgAnimate, goToItem } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);

  const state = reactive({
    isLoaded: false,
    playlist: null,
    isModalVisible: false,
    jsonData: [],
    caseStudyData: [],
    caseStudyContent: [],
    caseStudyServices: [],
    caseStudyServicesString: null,
    id: null,
    slug: null,
    url: null,
    largeTitle: null,
    smallTitle: null,
    bannerImage: null,
    bannerVideo: null,
    bannerVideoCode: null,
    caseStudyColor1: '',
    caseStudyColor2: '',
    caseStudyGradient: '',
    videoID: 'SuA8whSg',
    preview_num: '',
    preview_title: '',
    slickOptions: {
      itemsToShow: 1,
      slidesToScroll: 3,
      snapAlign: 'start',
      dots: false,
      infinite: false,
      cssEase: 'linear',
      centerMode: false,
      initialSlide: 0,
      prevArrow: false,
      nextArrow: false,
      breakpoints: {
        767: {
          itemsToShow: 2,
          initialSlide: 0
        },
        1024: {
          itemsToShow: 4,
          initialSlide: 0
        }
      },
    },
    slickOptions2: {
      itemsToShow: 2,
      slidesToScroll: 1,
      dots: false,
      infinite: false,
      cssEase: 'linear',
      centerMode: false,
      initialSlide: 0,
      prevArrow: false,
      nextArrow: false,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            initialSlide: 0
          }
        }
      ]
    },
    CaseStudiesForm: null,
    CaseStudiesFormID: 0,
    sentModal: false,
    notSentModal: false,
  });

  let url;
  const route = useRoute();

  if (route.params.num) {
    state.preview_num = route.params.num;
    state.preview_title = route.params.title;
    //state.slug = route.params.title
    url = backend + 'preview/work/' + state.preview_num + '/' + state.preview_title;
  } else {
    //this.id = params.id;
    state.slug = route.params.link;
    url = backend + 'page/work/';
  }

  api(url)
    .then((data) => {
      state.jsonData = data.data;
      EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
      EventBus.$emit('isLoaded', true);
      EventBus.$emit('footer', state.jsonData.site_details.tceg);
      EventBus.$emit('statement', state.jsonData.site_details.footer_statement);
      state.url = window.location.href;
      nextTick().then(() => {
        oldMounted();
      });
    }).catch(error => {
    state.loading = false;
  });

  onBeforeMount(() => {
    //console.log('before mounted!!!!');
  });

  onMounted(() => {

  });

  onUpdated(() => {
    initAnimate();
  })

  const isLoaded = computed(() => {
    return state.jsonData.hasOwnProperty('content')
      && Object.keys(state.jsonData.content).length !== 0;
  });

  const caseStudyContentSummary = computed(() => {
    return filterContent('summary');
  });

  const filterContent = (type) => {
    for (let i = 0; i < Object.keys(state.caseStudyContent).length; i++) {
      if (state.caseStudyContent[i][type]) {
        return state.caseStudyContent[i][type];
        break;
      }
    }
  };

  const oldMounted = () => {

    let objectFitImages = require('object-fit-images');
    state.isLoaded = true;

    // Object-fit fix for IE
    objectFitImages('img.objectFitImage');

    state.caseStudyData = getJsonData(state.slug);
    state.caseStudyContent = state.caseStudyData.content;
    let video_pos = -1;
    for (var prop in state.caseStudyContent) {
      if (typeof state.caseStudyContent[prop].video_testimonial !== "undefined") {
        video_pos = prop;
      };
    }

    if (video_pos != -1) {
      state.playlist = jwplayer("playlist");
      if (typeof state.playlist.setup === 'function') {
        state.playlist.setup({
          //file: this.jsonData.content.showreel,
          id: "playlistVid",
          width: "100%",
          aspectratio: "16:9",
          file: "https://cdn.jwplayer.com/manifests/" + state.caseStudyContent[video_pos].video_testimonial.code + ".m3u8",
          image: "https://content.jwplatform.com/thumbs/" + state.caseStudyContent[video_pos].video_testimonial.code + ".jpg",
          customProps: { skin: { url: './css/player.css', name: 'svp' }, controls: true }
        });
      }
    }

    state.caseStudyData = getJsonData(state.slug);
    state.caseStudyContent = state.caseStudyData.content;

    state.caseStudyServices = state.caseStudyContent.services;
    state.bannerImage = state.caseStudyData.banner_image;
    state.bannerVideo = (state.caseStudyData.banner_video !== false) ? state.caseStudyData.banner_video : false;
    state.bannerVideoCode = (state.caseStudyData.banner_video_code != false) ? state.caseStudyData.banner_video_code : false;

    state.caseStudyServicesString = getServices();

    state.caseStudyColor1 = state.caseStudyData.right_col;
    state.caseStudyColor2 = state.caseStudyData.left_col;
    state.caseStudyGradient = 'linear-gradient(-143deg, ' + state.caseStudyColor1 + ' 4%,' + state.caseStudyColor2 + ' 90%)';

    state.largeTitle = (state.caseStudyData.content[0].banner_image_text !== undefined) ? state.caseStudyData.content[0].banner_image_text[0].large : "";
    state.smallTitle = (state.caseStudyData.content[0].banner_image_text !== undefined) ? state.caseStudyData.content[0].banner_image_text[0].small : "";


    // Contact Form
    if (state.CaseStudiesForm = state.caseStudyData.form)
      state.CaseStudiesFormID = state.caseStudyData.form_id;

    if (state.CaseStudiesForm && state.CaseStudiesForm != 0) {

      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "1968984",
            formId: state.CaseStudiesFormID,
            target: "#hubspotForm",
            cssClass: 'cps_form',
            width: '100%'
          })
        }
      });
    }
  };

  const getJsonData = (slug) => {
    let caseStudyData;

    if (slug === undefined || slug === null) {
      caseStudyData = state.jsonData.content.case_studies;
    } else {
      for (let i = 0; i < state.jsonData.content.case_studies.length; i++) {
        if (slug === state.jsonData.content.case_studies[i].link) {
          caseStudyData = state.jsonData.content.case_studies[i];
        }
      }
    }
    return caseStudyData;
  };

  const getServices = () => {
    let caseStudyServices = '';
    for (let i = 0; i < state.caseStudyServices.length; i++) {
      caseStudyServices += state.caseStudyServices[i].service;

      if (i !== state.caseStudyServices.length - 1) {
        caseStudyServices += ', ';
      }
    }
    return caseStudyServices;
  };

  const getQuoteIndex = (quoteIndex, colours) => {
    let bgColour = '';

    for (let i = 0; i < colours.length; i++) {
      if (quoteIndex < colours.length) {
        bgColour = colours[quoteIndex];
      } else {
        bgColour = colours[quoteIndex % colours.length];
      }
    }

    return bgColour;
  };

  const openFullScreen = (videoID) => {
    let elem = document.getElementById(videoID);

    // Set iframe src if unset
    var iframe = document.getElementById("testingVideoIframe");
    if (iframe.hasAttribute('data-src')) {
      iframe.setAttribute('src', iframe.getAttribute('data-src'));
      iframe.removeAttribute('data-src');
    }

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }

    /* Unset iframe src to stop audio playing when exiting fullscreen. */
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);

    function exitHandler() {
      if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        var iframe = document.getElementById("testingVideoIframe");
        iframe.setAttribute('data-src', iframe.getAttribute('src'));
        iframe.setAttribute('src', '');
      }
    }

  };

  const next = () => {
    this.$refs.slick.next();
  };

  const prev = () => {
    this.$refs.slick.prev();
  };

  const reInit = () => {
    // Helpful if you have to deal with v-for to update dynamic lists
    this.$refs.slick.reSlick();
  };

  const initAnimate = () => {
    // Check if the element exists
    /*let elementExists = document.getElementById("workDetailImage");
    console.log(elementExists);
    if (!elementExists) {
        window.setTimeout(() => {this.initAnimate()}, 100);
        return;
    }*/

    if (window.innerWidth >= desktop && !isIE) {

      let svgPathsWork = [];

      if (document.getElementsByClassName("svgGroup42")) {
        document.querySelectorAll(".svgGroup42").forEach((ele) => {
          // Do stuff here
          let index = ele.id;

          svgPathsWork.push(
            {
              id: index + '__path1',
              tweenId: '1' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path2',
              tweenId: '1' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path3',
              tweenId: '1' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path4',
              tweenId: '1' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path5',
              tweenId: '1' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '-300'
            },
            {
              id: index + '__path6',
              tweenId: '1' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path7',
              tweenId: '1' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '-300'
            },
            {
              id: index + '__path8',
              tweenId: '1' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
          )
        });
      }

      if (document.getElementsByClassName("svgGroup43")) {
        document.querySelectorAll(".svgGroup43").forEach((ele) => {
          // Do stuff here
          let index = ele.id;

          svgPathsWork.push(
            {
              id: index + '__path1',
              tweenId: '2' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path2',
              tweenId: '2' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path3',
              tweenId: '2' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path4',
              tweenId: '2' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
          )
        });
      }

      if (document.getElementsByClassName("svgGroup44")) {
        document.querySelectorAll(".svgGroup44").forEach((ele) => {
          // Do stuff here
          let index = ele.id;

          svgPathsWork.push(
            {
              id: index + '__path1',
              tweenId: '3' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path2',
              tweenId: '3' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path3',
              tweenId: '3' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path4',
              tweenId: '3' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path5',
              tweenId: '3' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path6',
              tweenId: '3' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path7',
              tweenId: '3' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path8',
              tweenId: '3' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            }
          )
        });
      }

      if (document.getElementsByClassName("svgGroup45")) {
        document.querySelectorAll(".svgGroup45").forEach((ele) => {
          // Do stuff here
          let index = ele.id;

          svgPathsWork.push(
            {
              id: index + '__path1',
              tweenId: '4' + index,
              speed: 2,
              delay: 0,
              trigger: '#' + index,
              offset: '0'
            },
            {
              id: index + '__path2',
              tweenId: '4' + index,
              speed: 2,
              delay: 0,
              trigger: '#' + index,
              offset: '0'
            },
            {
              id: index + '__path3',
              tweenId: '4' + index,
              speed: 2,
              delay: 0,
              trigger: '#' + index,
              offset: '0'
            },
          )
        });

      }

      if (document.getElementsByClassName("svgGroup46")) {
        document.querySelectorAll(".svgGroup46").forEach((ele) => {
          // Do stuff here
          let index = ele.id;

          svgPathsWork.push(
            {
              id: index + '__path2',
              tweenId: '5' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path3',
              tweenId: '5' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            }
          )
        });
      }

      if (document.getElementsByClassName("svgGroup47")) {
        document.querySelectorAll(".svgGroup47").forEach((ele) => {
          // Do stuff here
          let index = ele.id;

          svgPathsWork.push(
            {
              id: index + '__path1',
              tweenId: '6' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path2',
              tweenId: '6' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path3',
              tweenId: '6' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path4',
              tweenId: '6' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            }
          )
        });
      }

      if (document.getElementsByClassName("svgGroup48")) {
        document.querySelectorAll(".svgGroup48").forEach((ele) => {
          // Do stuff here
          let index = ele.id;

          svgPathsWork.push(
            {
              id: index + '__path1',
              tweenId: '7' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path2',
              tweenId: '7' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path3',
              tweenId: '7' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path4',
              tweenId: '7' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path5',
              tweenId: '7' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            }
          )
        });
      }

      if (document.getElementById("svgGroup49")) {
        svgPathsWork.push(
          {
            id: 'svgGroup49__path1',
            tweenId: '8' + index,
            speed: 2,
            delay: 0,
            trigger: "#svgGroup49",
            offset: '0'
          },
          {
            id: 'svgGroup49__path2',
            tweenId: '8' + index,
            speed: 2,
            delay: 0,
            trigger: "#svgGroup49",
            offset: '0'
          },
          {
            id: 'svgGroup49__path3',
            tweenId: '8' + index,
            speed: 2,
            delay: 0,
            trigger: "#svgGroup49",
            offset: '0'
          }
        )
      }

      if (document.getElementsByClassName("svgGroup53")) {
        document.querySelectorAll(".svgGroup53").forEach((ele) => {
          // Do stuff here
          let index = ele.id;

          svgPathsWork.push(
            {
              id: index + '__path1',
              tweenId: '9' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path2',
              tweenId: '9' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path3',
              tweenId: '9' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            }
          )
        });
      }

      if (document.getElementsByClassName("svgGroup54")) {
        document.querySelectorAll(".svgGroup54").forEach((ele) => {
          let index = ele.id;

          svgPathsWork.push(
            {
              id: index + '__path1',
              tweenId: '10' + index,
              speed: 1.5,
              delay: 0,
              trigger: "#" + index,
              offset: '0'
            },
            {
              id: index + '__path2',
              tweenId: '10' + index,
              speed: 1.5,
              delay: 0.5,
              trigger: "#" + index,
              offset: '0'
            }
          )
        });
      }

      if (document.getElementsByClassName("svgGroup55")) {
        document.querySelectorAll(".svgGroup55").forEach((ele) => {

          let index = ele.id;

          svgPathsWork.push(
            // {
            //     id: 'svgGroup55__path1',
            //     tweenId: '11',
            //     speed: 2,
            //     delay: 0,
            //     trigger: "#svgGroup55",
            //     offset: '0'
            // },
            // {
            //     id: 'svgGroup55__path2',
            //     tweenId: '11',
            //     speed: 2,
            //     delay: 0,
            //     trigger: "#svgGroup55",
            //     offset: '0'
            // },
            {
              id: index + '__path3',
              tweenId: '11' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '100'
            },
            {
              id: index + '__path4',
              tweenId: '11' + index,
              speed: 2,
              delay: 0,
              trigger: "#" + index,
              offset: '100'
            }
          )
        });
      }

      let svgTweensWork = {};

      // Loop through SVG PATHS array / build tweens and scenes
      svgAnimate(svgPathsWork, svgTweensWork);
    }
  };

  const getVideoCode = (embed) => {
    let str = embed.split('/')[4];
    return str.split('-')[0];
  };

  const submitForm = () => {
    let data = new FormData(document.getElementById('sd_form'));
    _toggleSpinner(true);
    document.getElementById('loading-spinner').classList.add('show');
    state.ajaxRequest = true;
    this.$http.post(backend + 'services/enquiry/', data, { headers: { "content-type": "text/plain; charset=UTF-8" } })
      .then(response => {
        //document.getElementById('loading-spinner').classList.remove('show');
        state.postResults = response.data;
        state.ajaxRequest = false;
        _toggleSpinner(false);
        if (state.postResults.status === 200) {
          state.loggedModal = true;
          state.sentModal = true;
          VueScrollTo.scrollTo(document.getElementById("workDetailPage__contactForm"));
        } else if (this.postResults.status !== 200) {
          state.notSentModal = true;
        } else {
          state.sentModal = true;
          VueScrollTo.scrollTo(document.getElementById("workDetailPage__contactForm"));
        }
      }).catch((err) => {
        state.notSentModal = true;
        _toggleSpinner(false);
      })
  };

</script>
