<template>

  <div class="input-box" v-bind:data-type="$attrs.type">

    <label>
      <span v-if="labelText">{{ labelText }}</span>
      <input v-bind="$attrs" v-on:change="changeFile" />
    </label>

    <small>Required</small>

    <svg v-if="isFile" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <path v-bind:style="{fill: $props.agencyColor }" d="M13.391,15H1.609A1.625,1.625,0,0,1,0,13.364V10.74A1.625,1.625,0,0,1,1.609,9.1H4.858A.649.649,0,0,1,5.5,9.7a2.009,2.009,0,0,0,4,0,.649.649,0,0,1,.641-.592h3.249A1.625,1.625,0,0,1,15,10.74v2.624A1.625,1.625,0,0,1,13.391,15ZM1.609,9.867a.867.867,0,0,0-.859.873v2.624a.866.866,0,0,0,.859.872H13.391a.866.866,0,0,0,.858-.872V10.74a.866.866,0,0,0-.858-.873H10.237A2.784,2.784,0,0,1,7.5,12.295,2.738,2.738,0,0,1,5.679,11.6a2.816,2.816,0,0,1-.916-1.736ZM7.5,8.224a.379.379,0,0,1-.373-.383V1.3L5.308,3.151a.37.37,0,0,1-.53,0,.387.387,0,0,1,0-.539L7.236.113a.369.369,0,0,1,.531,0l2.461,2.5a.385.385,0,0,1,0,.539.372.372,0,0,1-.533,0L7.877,1.3V7.842A.38.38,0,0,1,7.5,8.224Z" />
    </svg>


  </div>
</template>
<script >export default {
    name: 'input-box',
    inheritAttrs: false,
    props: {
      agencyColor: String
    },
    data() {
      return {
        isRequired: 'required' in this.$attrs,
        isFile: this.$attrs.type.toLowerCase() == 'file',
        labelText: 'label' in this.$attrs ? this.$attrs.label : false,
      }
    },
    methods: {
      changeFile(e) {

        if (e.target.files) {
          this.labelText = e.target.files.length
            ? e.target.files[0].name
            : this.$attrs.label;

          this.$emit('uploaded', e.target.files);
        }
      },
    }
  }</script>
