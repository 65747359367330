<template>
  <div v-if="isLoaded" class="footerPage">
    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />
    <header-component type="footer"
                      class='header--footerLinks'>
    </header-component>
    <main>
      <h1>{{ state.jsonData.content.post_title }}</h1>
      <div v-html='state.jsonData.content.post_content'>
      </div>
    </main>
  </div>
</template>
<script>
  import EventBus from '../eventbus.js';
  import HeaderComponent from './widgets/header-component';
  import { api } from '../utils/api-gateway';
  import HeaderBar from './widgets/header-bar';
  import { useInjects } from '../utils/globals-inject';
  import { useRoute } from "vue-router";
  export default {
    name: 'PrivacyPage',
    components: {
      HeaderBar,
      HeaderComponent
    },
    setup() {
      const { backend } = useInjects();
      const state = reactive({
        jsonData: []
      })

      const isLoaded = computed(() => {
        if (state.jsonData.hasOwnProperty('content')) {
          return true;
        }
        return false;
      });

      const oldMounted = () => {
      };
      let url;
      const route = useRoute();

      if (route.params.num) {
        state.preview_num = route.params.num;
        state.preview_title = route.params.title;
        url = backend + 'preview/page/' + state.preview_num + '/privacy-policy-page';
      } else {
        url = backend + 'page/privacy-policy-page/';
      }
      api(url)
        .then((data) => {
          state.jsonData = data.data;
          EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
          EventBus.$emit('isLoaded', true);
          EventBus.$emit('footer', state.jsonData.site_details.tceg);
          EventBus.$emit('isPurple', true);
          EventBus.$emit('statement', state.jsonData.site_details.footer_statement);
          nextTick().then(() => {
            oldMounted();
          });
        }).catch(error => {
          state.loading = false;
        });
      return {
        state,
        oldMounted,
        isLoaded
      }
    }
  }</script>
