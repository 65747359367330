<template>
  <picture>
    <template v-for="ratio in state.ratios">
      <source v-for="size in sizesBigEnough" :media="mediaQuery(size.width)" :data-srcset="size.guid">
    </template>
    <img v-if="!isMobile()" ps1="hkhk"
         :style="$props.imagefit"
         class="lazyload"
         :data-src="$props.imgSrc"
         :alt="$props.imgAlt"
         :class="$props.imgClass">
    <img v-else ps2="hhh"
         style="height:unset"
         class="lazyload"
         :data-src="$props.imgSrc"
         :alt="$props.imgAlt"
         :class="$props.imgClass">
  </picture>
</template>
<script>
  import 'lazysizes';
  import { computed, reactive } from "vue";
  import { useInjects } from '../../utils/globals-inject';
  export default {
    name: 'PictureSources',
    components: {
    },
    props: {
      sizes: Array,
      targetWidth: Number, /* This is a percentage of the viewport width */
      imgSrc: String,
      imgAlt: String,
      imgClass: String,
      imagefit: String
    },
    setup(props) {

      let { window, tablet } = useInjects();

      const state = reactive({
        ratios: [1, 1.5, 2, 2.5, 3]
      });

      const sizesBigEnough = computed(() => {
        return props.sizes.filter(s => isBigEnough(s.width));
      });

      const isBigEnough = (width) => {
        let windowScale = (window.innerWidth / 100) * props.targetWidth;
        return windowScale <= width;
      };

      const mediaQuery = (imageWidth) => {
        let mediaQuery = '';
        let scale = 100 / props.targetWidth;

        state.ratios.forEach(ratio => {

          let maxWidth = parseInt(((imageWidth * scale) / ratio), 10);
          let maxWidthQuery = ' and (max-width: ' + maxWidth + 'px)';

          mediaQuery = mediaQuery + '(-webkit-max-device-pixel-ratio: ' + ratio + ') ' + maxWidthQuery + ', ';
          mediaQuery = mediaQuery + '(max--moz-device-pixel-ratio: ' + ratio + ') ' + maxWidthQuery + ', ';
          mediaQuery = mediaQuery + '(max-resolution: ' + ratio + 'dppx) ' + maxWidthQuery + ', ';
        });

        return mediaQuery.substring(0, mediaQuery.length - 2);
      };

      const isMobile = () => {
        return window.innerWidth <= 1024 && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      };

      return {
        state,
        isBigEnough,
        sizesBigEnough,
        isMobile,
        mediaQuery,
        window,
        tablet
      };
    }
  }</script>
