<template>
  <div v-if="isLoaded" id="news_list">

    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />

    <header-component type='campaignList'
                      class="header--campaign"
                      :title=state.jsonData.content.page_title
                      :image=state.jsonData.content.banner_image>
    </header-component>
    <main>
      <div>
        <div class="masonry">
          <div class="masonry-column-1">

          </div>
          <div class="masonry-column-2">

          </div>
          <div class="masonry-column-3">

          </div>
          <div v-for="(item, index) in state.campaignItems" :key="index" class="news-item" @click="goToItem(item.post_name,item.itemLink)" >
            <div class="campaign-item__wrapper">
              <picture-sources v-if="item.image && item.image.sizes && item.image.sizes.length"
                               :targetWidth="window.innerWidth <= mobile ? 100 : window.innerWidth <= tablet ? 50 : 33"
                               :sizes="item.image.sizes"
                               :imgSrc="item.image.guid"
                               :imgAlt="item.image.alt"
                               imgClass="campaign-item__image objectFitImage" />
              <div class="text-container" v-if="item.post_title" @mouseover="mouseOver">
                <h2>{{ (item.alternative_title) ? item.alternative_title : item.post_title }}</h2>
                <p v-if="item.text">
                  {{ item.text }}
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </main>
  </div>

</template>
<script >
  import  HeaderComponent  from './widgets/header-component';
  import { api } from '../utils/api-gateway';
  import  PictureSources  from './widgets/picture-sources';
  import  HeaderBar  from './widgets/header-bar';
  import EventBus from '../eventbus.js';
  import {computed, nextTick, onUpdated, reactive} from "vue";
  import objectFitImages from "object-fit-images";
  import { useRouter } from 'vue-router';
  import MyPluginCPS from '../plugins/MyPluginCPS.js';
  import { useInjects } from '../utils/globals-inject';
  export default {
    name: 'CampaignsPage',
    components: {
      HeaderBar,
      HeaderComponent,
      PictureSources
    },
    setup() {
      const router = useRouter();
      let { window, desktop, mobile, tablet, mouseX, mouseY, backend } = useInjects();
      const { goToItem } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);

      const state = reactive({
        jsonData: [],
        campaignItems: [],
        blReOrdered: false,
      });
      const isLoaded = computed(() => {
        if (state.jsonData.hasOwnProperty('content')) {
          return true;
        }
        return false;
      });
      const oldMounted = () => {
        let objectFitImages = require('object-fit-images');

        // Object-fit fix for IE
        objectFitImages('img.objectFitImage');
      };
      const mouseOver = (event) => {

      };
      const mouseLeave = () => {

      };

      api(backend + 'page/campaigns/')
        .then((data) => {
          state.jsonData = data.data;
          EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
          EventBus.$emit('isLoaded', true);
          EventBus.$emit('footer', state.jsonData.site_details.tceg);
          EventBus.$emit('isPurple', true);
          EventBus.$emit('statement', state.jsonData.site_details.footer_statement);

          nextTick().then(() => {
            oldMounted();
          });

          state.campaignItems = state.jsonData.content.campaigns;

        }).catch(error => {
        state.loading = false;
      })

      onUpdated(() => {
        if(!state.blReOrdered){
          const elmNewItems = document.querySelectorAll('.masonry .news-item');
          // Calculate the number of columns
          var numColumns = 3;
          if(window.innerWidth <= 1024){
            numColumns = 2;
          }

          // Create an array to hold items for each column
          const columns = new Array(numColumns).fill([]).map(() => []);

          // Iterate through the items and distribute them across columns
          for(let intNewsItem = 0; intNewsItem < elmNewItems.length; intNewsItem++){
            const columnIndex = intNewsItem % numColumns;
            columns[columnIndex].push(elmNewItems[intNewsItem]);
          }
          columns.forEach((columnItems,index) => {
            columnItems.forEach((item,itemIndex) => {
              item.setAttribute('data-index',itemIndex);
              switch(index){
                case 0:
                  document.querySelector('.masonry-column-1').appendChild(item);
                  break;
                case 1:
                  document.querySelector('.masonry-column-2').appendChild(item);
                  break;
                case 2:
                  document.querySelector('.masonry-column-3').appendChild(item);
              }
            });
          });
          state.blReOrdered = true;
        }
      });
      return{
        state,
        isLoaded,
        oldMounted,
        mouseOver,
        mouseLeave,
        goToItem,
        window,
        mobile,
        tablet
      }
    }
  }</script>
