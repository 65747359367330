<template>

</template>
<script >
  import jQuery from "jquery";
  //import Slick from "vue-slick";

  export default {
    name: 'Slider',
    data() {
      return {
        slickOptions: {
          // Options can be used from the plugin documentation
          slidesToShow: 4,
          infinite: true,
          accessibility: true,
          adaptiveHeight: false,
          arrows: false,
          dots: false,
          draggable: true,
          edgeFriction: 0.30,
          swipe: true
        },
      }
    },
    // All slick methods can be used too, example here
    methods: {
      next() {
        this.$refs.slick.next()
      },
      prev() {
        this.$refs.slick.prev()
      },
      reInit() {
        // Helpful if you have to deal with v-for to update dynamic lists
        this.$refs.slick.reSlick()
      }
    }
  }</script>
