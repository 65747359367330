<template>
  <div id="twentytwenty"  class="twentytwenty-container" v-bind:style="state.containerStyle" v-on:touchstart="startSlide" v-on:mousedown="startSlide">

    <img id="imageSlider" ref="img" :src="$props.after" :alt="$props.after" v-on:mousedown.prevent v-on:load="setDimensions" />
    <img :src="$props.before" :alt="$props.before" v-on:mousedown.prevent v-bind:style="beforeImgStyle" />

    <div class="twentytwenty-overlay" v-bind:style="state.overlayStyle">
      <div v-if="$props.beforeLabel" class="twentytwenty-before-label">{{$props.beforeLabel}}</div>
      <div v-if="$props.afterLabel" class="twentytwenty-after-label">{{$props.afterLabel}}</div>
    </div>

    <div class="twentytwenty-handle" v-bind:style="handleStyle" tabindex="0" v-on:keydown="handleArrowNavigation">
      <div class="twentytwenty-arrow-left"></div>
      <div class="twentytwenty-arrow-right"></div>
    </div>

  </div>
</template>
<script>
  import { ref, computed, nextTick, onBeforeUnmount, onMounted, reactive } from "vue";

  export default {
    name: 'TwentyTwenty',
    props: {
      before: {
        type: String,
        required: true
      },
      beforeLabel: {
        type: String
      },
      after: {
        type: String,
        required: true
      },
      afterLabel: {
        type: String
      },
      offset: {
        type: [String, Number],
        default: 0.5,
        validator: (value) => (value > 0 && value <= 1)
      },
      keyboardStep: {
        type: [String, Number],
        default: 0.2,
        validator: (value) => (value > 0 && value <= 1)
      }
    },
    setup(props) {

      const state = reactive({
        imgOffset: null,
        slideOffset: props.offset,
        sliding: false,
        containerStyle: {},
        overlayStyle: {}
      });

      const img = ref(null);
 
      const beforeImgStyle = computed(() => {
        return { clip: `rect(0, ${x.value}px, ${h.value}px, 0)` }
      });

      const handleStyle = computed(() => {
        const size = 40;
        return {
          width: `${size}px`,
          height: `${size}px`,
          top: `calc(50% - ${size / 2}px)`,
          left: `calc(${state.slideOffset * 100}% - ${size / 2}px)`
        }
      });

      const x = computed(() => {
        return w.value * state.slideOffset
      });

      const w = computed(() => {
        return state.imgOffset ? state.imgOffset.width : null
      });

      const h = computed(() => {
        return state.imgOffset ? state.imgOffset.height : null
      });

      const floatOffset = computed(() => {
        return parseFloat(state.slideOffset)
      });

      const floatKeyboardStep = computed(() => {
        return parseFloat(props.keyboardStep)
      });

      const setDimensions = () => {
        var imgSlider = document.getElementById("imageSlider");
        state.imgOffset = imgSlider.getBoundingClientRect();
        state.containerStyle = { width: `${w.value}px`, height: `${h.value}px` };
      };

      const startSlide = (event) => {
        state.sliding = true
        moveSlide(event)
        state.overlayStyle = { opacity: 0 }
      };

      const handleArrowNavigation = (event) => {
        return moveSlide(event)
      };

      const moveSlide = (event) => {
        if (state.sliding) {
          var xx = (event.touches ? event.touches[0].pageX : event.pageX) - state.imgOffset.left
          xx = (xx < 0) ? 0 : ((xx > w.value) ? w.value : xx)
          return state.slideOffset = (xx / w.value)
        }
        if (event.key) {
          switch (event.key) {
            case "Left":     // IE/Edge key
            case "ArrowLeft": state.slideOffset = ((floatOffset.value - floatKeyboardStep.value) >= 0) ? floatOffset.value - floatKeyboardStep.value : 0; break;
            case "Right":    // IE/Edge key
            case "ArrowRight": state.slideOffset = ((floatOffset.value + floatKeyboardStep.value) <= 1) ? floatOffset.value + floatKeyboardStep.value : 1; break;
            default: return;
          }
        }
      };

      const endSlide = () => {
        state.sliding = false
        state.overlayStyle = {}
      };

      const resize = () => {
        state.containerStyle = {};
        nextTick(() => {
          setDimensions();
        });
      };

      onMounted(() => {
        document.addEventListener("touchmove", moveSlide)
        document.addEventListener("touchend", endSlide)
        document.addEventListener("mousemove", moveSlide)
        document.addEventListener("mouseup", endSlide)
        window.addEventListener("resize", resize)
      });

      onBeforeUnmount(() => {
        document.removeEventListener("touchmove", moveSlide)
        document.removeEventListener("touchend", endSlide)
        document.removeEventListener("mousemove", moveSlide)
        document.removeEventListener("mouseup", endSlide)
        window.removeEventListener("resize", resize)
      });

      return {
        state,
        resize,
        endSlide,
        moveSlide,
        handleArrowNavigation,
        startSlide,
        setDimensions,
        beforeImgStyle,
        handleStyle,
        x,
        w,
        h,
        floatOffset,
        floatKeyboardStep,
        img
      };
    },
  }
</script>
<style>
  .twentytwenty-container {
    z-index: 9999;
    position: relative;
    overflow: hidden;
    box-sizing: content-box;
  }

  #twentytwenty{
      z-index: 20;
  }

  #imageSlider {
    z-index: 19;
  }

    .twentytwenty-container img {
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      user-select: none;
      z-index: 20;
    }

    .twentytwenty-container .twentytwenty-overlay {
      z-index: 25;
      width: 100%;
      height: 100%;
      top: 0;
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.5s;
    }

      .twentytwenty-container .twentytwenty-overlay .twentytwenty-before-label,
      .twentytwenty-container .twentytwenty-overlay .twentytwenty-after-label {
        user-select: none;
        position: absolute;
        font-size: 0.8em;
        top: calc(50% - 0.4em - 5px);
        padding: 10px;
        background: rgba(255, 255, 255, 0.4);
        color: white;
      }

      .twentytwenty-container .twentytwenty-overlay .twentytwenty-before-label {
        left: 0;
      }

      .twentytwenty-container .twentytwenty-overlay .twentytwenty-after-label {
        right: 0;
      }

    .twentytwenty-container:hover > .twentytwenty-overlay {
      opacity: 1;
    }

    .twentytwenty-container .twentytwenty-handle {
      cursor: move;
      z-index: 30;
      position: absolute;
      background: none;
      border: 4px solid white;
      border-radius: 50px;
      margin-left: -4px;
      margin-top: -4px;
      user-select: none;
    }

      .twentytwenty-container .twentytwenty-handle:active,
      .twentytwenty-container .twentytwenty-handle:focus {
        outline: 0;
      }

      .twentytwenty-container .twentytwenty-handle:before, .twentytwenty-container .twentytwenty-handle:after {
        content: "";
        border: 2px solid white;
        height: 9999px;
        position: absolute;
        left: calc(50% - 2px);
      }

      .twentytwenty-container .twentytwenty-handle:before {
        top: 40px;
      }

      .twentytwenty-container .twentytwenty-handle:after {
        bottom: 40px;
      }

    .twentytwenty-container .twentytwenty-arrow-right,
    .twentytwenty-container .twentytwenty-arrow-left {
      user-select: none;
      position: relative;
      width: 0;
      height: 0;
    }

    .twentytwenty-container .twentytwenty-arrow-right {
      bottom: 10px;
      left: 23px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid white;
    }

    .twentytwenty-container .twentytwenty-arrow-left {
      top: 10px;
      left: 7px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid white;
    }
</style>
