<template>
  <footer v-if="isLoaded">
    <div class="footer">
      <div class="footer__contact">
        <h2>Engage with us</h2>
        <!--<p>{{ tceg.name }}</p>-->
        <div class="footer__contact__email">
          <a :href="`mailto:${tceg.general_enquries_email}`" class="footer__contact__mail">{{ tceg.general_enquries_email }}</a>
        </div>

        <div class="footer__contact__uk">
          <label>UK</label>
          <a :href="`tel:${tceg.general_enquries_phone}`" class="footer__contact__tel">{{ tceg.general_enquries_phone }}</a>
        </div>
        <div class="footer__contact__us">
          <label>US</label>
          <a :href="`tel:${tceg.us_phone}`" class="footer__contact__tel">{{ tceg.us_phone }}</a>
        </div>
        <div v-if="tceg.media_enquiries_email" class="footer__contact__us">
          <label>Media Enquiries</label>
          <a :href="`mailto:${tceg.media_enquiries_email}`" class="footer__contact__tel">{{ tceg.media_enquiries_email }}</a>
        </div>
      </div>
      <div class="footer__statement" v-html="statement"></div>
      <div class="footer__social">
        <ul class="footer__social__icons">
          <li class="footer__social--twitter">
            <a :href="tceg.twitter" target="_blank" rel="noreferrer noopener">
              <img src="/x-icon.svg" alt="X Icon">
            </a>
          </li>
          <li class="footer__social--linkedin">
            <a :href="tceg.linkedin" target="_blank" rel="noreferrer noopener">
              <img src="/linkedin-icon.svg" alt="Linkedin Icon">
            </a>
          </li>
          <li class="footer__social--instagram">
            <a :href="tceg.instagram" target="_blank" rel="noreferrer noopener">
              <img src="/instagram-icon.svg" alt="Instagram Icon">
            </a>
          </li>
        </ul>
        <div class="footer__social__copy">
          <p>&copy;{{ year }}  Inizio. All rights reserved.</p>
        </div>
        <div class="footer__social__inizio">
          <a href="https://inizioengage.com/" target="_blank" rel="noreferrer noopener">
            <img src="/logos/Inizio_Engage_Logo_Reverse_Color_RGB_v03.20220627154012331.png" alt="Inizio Logo" />
          </a>
        </div>
      </div>
    </div>
    <div class="footer__white-bar">
      <ul class="footer__social__copy__menu">
        <li v-for="item in menu_items.footer">
          <router-link v-if="item.menu_item === 'Sitemap'" :to="item.link" style="cursor: pointer">
            {{ item.menu_item }}
          </router-link>
          <a v-else style="cursor: pointer" :href=item.url target="_blank">
            {{ item.menu_item }}
          </a>
        </li>
        <li><a id="ot-sdk-settings-link" class="ot-sdk-show-settings" style="cursor:pointer">Cookie Settings</a></li>
      </ul>

    </div>

    <!--<LoadingSpinner id="loading-spinner" />-->

  </footer>

</template>
<script >
  import EventBus  from "../../eventbus";
  import Navigation from './navigation';
  import LoadingSpinner from './loading-spinner';

  export default {
    name: 'footer-component',
    components: {
      Navigation,
      LoadingSpinner
    },
    created() {
      EventBus.$emit('isLoaded', this.loaded)
      EventBus.$emit('footer', this.tcegDetails)
      EventBus.$emit('menu_items', this.footerDetails)
      EventBus.$emit('statement', this.statementDetails)
    },
    data() {
      return {
        isLoaded: false,
        tceg: '',
        menu_items: '',
        statement: '',
        year: new Date().getFullYear(),
      }
    },
    computed: {},
    methods: {
      loaded(value) {
        this.isLoaded = value;
        console.log('footer isloaded: ', value);
      },
      tcegDetails(value) {
        this.tceg = value;
      },
      footerDetails(value) {
        this.menu_items = value;
      },
      statementDetails(value) {
        this.statement = value;
        console.log('footer statement: ', value);
      }
    },
    mounted() {
      EventBus.$on('isLoaded', (...args) => {
        this.isLoaded = args[0];
      });
      EventBus.$on('footer', (...args) => {
        if(args[0] !== undefined){
          this.tceg = args[0];
        }
      });
      EventBus.$on('menu_items', (...args) => {
        if(args[0] !== undefined){
          this.menu_items = args[0];
        }
      });
      EventBus.$on('statement', (...args) => {
        if(args[0] !== undefined){
          this.statement = args[0];
        }
      });
    }

  }</script>
