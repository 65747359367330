<template>
  <div id="jobs" v-if="isLoaded">

    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />

    <header-component type='jobs'
                      class='header--jobs'
                      :title=state.jsonData.content.banner_text
                      :image=state.jsonData.content.banner_image>
    </header-component>
    <main v-if="state.boardJobs.length">
      <!-- svgGroup10-->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.5 323.5" id="svgGroup10">
        <g>
          <path id="svgGroup10__path3" class="svgpath svgpath--mist" d="M105.6,4.2c85.6,0,155,69.4,155,155c0,0,0,0,0,0v160.6" />
          <path id="svgGroup10__path2" class="svgpath svgpath--fuschia" d="M105.6,4.2c85.6,0,155,69.4,155,155c0,0,0,0,0,0" />
          <path id="svgGroup10__path1" class="svgpath svgpath--green" d="M4.8,58.4c55.7-55.7,146-55.7,201.7,0" />
        </g>
      </svg>

      <div id="jobs-intro" v-html="state.jsonData.content.post_content"></div>
      <div role="group">
        <div class="jobs-filters">
          <div class="jobs-filter-container">
            <label for="location">Location</label>
            <select-dropdown :options=state.jobsLocations
                             defaultVal="All"
                             :onChange=updateLocationFilter />
          </div>
          <div class="jobs-filter-container">
            <label for="agency">Agency</label>
            <select-dropdown :options=state.jobsAgencies
                             defaultVal="All"
                             :onChange=updateAgencyFilter />
          </div>
          <div class="jobs-filter-container">
            <label for="department">Specialty</label>
            <select-dropdown :options=state.jobsDepartments
                             defaultVal="All"
                             :onChange=updateDepartmentFilter />
          </div>
        </div>
      </div>

      <div id="job_list">
        <!-- svgGroup9-->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 430.52 408.158" id="svgGroup9">
          <g>
            <path id="svgGroup9__path3" class="svgpath svgpath--mist" d="M204.1,204.1C92.7,204.1,2.5,294.3,2.5,405.7" />
            <path id="svgGroup9__path2" class="svgpath svgpath--fuschia" d="M428,2.5c0.1,53.5-21.2,104.8-59,142.5c-37.8,37.9-89.1,59.1-142.5,59" />
            <path id="svgGroup9__path1" class="svgpath svgpath--green" d="M95.3,133.6c72.4,72.4,189.8,72.4,262.3,0" />
          </g>
        </svg>
        <ul>
          <template v-for="(job, index) in state.boardJobs">
            <li
              v-if="(!state.filterLocation && !state.filterAgency && !state.filterDepartment) || ((!state.filterLocation || job.location.name.split(',')[0]==state.filterLocation) && (!state.filterAgency || job.job_company.name==state.filterAgency) && (!state.filterDepartment || (job.departments && job.departments.length>0 && job.departments.filter(department=>department.name.trim() === state.filterDepartment).length > 0)))"
              v-bind:style="{backgroundColor: state.agencyColours[job.job_company.short_name] }"
              @click="openJob(job.job_board, job.id, slugifyNew(job.title))"
              >
              <span>{{ job.job_company.short_name }} - {{ job.location.name.split(',')[0] }}</span>

              <h2>{{ job.title }}</h2>

              <p v-html="job.content_short"></p>
            </li>
          </template>
        </ul>

      </div>
    </main>
    <main v-else>
      <div id="jobs-intro">
        <p>Whilst we don’t have any specific job openings right now, do drop us a note on <a href="mailto:talent@tceg.com">talent@tceg.com</a>.</p>
      </div>
      <div id="job_list">
        <!-- svgGroup10-->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.5 323.5" id="svgGroup10">
          <g>
            <path id="svgGroup10__path3" class="svgpath svgpath--mist" d="M105.6,4.2c85.6,0,155,69.4,155,155c0,0,0,0,0,0v160.6" />
            <path id="svgGroup10__path2" class="svgpath svgpath--fuschia" d="M105.6,4.2c85.6,0,155,69.4,155,155c0,0,0,0,0,0" />
            <path id="svgGroup10__path1" class="svgpath svgpath--green" d="M4.8,58.4c55.7-55.7,146-55.7,201.7,0" />
          </g>
        </svg>
        <!-- svgGroup9-->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 430.52 408.158" id="svgGroup9">
          <g>
            <path id="svgGroup9__path3" class="svgpath svgpath--mist" d="M204.1,204.1C92.7,204.1,2.5,294.3,2.5,405.7" />
            <path id="svgGroup9__path2" class="svgpath svgpath--fuschia" d="M428,2.5c0.1,53.5-21.2,104.8-59,142.5c-37.8,37.9-89.1,59.1-142.5,59" />
            <path id="svgGroup9__path1" class="svgpath svgpath--green" d="M95.3,133.6c72.4,72.4,189.8,72.4,262.3,0" />
          </g>
        </svg>
      </div>
    </main>
  </div>

</template>
<script >
  import  SelectDropdown  from './widgets/select-dropdown';
  import  EventBus  from '../eventbus.js';
  import { slugify } from '../utils/slugify';
  import HeaderComponent from './widgets/header-component';
  import { api } from '../utils/api-gateway';
  import { decodeHTML } from '../utils/decode-html';
  import HeaderBar from './widgets/header-bar';
  import {computed, getCurrentInstance, nextTick, reactive } from "vue";
  import objectFitImages from "object-fit-images";
  import { useInjects } from '../utils/globals-inject';
  import { useRouter } from "vue-router";
  export default {
    name: 'JobsPage',
    components: {
      HeaderBar,
      SelectDropdown,
      HeaderComponent,
    },
    setup() {
      const router = useRouter();
      let { window, backend } = useInjects();
      const currentInstance = getCurrentInstance();

      const state = reactive({
        jobsLocations: [],
        jobsAgencies: [],
        jobsDepartments: [],
        jsonData: [],
        agencyColours: {},
        filterLocation: null,
        filterAgency: null,
        filterDepartment: null,
        boardJobs: [],
      });

      const isLoaded = computed(() => {
        if (state.jsonData.hasOwnProperty('content')) {
          return true;
        }
        return false;
      });

      const openJob = (board, job, link) => {
        router.push({
          name: 'job-item',
          params: {
            board: board,
            job: job,
            title: link
          }
        })
      };

      const oldMounted = () => {

        var companies = [
          state.jsonData.site_details.tceg,
          ...state.jsonData.site_details.all_companies
        ];

        companies.forEach(company => {
          if (company.greenhouse_api_job_boards && company.greenhouse_api_job_boards.length) {
            company.greenhouse_api_job_boards.forEach(greenhouse_api_job_board => {
              api('https://boards-api.greenhouse.io/v1/boards/' + greenhouse_api_job_board + '/jobs?content=true')
                .then(jobResponse => {
                  if (Object.keys(jobResponse).length > 0) {
                    if (jobResponse.jobs !== undefined || jobResponse.length > 0) {
                      jobResponse.jobs.forEach(boardJob => {
                        boardJob.job_company = company;
                        boardJob.job_board = greenhouse_api_job_board;
                        var content_long = decodeHTML(boardJob.content).replace(/(<([^>]+)>)/ig, "");
                        boardJob.content_short = content_long.substr(0, content_long.lastIndexOf(' ', 200)) + '&hellip;';

                        boardJob.city = '';
                        if (boardJob.location.name !== "" && boardJob.location.name.includes(',')) {
                          let arrLocation = boardJob.location.name.split(",");
                          boardJob.city = arrLocation[0];
                        }

                        if (boardJob.city !== '' && state.jobsLocations.indexOf(boardJob.city) === -1) {
                          state.jobsLocations.push(boardJob.city);
                          state.jobsLocations.sort();
                        }

                        if (boardJob.departments && boardJob.departments.length > 0) {
                          boardJob.departments.forEach(department => {
                            if (state.jobsDepartments.indexOf(department.name) === -1) {
                              state.jobsDepartments.push(department.name.trim());
                              state.jobsDepartments.sort();
                            }
                          })
                        }
                        if(boardJob.job_company.name === "The Creative Engagement Group"){
                          boardJob.job_company.name = "Inizio Engage XD";
                        }
                        state.boardJobs.push(boardJob);
                      });
                    }
                  }
                });
            })
          }
        });

        //console.log('this.boardJobs',this.boardJobs);

        let objectFitImages = require('object-fit-images');

        // Object-fit fix for IE
        objectFitImages('img.objectFitImage');

        if (currentInstance.parent.data.window && currentInstance.parent.data.window.innerWidth >= currentInstance.parent.data.desktop && !currentInstance.parent.data.isIE) {

          let svgPathsJobs = [
            {
              id: 'svgGroup10__path1',
              tweenId: '2',
              speed: 2,
              delay: 0,
              trigger: "#svgGroup10",
              offset: '0'
            },
            {
              id: 'svgGroup10__path2',
              tweenId: '2',
              speed: 2,
              delay: 0,
              trigger: "#svgGroup10",
              offset: '0'
            },
            {
              id: 'svgGroup10__path3',
              tweenId: '2',
              speed: 2,
              delay: 0,
              trigger: "#svgGroup10",
              offset: '0'
            }
          ]

          let svgTweensJobs = {};

          // Loop through SVG PATHS array / build tweens and scenes
          //this.svgAnimate(svgPathsJobs, svgTweensJobs);
        }
      };

      const updateLocationFilter = (newLocation) => {
        state.filterLocation = newLocation === 'All' ? null : newLocation;
      };

      const updateAgencyFilter = (newAgency) => {
        state.filterAgency = newAgency === 'All' ? null : newAgency;
      };

      const updateDepartmentFilter = (newDepartment) => {
        state.filterDepartment = newDepartment === 'All' ? null : newDepartment;
      };

      const slugifyNew = (str) => {
        var slug = slugify(str);
        return slug;
      }

      api(backend + 'page/jobs/')
        .then((data) => {
          state.jsonData = data.data;

          //EventBus.$emit('menu_items', this.jsonData.site_details.menu_items);
          EventBus.$emit('isLoaded', true);
          EventBus.$emit('footer', state.jsonData.site_details.tceg);
          EventBus.$emit('isPurple', false);
          EventBus.$emit('statement', state.jsonData.site_details.footer_statement);

          state.jsonData.site_details.all_companies.forEach(company => {
            state.agencyColours[company.short_name] = company.colour;
          });

          state.agencyColours['TCEG'] = state.jsonData.site_details.tceg.colour;

          state.jsonData.site_details.all_companies.forEach(company => {
            if (!['TCEG Film', 'TCEG Digital', 'Behavioural Science'].includes(company.name))
              state.jobsAgencies.push(company.name);
          });

          state.jobsAgencies.push('Inizio Engage XD');
          state.jobsAgencies.sort();

          nextTick().then(() => {
            oldMounted();
          });

        }).catch(error => {
        state.loading = false;
      });

      return{
        state,
        isLoaded,
        window,
        openJob,
        oldMounted,
        updateLocationFilter,
        updateAgencyFilter,
        updateDepartmentFilter,
        slugifyNew
      }
    }
  }</script>
