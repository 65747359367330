import { TweenMax, TimelineMax, Power4 } from "gsap"; // What to import from gsap
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
// This is your plugin object. It can be exported to be used anywhere.
import { ref } from 'vue';
export default function MyPluginCPS(isModalVisible, mouseX, mouseY, desktop, window, router) {

  /*function closeModal() {
    console.log(isModalVisible)
    isModalVisible = false;
    document.body.style.overflow = "visible";
    jwplayer().stop();
    window.removeEventListener('popstate', detectBackButton);
    console.log(isModalVisible)
  }

  function showModal() {
    console.log(isModalVisible)
    isModalVisible = true;
    document.body.style.overflow = "hidden";
    //window.addEventListener('popstate', detectBackButton);
    console.log(isModalVisible)
  }*/

  function handleResize() {
    window.innerWidth = window.innerWidth;
    window.height = window.innerHeight;
  }

  function mouseMove(e) {
    mouseX = e.clientX;
    mouseY = e.clientY;
  }

  function pathPrepare(el) {
    if (el && window.innerWidth >= desktop) {
      let lineLength = el.getTotalLength();
      el.style.strokeDasharray = lineLength;
      el.style.strokeDashoffset = lineLength;
    }
  }
  function svgAnimate(svgPaths, tweens) {
    // Loop through SVG PATHS array / build tweens and scenes
    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
    for (let i = 0; i < svgPaths.length; i++) {
      let svgPath = svgPaths[i];

      let path = document.getElementById(svgPath.id);

      pathPrepare(path);

      let tween;
      if (!(svgPath.tweenId in tweens)) {
        tween = new TimelineMax();
        tweens[svgPath.tweenId] = tween;
      } else {
        tween = tweens[svgPath.tweenId];
      }

      // Build tween
      tween.add(TweenMax.to(path, svgPath.speed, { strokeDashoffset: 0, ease: Power4.easeOut }), svgPath.delay);

      // Build scene

      let scene = new ScrollMagic.Scene({
        triggerElement: svgPath.trigger,
        offset: svgPath.offset
      })
        .setTween(tween)
      // .addIndicators({name: svgPath.tweenId})

      // Add scene
      new ScrollMagic.Controller().addScene(scene);

    }

  }

  function goToItem(itemLink, itemName) {
    console.log('goto')
    console.log({ name: itemName, path: itemLink, params: { link: itemLink } });
    if (itemName !== 'person') {
      router.push({ name: itemName, path: itemLink, params: { link: itemLink } })
    } else {
      router.push({ path: '/people/' + itemLink });
    }
  }

  function hubspotSlug(slug) {
    let _hsq = window._hsq = window._hsq || [];
    //console.log(slug);
    _hsq.push(['setPath', slug]);
    _hsq.push(['trackPageView']);
  }

  return {
    //showModal,
    //closeModal,
    handleResize,
    mouseMove,
    svgAnimate,
    goToItem,
    hubspotSlug,
    pathPrepare
  }
}

