<template>
  <div v-if="isLoaded" class="servicesPage">

    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />

    <header-component type="services"
                      class='header--services'
                      :title=state.jsonData.content.banner_small_text
                      :title2=state.jsonData.content.banner_large_text
                      :image=state.jsonData.content.banner_image>
    </header-component>
    <main>
      <div class="servicesPage__intro">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.7 374.2" id="svgGroup34">
          <g>
            <path id="svgGroup34__path3" class="svgpath svgpath--mist" d="M60.3,231.5c35.9,35.8,51.3,87.4,40.9,137" />
            <path id="svgGroup34__path1" class="svgpath svgpath--fuschia" d="M48.5,7c-58.7,58.7-58.7,154,0,212.7" />
            <path id="svgGroup34__path2" class="svgpath svgpath--green" d="M16.5,113.3c0,76.4,62,138.4,138.4,138.4" />
          </g>
        </svg>
        <div class="servicesPage__intro__wrapper" v-html=state.jsonData.content.post_content></div>
      </div>
      <div class="servicesPage__services">
        <ul class="servicesPage__services__list">
          <li v-for="(item, index) in state.jsonData.content.services" class="servicesPage__services__list__listItem" :id=item.link>
            <service :item=item :index=index>
            </service>
          </li>
        </ul>
      </div>
    </main>
  </div>
</template>
<script>
  import EventBus from '../eventbus.js';
  import HeaderComponent from './widgets/header-component';
  import Service from './widgets/service';
  //import VueScrollTo from 'vue-scrollto';
  import { api } from '../utils/api-gateway';
  import HeaderBar from './widgets/header-bar';
  import { useRoute, useRouter } from "vue-router";
  import { computed, reactive, nextTick } from "vue";
  import MyPluginCPS from '../plugins/MyPluginCPS.js';
  import { useInjects } from '../utils/globals-inject';
  export default {
    name: 'ServicesPage',
    components: {
      HeaderBar,
      HeaderComponent,
      Service
    },
    setup() {
      const router = useRouter();
      let { window, desktop, mobile, tablet, mouseX, mouseY, backend, isIE } = useInjects();
      const { svgAnimate } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);

      const state = reactive({
        jsonData: []
      });

      const isLoaded = computed(() => {
        if (state.jsonData.hasOwnProperty('content')) {
          return true;
        }
        return false;
      });

      const oldMounted = () => {
        let objectFitImages = require('object-fit-images');

        // Object-fit fix for IE
        objectFitImages('img.objectFitImage');

        // Check for a service ID and scroll to a section
        if (window.location.hash) {
          let serviceID = window.location.hash.replace('#', '');
          //VueScrollTo.scrollTo(document.getElementById(serviceID))
        }

        if (window.innerWidth >= desktop && !isIE) {

          let svgPathsServices = [
            {
              id: 'svgGroup34__path1',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: ".servicesPage__intro__wrapper",
              offset: '0'
            },
            {
              id: 'svgGroup34__path2',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: ".servicesPage__intro__wrapper",
              offset: '0'
            },
            {
              id: 'svgGroup34__path3',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: ".servicesPage__intro__wrapper",
              offset: '0'
            },
            {
              id: 'svgGroup35__path1',
              tweenId: '2',
              speed: 2,
              delay: 0,
              trigger: ".service--imgRight--v1 .service__imgWrapper",
              offset: '200'
            },
            {
              id: 'svgGroup35__path2',
              tweenId: '2',
              speed: 2,
              delay: 0,
              trigger: ".service--imgRight--v1 .service__imgWrapper",
              offset: '200'
            },
            {
              id: 'svgGroup35__path3',
              tweenId: '2',
              speed: 2,
              delay: 0,
              trigger: ".service--imgRight--v1 .service__imgWrapper",
              offset: '200'
            },
            {
              id: 'svgGroup35__path4',
              tweenId: '2',
              speed: 2,
              delay: 0,
              trigger: ".service--imgRight--v1 .service__imgWrapper",
              offset: '200'
            },
            {
              id: 'svgGroup36__path1',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v1 .service__info__title",
              offset: '0'
            },
            {
              id: 'svgGroup36__path2',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v1 .service__info__title",
              offset: '0'
            },
            {
              id: 'svgGroup36__path3',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v1 .service__info__title",
              offset: '0'
            },
            {
              id: 'svgGroup37__path1',
              tweenId: '4',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v2 .service__info",
              offset: '0'
            },
            {
              id: 'svgGroup37__path2',
              tweenId: '4',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v2 .service__info",
              offset: '0'
            },
            {
              id: 'svgGroup37__path3',
              tweenId: '4',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v2 .service__info",
              offset: '0'
            },
            {
              id: 'svgGroup38__path1',
              tweenId: '5',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v3 .service__imgWrapper",
              offset: '0'
            },
            {
              id: 'svgGroup38__path2',
              tweenId: '5',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v3 .service__imgWrapper",
              offset: '0'
            },
            {
              id: 'svgGroup38__path3',
              tweenId: '5',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v3 .service__imgWrapper",
              offset: '0'
            },
            {
              id: 'svgGroup39__path1',
              tweenId: '6',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v3 .service__info__title",
              offset: '0'
            },
            {
              id: 'svgGroup39__path2',
              tweenId: '6',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v3 .service__info__title",
              offset: '0'
            },
            {
              id: 'svgGroup40__path1',
              tweenId: '7',
              speed: 2,
              delay: 0,
              trigger: ".service--imgRight--v5 .service__info",
              offset: '-200'
            },
            {
              id: 'svgGroup40__path2',
              tweenId: '7',
              speed: 2,
              delay: 0,
              trigger: ".service--imgRight--v5 .service__info",
              offset: '-200'
            },
            {
              id: 'svgGroup40__path3',
              tweenId: '7',
              speed: 2,
              delay: 0,
              trigger: ".service--imgRight--v5 .service__info",
              offset: '-200'
            },
            {
              id: 'svgGroup41__path1',
              tweenId: '8',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v5 .service__imgWrapper",
              offset: '-200'
            },
            {
              id: 'svgGroup41__path2',
              tweenId: '8',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v5 .service__imgWrapper",
              offset: '-200'
            },
            {
              id: 'svgGroup41__path3',
              tweenId: '8',
              speed: 2,
              delay: 0,
              trigger: ".service--imgLeft--v5 .service__imgWrapper",
              offset: '-200'
            },
          ]

          let svgTweensServices = {};

          // Loop through SVG PATHS array / build tweens and scenes
          svgAnimate(svgPathsServices, svgTweensServices);
        }
      };

      let url;
      const route = useRoute();

      if (route.params.num) {
        state.preview_num = route.params.num;
        url = backend + 'preview/page/' + state.preview_num + '/services-page';
      } else {
        url = backend + 'page/services/';
      }

      api(url)
        .then((data) => {
          state.jsonData = data.data;
          EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
          EventBus.$emit('isLoaded', true);
          EventBus.$emit('footer', state.jsonData.site_details.tceg);
          EventBus.$emit('isPurple', false);
          EventBus.$emit('statement', state.jsonData.site_details.footer_statement);
          nextTick().then(() => {
            oldMounted();
          });

          //CU 8/1/24 - Don't think this is needed
          //state.jsonData.content.services.forEach(service => {
          //  this.$imagePreload(service.image);
          //});

        }).catch(error => {
          state.loading = false;
        });

      return {
        state,
        isLoaded,
        window,
        tablet,
        mobile,
        isIE,
        svgAnimate
      };
    },
  }</script>
