<template>
  <div v-if="isLoaded">

    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />

    <header-component :title=state.jsonData.content.post_title
                      :image=null
                      class="header--newsItem">
    </header-component>
    <main id="news-item-full" class="people-list__single-person">
      <h1>
        {{state.name}}
      </h1>
      <h2>
        {{state.jsonData.content.job_title}}
      </h2>

      <div class="wrapper">
        <picture-sources v-if="state.jsonData.content.image && state.jsonData.content.image.sizes && state.jsonData.content.image.sizes.length"
                         :targetWidth="100"
                         :sizes="state.jsonData.content.image.sizes"
                         :imgSrc="state.jsonData.content.image.guid"
                         imgAlt=""
                         imgClass="image objectFitImage" />
      </div>

      <div class="newsItem-content">
        <div class="people-list__single-person__bio">
          <div class="people-list__single-person__bio__name">
            <h3>{{state.name}}</h3>
          </div>
          <div class="people-list__single-person__bio__title">
            <span v-if="state.jsonData.content.job_title">{{state.jsonData.content.job_title}}</span>
          </div>
          <div class="people-list__single-person__bio__share-news">
            <span>Connect</span>
            <a v-if="state.jsonData.content.twitter" :href="state.jsonData.content.twitter" target="_blank" rel="noreferrer noopener">
              <img src="../assets/X-icon-dark.svg" alt="XD Icon">
            </a>
            <a v-if="state.jsonData.content.linkedin" :href="state.jsonData.content.linkedin" target="_blank" rel="noreferrer noopener">
              <img src="../assets/linkdin-logo_blue.svg" alt="Linkedin Icon">
            </a>
          </div>
        </div>
        <div class="newsItem-content__feature">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340.2 529.2" id="svgGroup36" class="people-list__single-person__svg">
            <g>
              <path id="svgGroup36__path1" class="svgpath svgpath--mist" d="M6.2,7.3v203.6c0,85.6,69.4,155,155,155" />
              <path id="svgGroup36__path2" class="svgpath svgpath--fuschia" d="M178.4,365.9c85.6,0,155,69.4,155,155c0,0,0,0,0,0" />
              <path id="svgGroup36__path3" class="svgpath svgpath--green" d="M77.5,420.1c55.7-55.7,146-55.7,201.7,0" />
            </g>
          </svg>
          <div class="people-list__single-person__description__text">
            <span>About {{state.firstName}}</span>
            <p v-html="state.personBio"></p>
          </div>
        </div>
      </div>
    </main>
  </div>

</template>
<script>
  import HeaderComponent from './widgets/header-component';
  import { api } from '../utils/api-gateway';
  import PictureSources from './widgets/picture-sources';
  import HeaderBar from './widgets/header-bar';
  import EventBus from '../eventbus.js';
  import { useRoute, useRouter } from "vue-router";
  import { computed, nextTick, reactive } from "vue";
  import MyPluginCPS from '../plugins/MyPluginCPS.js';
  import { useInjects } from '../utils/globals-inject';
  export default {
    name: 'PeoplePage',
    components: {
      HeaderBar,
      HeaderComponent,
      PictureSources
    },
    setup() {
      const router = useRouter();
      let { window, desktop, mouseX, mouseY, backend } = useInjects();
      const { svgAnimate } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);

      const state = reactive({
        jsonData: [],
        id: null,
        personBio: null,
        firstName: '',
        lastName: '',
        name: '',
        person: ''
      });

      const isLoaded = computed(() => {
        if (state.jsonData.hasOwnProperty('content')) {
          return true;
        }
        return false;
      });

      const oldMounted = () => {
        let objectFitImages = require('object-fit-images');

        // Object-fit fix for IE
        objectFitImages('img.objectFitImage');

        state.name = state.jsonData.content.name;
        state.firstName = state.jsonData.content.name.split(' ').slice(0, -1).join(' ');
        state.personBio = state.jsonData.content.bio;

        if (window.innerWidth >= state.desktop && !isIE) {

          let svgPathsServices = [
            {
              id: 'svgGroup36__path1',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__description__text",
              offset: '0'
            },
            {
              id: 'svgGroup36__path2',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__description__text",
              offset: '0'
            },
            {
              id: 'svgGroup36__path3',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__description__text",
              offset: '0'
            },
          ]
          let svgTweensServices = {};

          // Loop through SVG PATHS array / build tweens and scenes
          svgAnimate(svgPathsServices, svgTweensServices);
        }
      };

      let url;
      const route = useRoute();

      if (route.params.num) {
        state.preview_num = route.params.num;
        state.preview_title = route.params.title;
        url = backend + 'preview/people/' + state.preview_num + '/' + state.preview_title;
      } else {
        state.name = route.params.person;
        url = backend + 'people/' + state.name + '/';
      }
      api(url)
        .then((data) => {
          state.jsonData = data.data;
          nextTick().then(() => {
            oldMounted();
            EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
            EventBus.$emit('isLoaded', true);
            EventBus.$emit('footer', state.jsonData.site_details.tceg);
            EventBus.$emit('isPurple', true);
            EventBus.$emit('statement', state.jsonData.site_details.footer_statement);
            if (!state.jsonData.image) {
              state.noImage = true;
            }
          })
        }).catch(error => {
          state.loading = false;
        });
      return {
        state,
        isLoaded,
        svgAnimate
      };
    }
  }</script>
