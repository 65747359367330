<template>
  <div class="videoStoryBoxes" :class="extra_class">
    <div class="container sectionHeading">
      <h2>{{heading}}</h2>
    </div>
    <div class="container">
      <div class="col-33 outerVideoBox" v-for="(box,index) in boxes" :data-index="index">
        <a href="#" class="innerVideoBox" @click="toggleModal">
          <div class="imageContainer">
            <picture-sources
              v-if="box.image"
              :targetWidth="box.image.width"
              :sizes="box.image.sizes"
              :imgSrc="box.image.guid"
              :imgAlt="box.heading"
              imgClass="objectFitImage"
            />
          </div>
          <div class="textContainer">
            <h3>{{box.heading}}</h3>
            <div class="content">
              <p>{{box.short_content}}</p>
              <button class="openVideoWindow" :aria-label="box.heading">
                <svg xmlns="http://www.w3.org/2000/svg" width="25.804" height="27.033" viewBox="0 0 25.804 27.033">
                  <g id="Group_51" data-name="Group 51" transform="translate(2924.304 -2210.142)">
                    <line id="Line_2" data-name="Line 2" x2="22.804" transform="translate(-2922.804 2223.659)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                    <path id="Path_36" data-name="Path 36" d="M19.927,12.266l9.707,11.4-9.707,11.4" transform="translate(-2929.634 2199.991)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </a>
        <div class="modalBox">
          <div class="modalWindow">
            <button class="closeModal" aria-label="Close Modal" @click="toggleModal">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="29.339" height="29.078" viewBox="0 0 29.339 29.078">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width="29.339" height="29.078" transform="translate(0 0)" fill="#fff"/>
                  </clipPath>
                </defs>
                <g id="Group_20" data-name="Group 20" transform="translate(0 0.003)">
                  <g id="Group_19" data-name="Group 19" transform="translate(0 -0.003)" clip-path="url(#clip-path)">
                    <path id="Path_29" data-name="Path 29" d="M18.209,14.536l10.4-10.3a2.465,2.465,0,0,0,0-3.508,2.519,2.519,0,0,0-3.54,0l-10.4,10.3L4.273.723a2.519,2.519,0,0,0-3.54,0,2.465,2.465,0,0,0,0,3.508l10.4,10.3L.733,24.84a2.465,2.465,0,0,0,0,3.508,2.519,2.519,0,0,0,3.54,0l10.4-10.3,10.4,10.3a2.519,2.519,0,0,0,3.54,0,2.465,2.465,0,0,0,0-3.508Z" transform="translate(0 0.003)" fill="#fff"/>
                  </g>
                </g>
              </svg>
            </button>
            <div class="videoActionButtons" v-if="box.jw_video_id">
              <button class="previousVideo" aria-label="Previous Video" @click="changeVideoBoxModal(index,'previous')"><span class="title">Previous Story</span></button>
              <button class="nextVideo" aria-label="Next Video" @click="changeVideoBoxModal(index,'next')"><span class="title">Next Story</span></button>
            </div>
            <div class="videoContainer">
              <div class="videoBoxModal" :id="`videoBoxModal-${box.jw_video_id}`" :data-video-id="box.jw_video_id" v-if="box.jw_video_id"></div>
              <h3 v-else>Video Not Found</h3>
            </div>
            <div class="videoContent">
              <h3>{{box.heading}}</h3>
              <p class="shortContent">{{box.short_content}}</p>
              <div class="hiddenContent" v-html="box.content">
              </div>
              <button class="revealButton" :aria-label="box.heading" @click="toggleReadMore">
                Read<br>More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PictureSources from "./picture-sources.vue";

export default {
  name: "video-story-boxes",
  components: {
    PictureSources
  },
  props: {
    heading: String,
    boxes: Array,
    extra_class: String
  },
  setup(props){
    const initJWPlayer = (strPlayerID) => {
      let modalPlayerInstance = window.jwplayer(`videoBoxModal-${strPlayerID}`);
      let videoURL = `https://cdn.jwplayer.com/videos/${strPlayerID}-r1emxXWY.mp4`;
      modalPlayerInstance.setup({
        id: `videoBoxModal-${strPlayerID}`,
        file: videoURL,
        aspectratio: "x:y",
        autostart: true,
        repeat: true,
        mute: true,
        //stretching: (this.window.innerWidth <= this.mobile) ? "uniform" : "fill",
        stretching: "fill",
        controls: true
      });
    };
    const determineActionButtons = () => {
      // determine if there are any other videos
      let elmPreviousVideo = event.target.closest('.outerVideoBox').querySelector('.previousVideo');
      let elmNextVideo = event.target.closest('.outerVideoBox').querySelector('.nextVideo');
      let intVideoBox = parseInt(event.target.closest('.outerVideoBox').getAttribute('data-index'));
      let intTotalVideoBoxes = event.target.closest('.outerVideoBox').parentNode.querySelectorAll('.outerVideoBox').length;
      if(intVideoBox === 0){
        if(elmPreviousVideo){
          elmPreviousVideo.disabled = true;
        }
      } else if(intVideoBox > 0 && intVideoBox <= intTotalVideoBoxes){
        if(elmPreviousVideo){
          elmPreviousVideo.disabled = false;
        }
        if(elmNextVideo){
          elmNextVideo.disabled = false;
        }
      } else {
        if(elmNextVideo){
          elmNextVideo.disabled = true;
        }
      }
    };
    const toggleModal = (event) => {
      event.preventDefault();
      if(document.querySelector('body').classList.contains('modal-active')){
        if(document.querySelector('.modalBox.active .videoContainer video')){
          document.querySelector('.modalBox.active .videoContainer video').pause();
        }
        document.querySelector('body').classList.remove('modal-active');
        document.querySelector('.modalBox.active').classList.remove('active');
      } else {
        document.querySelector('body').classList.add('modal-active');
        event.target.closest('.outerVideoBox').querySelector('.modalBox').classList.add('active');

        if(event.target.closest('.outerVideoBox').querySelector('.videoBoxModal')){
          let strPlayerID = event.target.closest('.outerVideoBox').querySelector('.videoBoxModal').getAttribute('data-video-id');
          initJWPlayer(strPlayerID);
        }
        determineActionButtons();
        checkVideo();
      }
    };
    const changeVideoBoxModal = (intVideoBox,strDirection) => {
      let intNextIndex = (strDirection === "next") ? intVideoBox+1 : intVideoBox-1;
      let elmNextVideoBox = event.target.closest('.outerVideoBox').parentNode.querySelector('.outerVideoBox[data-index="'+ intNextIndex +'"]');

      // remove current instance
      toggleModal(event);
      document.querySelector('body').classList.add('modal-active');
      elmNextVideoBox.querySelector('.modalBox').classList.add('active');

      // find and initiate new versions
      if(elmNextVideoBox.querySelector('.videoBoxModal')){
        let strPlayerID = elmNextVideoBox.querySelector('.videoBoxModal').getAttribute('data-video-id');
        initJWPlayer(strPlayerID);
      }
      // check video if the buttons need to be shown or not.
      determineActionButtons();
      checkVideo();
    };
    const toggleReadMore = (event) => {
      event.preventDefault();
      if(document.querySelector('.modalBox.active').querySelector('.hiddenContent').classList.contains('active')){
        document.querySelector('.modalBox.active').querySelector('.hiddenContent').classList.remove('active');
        event.target.innerHTML = "Read<br>More";
        document.querySelector('.modalBox.active').querySelector('.videoContent').classList.remove('active');
      } else {
        document.querySelector('.modalBox.active').querySelector('.hiddenContent').classList.add('active');
        event.target.innerHTML = "Read<br>Less";
        document.querySelector('.modalBox.active').querySelector('.videoContent').classList.add('active');
      }
    };

    const checkVideo = () => {
      setTimeout(function(){
        if(document.querySelector('.videoStoryBoxes .modalBox.active')){
          if(document.querySelector('.videoStoryBoxes .modalBox.active video')){
            let elmVideo = document.querySelector('.videoStoryBoxes .modalBox.active video');

            // initiate to see if buttons are on show
            videoPlayState();

            // event listeners to activate the buttons depending on state of video
            elmVideo.removeEventListener("pause",videoPlayState);
            elmVideo.removeEventListener("playing",videoPlayState);
            elmVideo.removeEventListener("ended",videoPlayState);

            elmVideo.addEventListener("pause",videoPlayState);
            elmVideo.addEventListener("playing",videoPlayState);
            elmVideo.addEventListener("ended",videoPlayState);
          }
        }
      },250);
    };

    const videoPlayState = () => {
      let elmVideo = document.querySelector('.videoStoryBoxes .modalBox.active video');
      let elmVideoActionButtons = document.querySelector('.videoStoryBoxes .modalBox.active .videoActionButtons');
      if(elmVideo){
        if(elmVideo.paused){
          elmVideoActionButtons.style.display = "block";
        } else {
          elmVideoActionButtons.style.display = "none";
        }
      }
    };

    return{
      toggleModal,
      changeVideoBoxModal,
      initJWPlayer,
      toggleReadMore,
      checkVideo
    }
  }
}
</script>
<style lang="scss">

</style>
