<template>
  <div id="imageReveal" class="imageReveal">
      <twenty-twenty v-if="state.windowWidth >= desktop"
                     offset="0.42"
                     :before=$props.beforeImage
                     :after=$props.afterImage />
    <div class="overlay overlay--gradient--black"></div>
    <img :src=$props.afterImage alt="Background Image" class="imageReveal__background objectFitImage" />

    <a class="button button--purple" id="buttonReveal"
       @click="goToItem($props.caseStudyLink, 'work-item')">
      <span>explore</span>
    </a>
    <div class="imageReveal__info">
      <h3>{{ $props.title }}</h3>
      <p>{{ $props.description }}</p>
    </div>
  </div>
</template>
<script >
  import PictureSources from './picture-sources';
  import TwentyTwenty from './twenty-twenty';
  import MyPluginCPS from '../../plugins/MyPluginCPS.js';
  import { useRouter } from "vue-router";
  import { useInjects } from '../../utils/globals-inject';
  import { reactive, watch } from "vue";
  export default {
    name: 'image-reveal',
    components: {
      PictureSources,
      TwentyTwenty
    },
    props: {
      title: String,
      description: String,
      beforeImage: String,
      afterImage: String,
      caseStudyId: Number,
      caseStudyLink: String,
      dynamicWidth: Number
    },
    setup(props, context) {
      const router = useRouter();

      let { window, desktop, mouseX, mouseY } = useInjects();

      const state = reactive({
        windowWidth: window.innerWidth
      });

      const { goToItem } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);

      watch(() => props.dynamicWidth, (newValue, oldValue) => {
        state.windowWidth = newValue;
      })

      return {
        state,
        window,
        desktop,
        goToItem
      }
    }
  }</script>
