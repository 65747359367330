<template>
  <div class="workDetails">
    <ul class="workDetails__list">
      <li class="workDetails__list__item" v-for="(item, key, index) in $props.caseStudySummary">
        <span  style="font-weight: 400; font-size: 0.75rem !importan; line-height: 1rem !important; margin-bottom: 0.25rem !important">CLIENT</span>
        <span style="margin-bottom: 1rem">{{ item.client }}</span>
        <span style="font-size: 0.75rem !important; line-height: 1rem !important; margin-bottom: 0.25rem !important">SECTOR</span>
        <span style="margin-bottom: 1rem">{{ item.sector }}</span>
        <span style=" font-size: 0.75rem !important; line-height: 1rem !important; margin-bottom: 0.25rem !important">AUDIENCE</span>
        <span style="margin-bottom: 1rem">{{ item.audience }}</span>
      </li>
    </ul>
  </div>
</template>
<script >
  export default {
    name: 'work-details',
    props: {
      item: Object,
      caseStudySummary: Object,
      caseStudyColor1: String,
      caseStudyColor2: String
    }
  }
</script>
