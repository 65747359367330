<template>
  <div class="service" :class="getClass()">
    <div class="service__imgWrapper" :class="{'service-hover' : state.serviceHover, '' : !state.serviceHover}">

      <picture v-if="item.services_image.sizes.length" class="service__image objectFitImage">
        <source v-for="size in item.services_image.sizes" :media="'(max-width: '+size.width+'px)'" :srcset="size.guid">
        <img :src="item.services_image.guid" alt="" class="service__image objectFitImage">
      </picture>

      <picture-sources v-if="item.services_image && item.services_image.sizes && item.services_image.sizes.length"
                       :targetWidth="window.innerWidth <= tablet ? 100 : 50"
                       :sizes="item.services_image.sizes"
                       :imgSrc="item.services_image.guid"
                       imgClass="service__image objectFitImage"
                       :imgAlt="item.services_image.alt" />

      <svg v-if="index == 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.1 375.9" id="svgGroup35">
        <g>
          <path id="svgGroup35__path1" class="svgpath svgpath--mist" d="M175.8,4.7l0.3,159c0,102.6-73.6,188.1-171,206.3" />
          <path id="svgGroup35__path4" class="svgpath svgpath--fuschia" d="M154.9,100.8v53.7c-0.1,63.6,28.7,123.8,78.3,163.6" />
          <path id="svgGroup35__path3" class="svgpath svgpath--fuschia" d="M176.2,60.9v102.8c0.1,42.6-12.9,84.3-37.2,119.3" />
          <path id="svgGroup35__path2" class="svgpath svgpath--green" d="M165.8,33.7l0,120.8c0,28.1-5.6,55.9-16.5,81.7c-24.1,56.9-72,100.3-131,118.8" />
        </g>
      </svg>
      <svg v-if="index == 1 || index == 11" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340.2 529.2" id="svgGroup36">
        <g>
          <path id="svgGroup36__path1" class="svgpath svgpath--mist" d="M6.2,7.3v203.6c0,85.6,69.4,155,155,155" />
          <path id="svgGroup36__path2" class="svgpath svgpath--fuschia" d="M178.4,365.9c85.6,0,155,69.4,155,155c0,0,0,0,0,0" />
          <path id="svgGroup36__path3" class="svgpath svgpath--green" d="M77.5,420.1c55.7-55.7,146-55.7,201.7,0" />
        </g>
      </svg>
      <svg v-if="index == 4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.5 323.5" id="svgGroup37">
        <g>
          <path id="svgGroup37__path3" class="svgpath svgpath--mist" d="M105.6,4.2c85.6,0,155,69.4,155,155c0,0,0,0,0,0v160.6" />
          <path id="svgGroup37__path2" class="svgpath svgpath--fuschia" d="M105.6,4.2c85.6,0,155,69.4,155,155c0,0,0,0,0,0" />
          <path id="svgGroup37__path1" class="svgpath svgpath--green" d="M4.8,58.4c55.7-55.7,146-55.7,201.7,0" />
        </g>
      </svg>
      <svg v-if="index == 5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329.5 456.4" id="svgGroup38">
        <g>
          <path id="svgGroup38__path3" class="svgpath svgpath--mist" d="M155.7,155.9c-83.1,0-150.4,67.3-150.4,150.4v145.1" />
          <path id="svgGroup38__path1" class="svgpath svgpath--fuschia" d="M322.8,5.5c0.1,39.9-15.8,78.2-44.1,106.4c-28.2,28.3-66.5,44.1-106.4,44.1" />
          <path id="svgGroup38__path2" class="svgpath svgpath--green" d="M270.2,103.3c-54,54-141.6,54-195.7,0" />
        </g>
      </svg>
      <svg v-if="index == 6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 323 485.8" id="svgGroup39">
        <g>
          <path id="svgGroup39__path2" class="svgpath svgpath--fuschia" d="M223.7,316.8c25.2,0,49.4,10,67.2,27.8c37.1,37.1,37.1,97.3,0,134.4c0,0,0,0,0,0" />
          <path id="svgGroup39__path1" class="svgpath svgpath--mist" d="M7.4,7.4v158.9c0,83.1,67.3,150.4,150.4,150.4c0,0,0,0,0,0h52.3" />
        </g>
      </svg>
    </div>
    <div class="service__info" @mouseover="mouseOver()" @mouseleave="mouseOut" @click="item.detail_page ? goToItem(item.link, item.itemLink) : ''"
         :style="(item.detail_page === false) ? 'cursor:default;' : ''">
      <h2 class="service__info__title">{{ item.service }}</h2>
      <p class="service__info__text">{{ item.text }}</p>
      <button class="button button--purple" v-if="item.detail_page">{{ item.button }}</button>
      <svg v-if="index == 8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.9 338.9" id="svgGroup40">
        <g>
          <path id="svgGroup40__path3" class="svgpath svgpath--mist" d="M364.5,177.9c0,85.6-69.4,155-155,155H5.9" />
          <path id="svgGroup40__path1" class="svgpath svgpath--fuschia" d="M519.5,5.8C519.5,5.8,519.5,5.8,519.5,5.8c-85.6,0-155,69.4-155,155" />
          <path id="svgGroup40__path2" class="svgpath svgpath--green" d="M418.7,59.9c-55.7,55.7-55.7,146,0,201.7" />
        </g>
      </svg>
      <svg v-if="index == 9" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.8 325.6" id="svgGroup41">
        <g>
          <path id="svgGroup41__path1" class="svgpath svgpath--mist" d="M5,5v160.6c0,0,0,0,0,0c0,85.6,69.4,155,155,155" />
          <path id="svgGroup41__path2" class="svgpath svgpath--fuschia" d="M5,165.6C5,165.6,5,165.6,5,165.6c0,85.6,69.4,155,155,155" />
          <path id="svgGroup41__path3" class="svgpath svgpath--green" d="M59.2,266.4c55.7,55.7,146,55.7,201.7,0" />
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
  import PictureSources from './picture-sources';
  import { reactive } from "vue";
  import MyPluginCPS from '../../plugins/MyPluginCPS.js';
  import { useRouter } from "vue-router";
  import { useInjects } from '../../utils/globals-inject';
  export default {
    name: 'service',
    components: {
      PictureSources
    },
    props: {
      item: Object,
      index: Number
    },
    setup(props) {
      const router = useRouter();
      let { window, desktop, mobile, tablet, mouseX, mouseY } = useInjects();
      const { goToItem } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);
      const state = reactive({
        serviceHover: false
      });

      function getClass() {
        return {
          'service--imgRight service--imgRight--v1': props.index == 0,
          'service--imgLeft service--flexColumn service--imgLeft--v1': props.index == 1 || props.index == 11,
          'service--imgRight service--addMarginLeft service--imgRight--v2': props.index == 2 || props.index == 12,
          'service--imgLeft service--imgLeft--v2': props.index == 3 || props.index == 13,
          'service--imgRight service--addMarginLeft service--imgRight--v3': props.index == 4 || props.index == 14,
          'service--imgLeft service--addMarginLeft service--imgLeft--v3': props.index == 5 || props.index == 15,
          'service--imgRight service--imgRight--v4': props.index == 6,
          'service--imgLeft service--imgLeft--v4': props.index == 7,
          'service--imgRight service--imgRight--v5': props.index == 8,
          'service--imgLeft service--imgLeft--v5': props.index == 9,
          'service--imgRight service--imgRight--v6': props.index == 10
        }
      };

      const mouseOver = () => {
        state.serviceHover = true;
      };

      const mouseOut = () => {
        state.serviceHover = false;
      };

      return {
        state,
        window,
        tablet,
        mobile,
        getClass,
        mouseOver,
        mouseOut,
        goToItem
      };
    }

  }</script>
