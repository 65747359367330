<template>
  <div v-if="isLoaded" class="serviceDetailPage " :class="state.serviceExtraClass">
    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />

    <header-component type='serviceDetail'
                      class='header--serviceDetail'
                      :title=state.largeTitle
                      :image="state.bannerImage ? state.bannerImage : null"
                      :video="state.bannerVideo ? state.bannerVideo : null"
                      :code="state.bannerVideoCode ? state.bannerVideoCode : null"
                      :popupCode="state.strPopupVideo ? state.strPopupVideo : null"
                      :intro=state.smallTitle
                      :additionalContent="state.bannerAdditionalContent ? state.bannerAdditionalContent : ''"
                      :class="{'header--overlay header--overlay--solid': state.overlay, 'modalVersion': state.blPopupVideo, 'additionalContent': state.blAdditionalContent}"
                      id="header">
    </header-component>
    <main>
      <div v-if="state.serviceDetailForm > 0" class="serviceDetailPage__contactCtaWrapper">
        <a class="button button--purple" id="buttonContact" :href=state.contactUrl target="_blank">
          get i<br>touch
        </a>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 316.1 480" id="svgGroup57_0" class="svgGroup57">
          <g>
            <path name="path1" id="svgGroup57_0__path1" class="svgpath svgpath--mist" d="M313.7,2.4v158.9c0,83.1-67.3,150.4-150.4,150.4c0,0,0,0,0,0h-52.3" />
            <path name="path2" id="svgGroup57_0__path2" class="svgpath svgpath--green" d="M97.4,311.8c-25.2,0-49.4,10-67.2,27.8c-37.1,37.1-37.1,97.3,0,134.4c0,0,0,0,0,0" />
            <path name="path3" id="svgGroup57_0__path3" class="svgpath svgpath--fuschia" d="M146.1,337.2c-38.7-25.8-91-15.4-116.8,23.3c-9.2,13.8-14.1,30-14.2,46.5" />
          </g>
        </svg>
      </div>
      <div class="serviceDetailPage__intro" :class="state.introAdditionalClass">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299.7 365.2" id="svgGroup56_0" class="svgGroup56">
          <g>
            <path name="path4" id="svgGroup56_0__path4" class="svgpath svgpath--mist" d="M82.5,40.7c-73.2,62-99.3,163.4-65.3,253" />
            <path name="path3" id="svgGroup56_0__path3" class="svgpath svgpath--fuschia" d="M82.5,40.7c-26.2,22-47.1,49.7-61,81" />
            <path name="path2" id="svgGroup56_0__path2" class="svgpath svgpath--mist" d="M296.5,14.6C220.4-12.3,135.7,7,78.8,64.3c-57.7,58-74.7,141.4-50.9,214.3
                        c10.2,31.3,27.6,59.8,50.9,83.1" />
            <path name="path1" id="svgGroup56_0__path1" class="svgpath svgpath--green" d="M296.5,14.5c-73.7-25.8-159-9.4-218,49.2" />
          </g>
        </svg>
        <p v-html="state.intro"></p>
      </div>

      <div class="serviceDetailPage__content">
        <template v-for="(item, index) in state.serviceDetailContent"
                  class="serviceDetailPage__circles component-background"
                  :class="{'serviceDetailPage__circles--nopaddingtop': item.circles.layout === 'coloured'}">

          <!-- VIDEO STORY BOXES  -->
          <video-story-boxes v-if="item.video_boxes"
                             :boxes="item.video_boxes.boxes"
                             :heading="item.video_boxes.section_heading"
                             :extra_class="item.video_boxes.additional_class" />

          <!-- CIRCLES (COLOURED / WHITE LAYOUTS) -->
          <div v-if="item.circles">
            <div :style="{paddingBottom: item.circles.spacing + 'px'}">
              <div class="serviceDetailPage__circles__intro">
                <h2 v-if="item.circles.title">{{ item.circles.title }}</h2>
                <div v-if="item.circles.text" v-html="item.circles.text"></div>
              </div>
              <circles :circlesListItems="item.circles.content"
                       :layout="item.circles.layout"
                       :animation="item.circles.animation"
                       :itemIndex="index"
                       :colours=item.circles.colours>
              </circles>
              <svg v-if="item.circles.layout == 'coloured'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 430.52 408.158" :id="'svgGroup9c_' + index" class="svgGroup9c">
                <g>
                  <path name="path3" :id="'svgGroup9c_' + index + '__path3'" class="svgpath svgpath--mist" d="M204.1,204.1C92.7,204.1,2.5,294.3,2.5,405.7" />
                  <path name="path2" :id="'svgGroup9c_' + index + '__path2'" class="svgpath svgpath--fuschia" d="M428,2.5c0.1,53.5-21.2,104.8-59,142.5c-37.8,37.9-89.1,59.1-142.5,59" />
                  <path name="path1" :id="'svgGroup9c_' + index + '__path1'" class="svgpath svgpath--green" d="M95.3,133.6c72.4,72.4,189.8,72.4,262.3,0" />
                </g>
              </svg>
            </div>

          </div>

          <!-- SERVICE TYPES -->
          <div v-else-if="item.service_types" class="component-background">
            <div :style="{paddingBottom: item.service_types.spacing + 'px'}">
              <div class="serviceDetailPage__service-types">
                <h2 v-if="item.service_types.introduction">{{ item.service_types.introduction }}</h2>
                <ul class="serviceDetailPage__service-types__details">
                  <li v-for="(type, index) in item.service_types.types" class="serviceDetailPage__service-types__details__item"
                      :key="index"
                      :class="{ active: active == index }">
                    {{ type }}.&nbsp;
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- TEXT BLOCK -->
          <div v-else-if="item.text_block"  :ref="item.text_block.link_reference" :style="{backgroundImage: 'url(' + item.text_block.optional_background_image + ')'}" class="component-background" :class="item.text_block.additional_class">
            <div :style="{paddingBottom: item.text_block.spacing + 'px'}">
              <div class="serviceDetailPage__textBlock" v-html=item.text_block.value >
              </div>
            </div>
          </div>

          <!-- IMAGE -->
          <div v-else-if="item.image" class="component-background">
            <div :style="{paddingBottom: item.image.spacing + 'px'}">
              <div class="serviceDetailPage__image">
                <picture-sources v-if="item.image.value && item.image.value.sizes && item.image.value.sizes.length"
                                 :targetWidth="100"
                                 :sizes="item.image.value.sizes"
                                 :imgSrc="item.image.value.guid"
                                 :imgAlt="state.largeTitle"
                                 imgClass="objectFitImage"
                                 :imagefit="(item.image.blurred === '1') ? 'width:unset;height:unset;' : (item.image.stretch === 'width') ? 'width:unset;' : (item.image.stretch === 'height') ? 'height:unset;' : ''" />
                <svg v-if="item.image.animation && item.image.animation.includes('Animation 1') && window.innerWidth >= desktop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 232.975 733.041" :id="'svgGroup42_' + index" class="svgGroup42">
                  <g>
                    <path :id="'svgGroup42_' + index + '__path8'" class="svgpath svgpath--fuschia" d="M84.6,338.2v192.6" />
                    <path :id="'svgGroup42_' + index + '__path7'" class="svgpath svgpath--mist" d="M231.2,2.9c-97.3,18.3-171,103.7-171,206.3v312.9" />
                  </g>
                  <g>
                    <path :id="'svgGroup42_' + index + '__path1'" class="svgpath svgpath--mist" d="M231.5,2.9c-97.3,18.3-171,103.7-171,206.3V731" />
                    <path :id="'svgGroup42_' + index + '__path2'" class="svgpath svgpath--fuschia" d="M3.5,54.9c49.6,39.8,78.4,100,78.3,163.6v53.7" />
                    <path :id="'svgGroup42_' + index + '__path6'" class="svgpath svgpath--fuschia" d="M60.5,562.2v144" />
                    <path :id="'svgGroup42_' + index + '__path4'" class="svgpath svgpath--fuschia" d="M97.7,90c-24.3,35-37.2,76.7-37.2,119.3v102.8" />
                    <path :id="'svgGroup42_' + index + '__path5'" class="svgpath svgpath--fuschia" d="M84.9,368.2v256.7" />
                    <path :id="'svgGroup42_' + index + '__path3'" class="svgpath svgpath--green" d="M218.4,18c-59,18.4-106.9,61.9-131,118.8c-10.9,25.9-16.5,53.7-16.5,81.7v265.6" />
                  </g>
                </svg>
                <svg v-if="item.image.animation && item.image.animation.includes('Animation 2') && window.innerWidth >= desktop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335.7 762.3" :id="'svgGroup43_' + index" class="svgGroup43">
                  <g>
                    <path :id="'svgGroup43_' + index + '__path4'" class="svgpath svgpath--mist" d="M159.2,361.5c-85.6,0-155,69.4-155,155v241" />
                    <path :id="'svgGroup43_' + index + '__path1'" class="svgpath svgpath--mist" d="M331.2,2.5v203.8c0,85.7-69.4,155.2-155,155.2" />
                    <path :id="'svgGroup43_' + index + '__path2'" class="svgpath svgpath--green" d="M159.2,361.5c-85.6,0-155,69.4-155,155" />
                    <path :id="'svgGroup43_' + index + '__path3'" class="svgpath svgpath--fuschia" d="M58.2,415.5c55.5-55.8,145.7-56,201.4-0.6c0.2,0.2,0.4,0.4,0.6,0.6" />
                  </g>
                </svg>
                <svg v-if="item.image.animation && item.image.animation.includes('Animation 3') && window.innerWidth >= desktop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.4 1752.3" :id="'svgGroup44_' + index" class="svgGroup44">
                  <g>
                    <path :id="'svgGroup44_' + index + '__path1'" class="svgpath svgpath--mist" d="M175.4,4.3C78.1,22.6,4.5,108,4.5,210.7v587.9" />
                    <path :id="'svgGroup44_' + index + '__path5'" class="svgpath svgpath--green" d="M4.5,563.6v144" />
                    <path :id="'svgGroup44_' + index + '__path7'" class="svgpath svgpath--mist" d="M4.3,1187.7v559.8" />
                    <path :id="'svgGroup44_' + index + '__path3'" class="svgpath svgpath--fuschia" d="M41.6,91.4C17.4,126.4,4.4,168,4.5,210.7v102.8" />
                    <path :id="'svgGroup44_' + index + '__path4'" class="svgpath svgpath--fuschia" d="M15.3,518.1v338.7" />
                    <path :id="'svgGroup44_' + index + '__path6'" class="svgpath svgpath--green" d="M14.8,956.2v561" />
                    <path :id="'svgGroup44_' + index + '__path2'" class="svgpath svgpath--fuschia" d="M162.4,19.3c-59,18.4-106.9,61.9-131,118.8c-10.9,25.9-16.5,53.7-16.5,81.7v265.6" />
                    <path :id="'svgGroup44_' + index + '__path8'" class="svgpath svgpath--fuschia" d="M4.3,821.7v376.5" />
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <!-- LIST -->
          <div v-else-if="item.list"
               class="component-background">
            <div class="serviceDetailPage__list">
              <div :style="{paddingBottom: item.list.spacing + 'px'}">
                <h2>{{ item.list.introduction }}</h2>
                <ul class="outputList">
                  <li v-for="(value, index) in item.list.items"
                      class="outputList__item"
                      :key="index">
                    <a v-if="!!value.link" :href="value.link" :title="value.item" :style="{color: ((index % 2) === 0) ? state.ServiceColor1 :state.ServiceColor2 }">{{ value.item }}</a>
                    <span v-else :style="{color: ((index % 2) === 0) ? state.ServiceColor1 :state.ServiceColor2 }">{{ value.item }}</span>
                    &nbsp;
                  </li>
                </ul>
                <!--<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 316.9 277.9" :id="'svgGroup58_' + index" class="svgGroup58">
          <g>
              <path name="path1" :id="'svgGroup58_' + index + '__path1'" class="svgpath svgpath--mist" d="M2.5,2.5h53.4c0,0,0,0,0,0c71.4,0,129.3,57.9,129.3,129.3"/>
              <path name="path3" :id="'svgGroup58_' + index + '__path3'" class="svgpath svgpath--fuschia" d="M185.1,146.1c0,71.4,57.9,129.3,129.3,129.3c0,0,0,0,0,0"/>
              <path name="path2" :id="'svgGroup58_' + index + '__path2'" class="svgpath svgpath--green" d="M230.3,62c-16.8,16.7-28.2,38.1-32.7,61.4"/>
          </g>
        </svg>-->
              </div>
            </div>
          </div>

          <!-- STATISTICS -->
          <div v-else-if="item.statistics"
               class="serviceDetailPage__stats component-background">
            <div :style="{paddingBottom: item.statistics.spacing + 'px'}">
              <p class="serviceDetailPage__stats__text">
                {{ item.statistics.text }}
              </p>
              <ul class="serviceDetailPage__stats__list"
                  :class="{'serviceDetailPage__stats__list--four': item.statistics.num_per_row == '4', 'serviceDetailPage__stats__list--three': item.statistics.num_per_row == '3'}">
                <li v-for="(value, index) in item.statistics.items"
                    class="serviceDetailPage__stats__list__item">
                  <span>{{ value.value }}</span>
                  <span>{{ value.description }}</span>
                </li>
              </ul>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 221 217.3" :id="'svgGroup59_' + index" class="svgGroup59">
                <g>
                  <path name="path2" :id="'svgGroup59_' + index + '__path2'" class="svgpath svgpath--mist" d="M2.7,3.2c62.1-4.9,123.2,17.7,167.2,61.8" />
                  <path name="path1" :id="'svgGroup59_' + index + '__path1'" class="svgpath svgpath--fuschia" d="M2.7,3.2C35,0.7,67.4,5.5,97.5,17.4" />
                  <path name="path3" :id="'svgGroup59_' + index + '__path3'" class="svgpath svgpath--mist" d="M21.1,17.4c74.5,0,142.6,41.9,176.2,108.3c14,27.6,21.3,58.2,21.2,89.1" />
                  <path name="path4" :id="'svgGroup59_' + index + '__path4'" class="svgpath svgpath--green" d="M41.4,18.4c45.1,4.6,87.3,24.7,119.3,56.8" />
                </g>
              </svg>
            </div>
          </div>

          <!-- Gallery -->
          <div v-else-if="item.gallery"
               class="component-background">
            <div :style="{paddingBottom: item.gallery.spacing + 'px'}">
              <image-reveal v-if="item.gallery && item.gallery.type === 'reveal'"
                            :beforeImage=item.gallery.images[0].guid
                            :afterImage=item.gallery.images[1].guid
                            class="imageReveal--noInfo imageReveal--addMargin">
              </image-reveal>

              <ul v-else-if="item.gallery && item.gallery.type === 'grid'" class="serviceDetailPage__gallery">
                <li v-for="(value, index) in item.gallery.images"
                    class="serviceDetailPage__gallery__galleryItem"
                    :class="{'serviceDetailPage__gallery__galleryItem--two': item.gallery.images_per_row == '2', 'serviceDetailPage__gallery__galleryItem--three': item.gallery.images_per_row == '3'}">
                  <picture-sources v-if="value && value.sizes && value.sizes.length"
                                   :targetWidth="window.innerWidth <= mobile ? 100 : window.innerWidth <= tablet ? 80 : 25"
                                   :sizes="value.sizes"
                                   :imgSrc="value.guid"
                                   imgClass="objectFitImage"
                                   :imgAlt="value.alt" />
                </li>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.3 549.8" :id="'svgGroup45_' + index" class="svgGroup45">
                  <g>
                    <path name="path2" :id="'svgGroup45_' + index + '__path2'" class="svgpath svgpath--green" d="M17.8,72v473.5" />
                    <path name="path1" :id="'svgGroup45_' + index + '__path1'" class="svgpath svgpath--mist" d="M4.8,4v166.4" />
                    <path name="path3" :id="'svgGroup45_' + index + '__path3'" class="svgpath svgpath--fuschia" d="M4.3,111.5v81.3" />
                  </g>
                </svg>
              </ul>
            </div>
          </div>

          <!-- TEXT BOXES -->
          <div v-else-if="item.text_box" class="component-background">
            <div class="serviceDetailPage__textbox">
              <div :style="{paddingBottom: item.text_box.spacing + 'px'}">
                <ul class="serviceDetailPage__textbox__list">
                  <li v-for="(value, index) in item.text_box"
                      class="serviceDetailPage__textbox__list__item"
                      :key="index">
                    <h3>{{ value.title }}</h3>
                    <p>{{ value.text }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- News -->
          <div v-else-if="item.news" class="component-background">
            <div class="serviceDetailPage__news">
              <div :style="{paddingBottom: item.news.spacing + 'px;text-align:left'}">
                <h2 v-if="item.news.title" class="grey">{{ item.news.title }}</h2>
                <div id="news_list">

                  <!--<img class="news-drag-icon" src="/src/assets/drag2.svg" alt="Drag Icon">-->
                  <carousel id="case-studiesScroll" ref="case-studies_slick"
                            :items-to-show="state.slickOptions.itemsToShow"
                            :breakpoints="state.slickOptions.breakpoints"
                            :snap-align="state.slickOptions.snapAlign"
                            :wrap-around="state.slickOptions.wrapAround"
                            class="carousel carousel--case-studies"
                            aria-hidden="null">
                    <slide v-for="(item, index) in item.news" :key="index" class="carousel__slide carousel__slide--case-studies news-item" aria-hidden="null">
                      <div class="news-item__wrapper" v-if="index !== 'spacing' && index !== 'title' && item.image.sizes && item.image.sizes.length">
                        <picture-sources v-if="item.image && item.image.sizes && item.image.sizes.length"
                                         :targetWidth="window.innerWidth <= mobile ? 100 : window.innerWidth <= tablet ? 50 : 33"
                                         :sizes="item.image.sizes"
                                         :imgSrc="item.image.guid"
                                         :imgAlt="item.name"
                                         imgClass="news-item__image objectFitImage" />
                      </div>
                      <div class="text-container" v-if="item.title" @mouseover="mouseOver">
                        <div class="text-container__category">
                          <h2>{{ item.title }}</h2>
                          <div v-if="typeof(item.author) == 'object'" class="text-container__category__authorImg" v-bind:style="{backgroundImage: 'url(' + item.author.image.guid + ')'}" alt=""></div>
                        </div>

                        <p v-if="item.summary">
                          {{ item.summary }}
                        </p>
                        <span>{{item.date}}</span>
                        <span :class="['serviceDetailPage__news__read-more', item.type]" @click="goToItem(item.link, 'campaign-item')" v-if="item.itemLink === 'campaign-page'">Read More</span>
                        <span :class="['serviceDetailPage__news__read-more', item.type]" @click="goToItem(item.link, item.itemLink)" v-else>Read More</span>
                      </div>
                    </slide>
                  </carousel>
                </div>
              </div>
            </div>
          </div>

          <!-- CASE STUDIES -->
          <div v-else-if="item.case_study" class="component-background">
            <div class="serviceDetailPage__case-study case-study-list">
              <div :style="{paddingBottom: item.case_study.spacing + 'px'}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 174 967.7" :id="'svgGroup61_' + index" class="svgGroup61" style="left: 25px;">
                  <g>
                    <path name="path3" :id="'svgGroup61_' + index + '__path3'" class="svgpath svgpath--mist" d="M16.5,2.5v809.9v-2.2c0,85.6,69.4,155,155,155" />
                    <path name="path1" :id="'svgGroup61_' + index + '__path1'" class="svgpath svgpath--fuschia" d="M30.5,215.2v376.5" />
                    <path name="path2" :id="'svgGroup61_' + index + '__path2'" class="svgpath svgpath--green" d="M2.5,312.9v116.8" />
                  </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.3 367.8" :id="'svgGroup62_' + index" class="svgGroup62">
                  <g>
                    <path name="path3" :id="'svgGroup62_' + index + '__path3'" class="svgpath svgpath--mist" d="M97,227.9c-35.9,35.8-51.3,87.4-40.9,137" />
                    <path name="path1" :id="'svgGroup62_' + index + '__path1'" class="svgpath svgpath--fuschia" d="M108.8,216.1c28.3-28.2,44.1-66.5,44.1-106.4c0.1-39.9-15.8-78.2-44.1-106.4" />
                    <path name="path2" :id="'svgGroup62_' + index + '__path2'" class="svgpath svgpath--green" d="M140.8,109.8c0,76.4-62,138.4-138.4,138.4" />
                  </g>
                </svg>
                <h2 class="grey">{{ item.case_study.title }}</h2>

                <carousel id="case-studiesScroll"
                          ref="case-studies_slick"
                          :items-to-show="state.slickOptions.itemsToShow"
                          :breakpoints="state.slickOptions.breakpoints"
                          :snap-align="state.slickOptions.snapAlign"
                          :wrap-around="state.slickOptions.wrapAround"
                          class="carousel carousel--case-studies"
                          aria-hidden="null">
                  <slide v-for="work in item.case_study.case_studies" class="carousel__slide carousel__slide--case-studies" aria-hidden="null">
                    <div class="carousel__slide__imgwrapper">
                      <picture-sources v-if="work[0].banner_image && work[0].banner_image.sizes && work[0].banner_image.sizes.length"
                                       :targetWidth="window.innerWidth <= mobile ? 100 : 50"
                                       :sizes="work[0].banner_image.sizes"
                                       :imgSrc="work[0].banner_image.guid"
                                       :imgAlt="work[0].content[1].summary[0].client"
                                       imgClass="objectFitImage" />

                      <div class="carousel__slide__imgwrapper__info">
                        <router-link :to="'/work/' + work[0].link">
                          <div class="carousel__slide__imgwrapper__info__wrapper">
                            <h3 :style="{color: work[0].left_col}" v-if=work[0].content[1]>
                              {{ work[0].content[1].summary[0].client }}
                            </h3>
                            <h3 :style="{color: work[0].left_col}" v-if='work[0].content[1] == null'>
                              Client Name
                            </h3>
                            <p if="work[0].content[0]">{{ work[0].content[0].banner_image_text[0].large }}</p>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </slide>
                </carousel>
              </div>
            </div>
          </div>

          <!-- TABLE -->
          <div v-else-if="item.table" class="component-background">
            <div class="serviceDetailPage__tables">
              <div :style="{paddingBottom: item.table.spacing + 'px'}">
                <h2 v-if="item.table.title">
                  {{ item.table.title }}
                </h2>
                <p v-if="item.table.text">{{ item.table.text }}</p>
                <div class="serviceDetailPage__tables__wrapper">
                  <div v-for="(value, index) in item.table.tables"
                       class="serviceDetailPage__tables__table"
                       :class="{'serviceDetailPage__tables__table--layout1': item.table.layout == 'layout1', 'serviceDetailPage__tables__table--layout2': item.table.layout == 'layout2'}">
                    <h3>{{ value.header }}</h3>
                    <ul class="serviceDetailPage__tables__table__list">
                      <li v-for="(value2, index) in value.items"
                          class="serviceDetailPage__tables__table__list__listItem">
                        {{ value2 }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Squares -->
          <div v-else-if="item.squares" class="component-background">
            <div class="serviceDetailPage__listWrapper"
                 :style="{backgroundImage: 'url(' + item.squares.background_image.guid + ')'}">
              <div v-if="item.squares.title" class="serviceDetailPage__squares__intro">
                <h2>{{ item.squares.title }}</h2>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 174 967.7" :id="'svgGroup61_' + index" class="svgGroup61">
                <g>
                  <path name="path3" :id="'svgGroup61_' + index + '__path3'" class="svgpath svgpath--mist" d="M16.5,2.5v809.9v-2.2c0,85.6,69.4,155,155,155" />
                  <path name="path1" :id="'svgGroup61_' + index + '__path1'" class="svgpath svgpath--fuschia" d="M30.5,215.2v376.5" />
                  <path name="path2" :id="'svgGroup61_' + index + '__path2'" class="svgpath svgpath--green" d="M2.5,312.9v116.8" />
                </g>
              </svg>
              <div :style="{paddingBottom: item.squares.spacing + 'px'}">

                <ul :class="{ 'serviceDetailPage__list--row' : item.squares.grid }"
                    class="serviceDetailPage__list">
                  <li class="serviceDetailPage__list__listItem"
                      v-for="value in item.squares.rows"
                      :style="[value.background_colour.length > 1 ? {backgroundColor: value.background_colour} : {backgroundColor:'#ffffff'}]">
                    <h3 :style="[value.title_colour.length > 1 ? {color: value.title_colour} : {color:'#868686'}]">{{ value.title }}</h3>
                    <div class="serviceDetailPage__list__listItem__text" v-html=value.content></div>
                    <picture-sources v-if="value.icon && value.icon.sizes && value.icon.sizes.length"
                                     :targetWidth="20"
                                     :sizes="value.icon.sizes"
                                     :imgSrc="value.icon.guid"
                                     :imgAlt="value.icon.alt" />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- VIDEO -->
          <div v-else-if="item.video" class="component-background">
            <div :style="{paddingBottom: item.video.spacing + 'px'}">
              <div class="workDetailPage__video" itemscope itemtype="https://schema.org/VideoObject">
                <div v-if="item.video.code" style="position:relative; overflow:hidden;" :class=item.video.colour>
                  <videoPlaylistComponent :code="item.video.code" />
                </div>
              </div>
            </div>
          </div>

          <!-- QUOTE WALL -->
          <div v-else-if="item.social_carousel" class="component-background">
            <div class="serviceDetailPage__socialCarousel serviceDetailPage__quoteWall">
              <div :style="{paddingBottom: item.social_carousel.spacing + 'px'}">
                <div class="carousel carousel--quotes">
                  <h2>{{ item.social_carousel.title }}</h2>
                  <div class="serviceDetailPage__quoteWall__text"><p v-html=item.social_carousel.text></p></div>
                  <div class="serviceDetailPage__quoteWall__sliderWrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 493.2 703.6" :id="'svgGroup48_' + index" class="svgGroup48">
                      <g>
                        <path :id="'svgGroup48_' + index + '__path4'" class="svgpath svgpath--mist" d="M316,231.2c-85.6,0-155,69.4-155,155c0,0,0,0,0,0v160.6" />
                        <path :id="'svgGroup48_' + index + '__path1'" class="svgpath svgpath--mist" d="M488.2,6.1v70.1c0,85.6-69.4,155-155,155" />
                        <path :id="'svgGroup48_' + index + '__path2'" class="svgpath svgpath--fuschia" d="M316,231.2c-85.6,0-155,69.4-155,155c0,0,0,0,0,0" />
                        <path :id="'svgGroup48_' + index + '__path3'" class="svgpath svgpath--green" d="M215.2,285.4c55.7-55.7,146-55.7,201.7,0" />
                        <path :id="'svgGroup48_' + index + '__path5'" class="svgpath svgpath--fuschia" d="M161.2,542.9c0.1,34-11.1,67.1-31.8,94.1c-29.3,38.4-74.9,61-123.2,60.9" />
                      </g>
                    </svg>

                    <carousel id="quoteWallScroll"
                              ref="slick"
                              :items-to-show="state.slickOptions2.itemsToShow"
                              :breakpoints="state.slickOptions2.breakpoints"
                              :snap-align="state.slickOptions2.snapAlign"
                              :wrap-around="state.slickOptions2.wrapAround"
                              class="carousel carousel--case-studies" aria-hidden="null">
                      <slide v-for="(value, index) in item.social_carousel.items"
                             class="carousel__slide carousel--quotes__slide"
                             aria-hidden="null">
                        <div class="carousel--quotes__slide__wrapper">
                          <div class="carousel--quotes__slide__info">
                            <h3 class="carousel--quotes__slide__info__heading">{{ value.title }}</h3>
                            <p>{{ value.text }}</p>
                          </div>
                        </div>
                      </slide>
                    </carousel>
                  </div>
                  <!--<img src="/src/assets/drag-arrows-grey.svg" alt="Drag Slider Icon" class="serviceDetailPage__quoteWall__dragIcon">-->
                </div>
              </div>
            </div>
          </div>

          <!-- TESTIMONIAL -->
          <testimonial id="test" v-else-if="item.testimonials" :testimonials="item.testimonials"></testimonial>

          <!-- PEOPLE -->
          <div v-else-if="item.people" class="people component-background">
            <div class="serviceDetailPage__people people-list">
              <div :style="{paddingBottom: item.people.spacing + 'px'}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.7 374.2" id="svgGroup20">
                  <g>
                    <path id="svgGroup20__path3" class="svgpath svgpath--mist" d="M60.3,231.5c35.9,35.8,51.3,87.4,40.9,137" />
                    <path id="svgGroup20__path1" class="svgpath svgpath--fuschia" d="M48.5,7c-58.7,58.7-58.7,154,0,212.7" />
                    <path id="svgGroup20__path2" class="svgpath svgpath--green" d="M16.5,113.3c0,76.4,62,138.4,138.4,138.4" />
                  </g>
                </svg>
                <h2>{{ item.people.title }}</h2>
                <!--<img class="people-drag-icon" src="/src/assets/drag2.svg" alt="Drag Icon">-->
                <carousel id="peopleScroll" ref="people_slick"
                          :items-to-show="state.slickOptions3.itemsToShow"
                          :breakpoints="state.slickOptions3.breakpoints"
                          :snap-align="state.slickOptions3.snapAlign"
                          :wrap-around="state.slickOptions3.wrapAround"
                          class="carousel carousel--people" aria-hidden="null">
                  <slide v-for="(person, index) in item.people" class="carousel__slide carousel__slide--people">
                    <div class="carousel__slide__info">
                      <div class="carousel__slide__info__wrapper">
                        <h3>{{ person.name }}</h3>
                        <span>{{ person.job_title }}</span>
                      </div>
                    </div>
                    <div class="carousel__slide__imgwrapper">
                      <picture-sources v-if="person.image && person.image.sizes && person.image.sizes.length"
                                       :targetWidth="window.innerWidth <= mobile ? 100 :50"
                                       :sizes="person.image.sizes"
                                       :imgSrc="person.image.guid"
                                       :imgAlt="person.name"
                                       imgClass="people-list__container__person__image objectFitImage"
                                       :mobile="mobile" />
                      <router-link v-if="person.key" :to="'/people/' + person.link" class="button button--white">more</router-link>
                    </div>
                  </slide>
                </carousel>

              </div>
            </div>
          </div>

          <!-- TEXT & IMAGE -->
          <div v-else-if="item.text_image"
               class="serviceDetailPage__text_image component-background">
            <div :style="{paddingBottom: item.text_image.spacing + 'px'}">
              <div class="serviceDetailPage__text_image__col1">
                <h2 class="serviceDetailPage__text_image__title">
                  {{ item.text_image.title }}
                </h2>
                <picture-sources v-if="item.text_image.image && item.text_image.image.sizes && item.text_image.image.sizes.length"
                                 :targetWidth="100"
                                 :sizes="item.text_image.image.sizes"
                                 :imgSrc="item.text_image.image.guid"
                                 :imgAlt="(item.text_image.image.alt) ? item.text_image.image.alt : item.text_image.image.title" />

                <p class="serviceDetailPage__text_image__text" v-html="item.text_image.text"></p>
              </div>
            </div>
          </div>

          <!-- CONTACT FORM -->
          <div v-if="state.serviceDetailForm > 0" class="serviceDetailPage__contactForm component-background" id="contactForm" ref="anchor">

            <svg v-if="!sentModal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 281.7 320.9" id="svgGroup15">
              <g>
                <path id="svgGroup15__path1" class="svgpath svgpath--mist" d="M277.4,4.4v53.4c0,0,0,0,0,0c0,71.4-57.9,129.3-129.3,129.3" />
                <path id="svgGroup15__path2" class="svgpath svgpath--fuschia" d="M133.8,187C62.5,187,4.6,244.9,4.6,316.2l0,0" />
                <path id="svgGroup15__path3" class="svgpath svgpath--green" d="M156.6,199.5c23.3,4.5,44.6,15.9,61.4,32.7" />
              </g>
            </svg>

            <div v-if="!sentModal" class="serviceDetailPage__contactForm-content">
              <div id="hubspotForm" v-once></div>
            </div>

            <div v-if="sentModal" class="serviceDetailPage__contactForm-content">
              <h2>
                Thank you
              </h2>
              <p id="serviceDetailPage-contact-sent">
                Your enquiry has been submitted. We will contact you shortly.
              </p>
            </div>

            <div v-if="notSentModal" class="serviceDetailPage__contactForm-content">
              <h2>
                Error
              </h2>
              <p id="serviceDetailPage-contact-not-sent">
                There was a problem sending your Enquiry. Please try again later.
              </p>
            </div>
          </div>
        </template>
      </div>
    </main>
  </div>
</template>
<script>
  import { Carousel, Slide } from 'vue3-carousel';
  import 'vue3-carousel/dist/carousel.css';
  import EventBus from '../eventbus.js';
  import HeaderComponent from './widgets/header-component';
  import VideoComponent from './widgets/video-component';
  import Slider from './widgets/slider';
  import { _toggleSpinner, api } from '../utils/api-gateway';
  import PictureSources from './widgets/picture-sources';
  import ModalComponent from './widgets/modal-component';
  import ImageReveal from './widgets/image-reveal';
  import Circles from './widgets/circles';
  import InputBox from './widgets/form-elements/input-box';
  import VueScrollTo from "vue-scrollto";
  import LoadingSpinner from './widgets/loading-spinner';
  import { TweenMax, Elastic, Bounce } from 'gsap';
  import SlideEvent from 'slide-event';
  import HeaderBar from './widgets/header-bar';
  import { useRoute, useRouter } from "vue-router";
  import { computed, nextTick, onUpdated, reactive, ref } from "vue";
  import MyPluginCPS from '../plugins/MyPluginCPS.js';
  import { useInjects } from '../utils/globals-inject';
  import VideoStoryBoxes from "./widgets/video-story-boxes.vue";
  import Testimonial from "./widgets/testimonial";
  import VideoPlaylistComponent from "./widgets/video-playlist-component.vue";
  export default {
    name: 'ServiceDetailPage',
    components: {
      VideoPlaylistComponent,
      VideoStoryBoxes,
      HeaderBar,
      HeaderComponent,
      ModalComponent,
      VideoComponent,
      Slider,
      Carousel,
      Slide,
      Bounce,
      PictureSources,
      Circles,
      InputBox,
      LoadingSpinner,
      ImageReveal,
      SlideEvent,
      Testimonial
    },
    setup(props) {
      let { window, desktop, mobile, tablet, mouseX, mouseY,  backend } = useInjects();
      const router = useRouter();
      const { svgAnimate, goToItem } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);

      const state = reactive({
        jsonData: [],
        contactUrl: "",
        serviceVideo: null,
        serviceExtraClass: null,
        serviceData: [],
        serviceContent: [],
        serviceDetail: null,
        serviceDetailContent: [],
        id: null,
        slug: null,
        url: null,
        bannerImage: null,
        bannerVideoURL: null,
        bannerVideoCode: null,
        overlay: null,
        strPopupVideo: null,
        blPopupVideo: false,
        largeTitle: null,
        smallTitle: null,
        blAdditionalContent: false,
        bannerAdditionalContent: null,
        serviceDetailForm: [],
        intro: null,
        introAdditionalClass: '',
        preview_num: '',
        preview_title: '',
        sentModal: false,
        first_name: '',
        last_name: '',
        company: '',
        email: '',
        type: '',
        enquiry: '',
        phone: '',
        job_title: '',
        ajaxRequest: false,
        notSentModal: false,
        loggedModal: false,
        personBio: null,
        bioOrderNumber: null,
        firstName: '',
        lastName: '',
        selected: false,
        name: null,
        isActive: true,
        legal: null,

        slickOptions: { /* Case Studies */
          itemsToShow: 1,
          snapAlign: 'start',
          breakpoints: {
            0: {
              itemsToShow: 1,
              snapAlign: 'center'
            },
            600: {
              itemsToShow: 2,
            },
            982: {
              itemsToShow: 2
            }
          }
        },
        slickOptions2: /** Quote Wall **/ {
          itemsToShow: 4,
          snapAlign: 'start',
          breakpoints: {
            0: {
              itemsToShow: 2
            },
            981: {
              itemsToShow: 2,
            },
            1200: {
              itemsToShow: 3
            },
            1201: {
              itemsToShow: 4
            }
          }
        },
        slickOptions3: { /* PEOPLE & NEWS */
          // Options can be used from the plugin documentation
          itemsToShow: 2,
          wrapAround: false,
          snapAlign: 'start',
          breakpoints: {
            0: {
              itemsToShow: 1,
              snapAlign: 'center'
            },
            600: {
              itemsToShow: 2,
              snapAlign: 'center'
            },
            982: {
              itemsToShow: 2
            }
          }
        },
        active: null,
      });

      const serviceDetailContentCircles = computed(() => {
        return serviceDetailContent.filter((c) => c.circles);
      });

      const isLoaded = computed(() => {
        if (state.jsonData.hasOwnProperty('content')) {
          return true;
        }
        return false;
      });

      const oldMounted = () => {
        let objectFitImages = require('object-fit-images');

        // Object-fit fix for IE
        objectFitImages('img.objectFitImage');

        //CU : This link makes no sense
        //getJsonData(this.link);

        state.serviceData = getJsonData(state.slug);
        state.serviceContent = state.serviceData.detail_page;

        let video_pos = -1;
        for (var prop in state.serviceContent.content) {
          if (typeof state.serviceContent.content[prop].video !== "undefined") {
            video_pos = prop;
          }
        }

        if (video_pos != -1) {
          console.log(state.serviceContent.content[video_pos].video.code);
          state.serviceVideo = jwplayer("serviceVideo");
          console.log(typeof state.serviceVideo.setup);
          if (typeof state.serviceVideo.setup === 'function') {
            state.serviceVideo.setup({
              //file: this.jsonData.content.showreel,
              id: "serviceVid",
              width: "100%",
              aspectratio: "16:9",
              file: "https://cdn.jwplayer.com/manifests/" + state.serviceContent.content[video_pos].video.code + ".m3u8",
              image: "https://content.jwplatform.com/thumbs/" + state.serviceContent.content[video_pos].video.code + ".jpg",
              customProps: { skin: { url: './css/player.css', name: 'svp' }, controls: true }
            });
          }
        }

        if (state.serviceData.detail_page) {
          state.serviceDetail = state.serviceData.detail_page;
        }

        // Service additional class
        state.serviceExtraClass = (state.serviceData.additional_class !== undefined) ? state.serviceData.additional_class : "";

        // Legal blurb for form
        state.legal = state.jsonData.site_details.form;

        state.bannerImage = (state.serviceDetail.banner_image !== false) ? state.serviceDetail.banner_image : state.serviceData.image;

        if (state.serviceDetail.banner_video && state.serviceDetail.banner_video.url !== false)
          state.bannerVideoURL = state.serviceDetail.banner_video.url;

        if (state.serviceDetail.banner_video && state.serviceDetail.banner_video.code !== false)
          state.bannerVideoCode = state.serviceDetail.banner_video.code;

        if (state.serviceDetail.banner_video && state.serviceDetail.banner_video.popup_media_id !== undefined){
          state.blPopupVideo = true;
          state.strPopupVideo = state.serviceDetail.banner_video.popup_media_id;
        }

        state.overlay = (state.serviceDetail.overlay != false) ? true : false;
        // Service title
        state.largeTitle = state.serviceData.service;

        // Service caption
        state.smallTitle = state.serviceData.detail_page.caption;

        // Service additional content
        if(state.serviceData.detail_page.banner_additional_content !== undefined && state.serviceData.detail_page.banner_additional_content !== ""){
          state.blAdditionalContent = true;
          state.bannerAdditionalContent = state.serviceData.detail_page.banner_additional_content;
        }
        // Service introduction
        state.intro = state.serviceData.detail_page.introduction;
        state.introAdditionalClass = (state.serviceData.introduction_class !== "") ? state.serviceData.introduction_class : "";

        // Service detail content array
        state.serviceDetailContent = state.serviceData.detail_page.content;
        // Service Contact Form
        state.serviceDetailForm = state.serviceData.detail_page.form.required;
        state.serviceDetailFormID = state.serviceData.detail_page.form.id;

        //Gradient colours
        state.ServiceColor1 = state.serviceData.right_col;
        state.ServiceColor2 = state.serviceData.left_col;
        state.ServiceGradient = 'linear-gradient(-143deg, ' + state.ServiceColor1 + ' 4%,' + state.ServiceColor2 + ' 90%)';

        nextTick(() => {
          slickStuff();
          if (route.hash)
            scrollToAnchor(route.hash.slice(1));
        })

        if (state.serviceDetailForm.required === 1) {
          const script = document.createElement("script");
          script.src = "//js.hsforms.net/forms/v2.js";
          document.body.appendChild(script);
          script.addEventListener("load", () => {
            if (window.hbspt) {
              window.hbspt.forms.create({
                region: "na1",
                portalId: "1968984",
                formId: state.serviceDetailFormID,
                target: "#hubspotForm",
                cssClass: 'cps_form',
                width: '100%'
              })
            }
          });
        }

      };

      const getJsonData = (slug) => {
        let serviceData;

        if (slug === undefined || slug === null) {
          serviceData = state.jsonData.content.services;
        } else {
          for (let i = 0; i < state.jsonData.content.services.length; i++) {
            if (slug == state.jsonData.content.services[i].link) {
              serviceData = state.jsonData.content.services[i];
            }
          }
        }
        return serviceData;
      };

      const mouseOver = (index) => {
        if (window.innerWidth >= state.desktop) {
          state.active = index
        }
      };

      const mouseOut = (index) => {
        if (window.innerWidth >= state.desktop) {
          state.active = null
        }
      };

      const initAnimate = () => {
        if (window.innerWidth >= state.desktop && !isIE) {

          let svgPathsWork = [];

          if (document.getElementsByClassName(".svgGroup45")) {
            document.querySelectorAll(".svgGroup45").forEach((ele) => {

              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path2',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path3',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path4',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                }
              )
            });
          }

          if (document.getElementsByClassName("svgGroup56")) {
            document.querySelectorAll(".svgGroup56").forEach((ele) => {

              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-300'
                },
                {
                  id: index + '__path2',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-300'
                },
                {
                  id: index + '__path3',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-300'
                },
                {
                  id: index + '__path4',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-300'
                }
              )
            });
          }

          if (document.getElementsByClassName("svgGroup57")) {
            document.querySelectorAll(".svgGroup57").forEach((ele) => {

              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-300'
                },
                {
                  id: index + '__path2',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-300'
                },
                {
                  id: index + '__path3',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-300'
                }
              )
            });
          }

          if (document.getElementsByClassName("svgGroup9c")) {
            document.querySelectorAll(".svgGroup9c").forEach((ele) => {

              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path2',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path3',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                }
              )
            });
          }

          if (document.getElementsByClassName("svgGroup58")) {
            document.querySelectorAll(".svgGroup58").forEach((ele) => {

              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path2',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path3',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                }
              )
            });
          }

          if (document.getElementsByClassName("svgGroup59")) {
            document.querySelectorAll(".svgGroup59").forEach((ele) => {

              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-100'
                },
                {
                  id: index + '__path2',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-200'
                },
                {
                  id: index + '__path3',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-200'
                },
                {
                  id: index + '__path4',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-200'
                }
              )
            });
          }

          if (document.getElementsByClassName("svgGroup60")) {
            document.querySelectorAll(".svgGroup60").forEach((ele) => {

              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path2',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path3',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path4',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                }
              )
            });
          }

          if (document.getElementsByClassName("svgGroup61")) {
            document.querySelectorAll(".svgGroup61").forEach((ele) => {

              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path2',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path3',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                }
              )
            });
          }

          if (document.getElementsByClassName("svgGroup62")) {
            document.querySelectorAll(".svgGroup62").forEach((ele) => {

              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-200'
                },
                {
                  id: index + '__path2',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-200'
                },
                {
                  id: index + '__path3',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-200'
                }
              )
            });
          }

          let svgTweensWork = {};

          // Loop through SVG PATHS array / build tweens and scenes
          svgAnimate(svgPathsWork, svgTweensWork);
        }
      };

      const submitForm = () => {
        let data = new FormData(document.getElementById('sd_form'));
        _toggleSpinner(true);
        document.getElementById('loading-spinner').classList.add('show');
        state.ajaxRequest = true;
        this.$http.post(backend + 'services/enquiry/', data, { headers: { "content-type": "text/plain; charset=UTF-8" } })
          .then(response => {
            //document.getElementById('loading-spinner').classList.remove('show');
            state.postResults = response.data;
            state.ajaxRequest = false;
            _toggleSpinner(false);
            if (state.postResults.status === 200) {
              state.loggedModal = true;
              state.sentModal = true;
              VueScrollTo.scrollTo(document.getElementById("serviceDetailPage__contactForm"));
            } else if (state.postResults.status !== 200) {
              state.notSentModal = true;
            } else {
              state.sentModal = true;
              VueScrollTo.scrollTo(document.getElementById("serviceDetailPage__contactForm"));
            }
          }).catch((err) => {
            state.notSentModal = true;
            _toggleSpinner(false);
          })
      };

      const toggleClass = (e) => {
        state.isActive = !state.isActive;
      };

      const renderSvgAnimationsAfterBioPopup = () => {
        let _this = this;
        setTimeout(function () {
          let svgPathsPeople = [
            {
              id: 'svgGroup18__path1',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__container__images",
              offset: '0'
            },
            {
              id: 'svgGroup18__path2',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__container__images",
              offset: '0'
            },
            {
              id: 'svgGroup18__path3',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__container__images",
              offset: '0'
            },
          ]

          let svgTweensPeople = {};

          // Loop through SVG PATHS array / build tweens and scenes
          svgAnimate(svgPathsPeople, svgTweensPeople);
        })

      };

      const activeHover = (index) => {
      };

      const next = () => {
        //this.$refs.slick.next();
      };

      const prev = () => {
        //this.$refs.slick.prev();
      };

      const reInit = () => {
        // Helpful if you have to deal with v-for to update dynamic lists
        //this.$refs.slick.reSlick();
      };

      const slickStuff = () => {

        function getTransformValues(transform) {
          let results = transform.match(/translate3d\(\s*([^ ,]+)\s*,\s*([^ ,]+)\s*,\s*([^ )]+)\s*\)/)

          if (!results) return [0, 0, 0];

          return [
            parseInt(results[1].replace('px', ''), 10),
            parseInt(results[2].replace('px', ''), 10),
            parseInt(results[3].replace('px', ''), 10),
          ];
        }
        let matches = document.querySelectorAll('.slick-list');

        if (matches.length) {
          matches.forEach(function (slickList) {

            slickList.onwheel = function (e) {

              if ((e.deltaY == 0 || e.deltaY == -0) && e.deltaX != 0 && e.deltaX != -0) {

                let slickTrack = slickList.querySelector('.slick-track');

                let transform = slickTrack.style.transform;

                let transform3d = getTransformValues(transform);

                let newX = transform3d[0] - e.deltaX;

                let limit = (slickTrack.offsetWidth - slickList.offsetWidth) * -1;

                if (newX < 0 && newX > limit) {
                  slickTrack.style.transform = 'translate3d(' + newX + 'px,' + transform3d[1] + 'px,' + transform3d[2] + 'px)';
                }

              }

            };

          });
        }
      };

      const scrollToAnchor = () => {
        const anchor = ref(null);
        //anchor.scrollIntoView({ behavior: 'smooth' });
      };

      let url;
      const route = useRoute();

      if (route.params.num) {
        state.preview_num = route.params.num;
        state.preview_title = route.params.title;
        url = backend + 'preview/services/' + state.preview_num + '/' + state.preview_title;
      } else {
        state.id = route.params.id;
        state.slug = route.params.link;
        url = backend + 'page/services/';
      }

      state.contactUrl = "/services/" + state.slug + "/#contactForm";

      api(url)
        .then((data) => {
          state.jsonData = data.data;
          nextTick().then(() => {
            oldMounted();
            EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
            EventBus.$emit('isLoaded', true);
            EventBus.$emit('footer', state.jsonData.site_details.tceg);
            EventBus.$emit('statement', state.jsonData.site_details.footer_statement);
            state.url = window.location.href;
          });
        }).catch(error => {
          state.loading = false;
        });

      onUpdated(() => {
        initAnimate();
      });

      return {
        state,
        window,
        tablet,
        mobile,
        svgAnimate,
        goToItem,
        serviceDetailContentCircles,
        isLoaded,
        mouseOver,
        mouseOut,
        submitForm,
        toggleClass,
        renderSvgAnimationsAfterBioPopup,
        activeHover,
        next,
        prev,
        reInit,
        initAnimate,
        slickStuff,
        getJsonData,
      };
    },
  }</script>
