<template>
  <div :data-id="code" class="videoMedia"></div>
</template>

<script>
import {onMounted} from "vue";

export default {
  name: 'VideoPlaylistComponent',
  props: {
    code: String
  },

  setup(props) {

    onMounted(() => {
      let vid = document.querySelector(`[data-id="${props.code}"]`);
      setupPlayer(vid);
    })

    const setupPlayer = (code) => {
      let player = window.jwplayer(code).setup({
        "playlist": [{
          "file": "https://cdn.jwplayer.com/manifests/" + props.code + ".m3u8",
          "image": "https://cdn.jwplayer.com/v2/media/" + props.code + "/poster.jpg"
        }]
      })
    };

    return {
      setupPlayer
    }
  }

}
</script>

<style scoped lang="scss">

</style>
