<template>
  <div class="circles">
    <ul class="circles__list"
        :class="{'circles__list--coloured': $props.layout === 'coloured', 'circles__list--white': $props.layout === 'white'}">
      <li class="circles__list__item"
          v-for="(item, index) in $props.circlesListItems">
        <svg v-if="$props.layout === 'white' && index === 0 && $props.animation === '1'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 259.3 315.9" :id="'svgGroup60_' + itemIndex" class="svgGroup60">
          <g>
            <path name="path4" :id="'svgGroup60_' + $props.itemIndex + '__path4'" id="svgGroup60__path4" class="svgpath svgpath--mist" d="M71.5,35.4C8.4,88.9-14.1,176.3,15.2,253.7" />
            <path name="path2" :id="'svgGroup60_' + $props.itemIndex + '__path2'" class="svgpath svgpath--fuschia" d="M71.5,35.4c-22.6,19-40.6,42.9-52.6,69.9" />
            <path name="path3" :id="'svgGroup60_' + $props.itemIndex + '__path3'" class="svgpath svgpath--mist" d="M256.1,12.9C190.5-10.3,117.4,6.4,68.3,55.8c-70.6,71-70.6,185.6,0,256.5" />
            <path name="path1" :id="'svgGroup60_' + $props.itemIndex + '__path1'" class="svgpath svgpath--green" d="M256.1,12.8C192.5-9.4,118.9,4.7,68.1,55.3" />
          </g>
        </svg>
        <div class="circles__list__item__content"
             :style="{ backgroundColor : ($props.layout === 'coloured' && $props.colours) ? $props.colours[index % $props.colours.length] + ' !important' : '#FFFFFF'}">
          <span class="highlighted">{{ item.highlighted_text }}</span>
          <span class="normal">{{ item.normal_text }}</span>
        </div>
        <div
             v-for="index in three"
             :key="index"
             :id="'circle--'+index"
             class="circles__list__item__backgroundCircle"
             :class="'circles__list__item__backgroundCircle--' + index"></div>
      </li>
    </ul>
  </div>
</template>
<script>
import {computed} from "vue";
  export default {
    name: 'circles',
    props: {
      item: Object,
      circlesListItems: Object,
      layout: String,
      itemIndex: Number | String,
      animation: Object,
      gradient: String,
      colours: Array
    },
    setup(props){
      const three = computed(() => {
        if (props.layout === 'coloured') return 3;
        return 0;
      });
      return {
        three
      }
    },
  }</script>
