
export function api(url) {

  _toggleSpinner(true);

  return fetch(url).then(response => {

    setTimeout(() => {
      _toggleSpinner(false);
    },1000);

    return response.json();
  });

}

export function _toggleSpinner(show) {

  var spinner = document.getElementById('loading-spinner');
  if (spinner) {
    if (show) {
      spinner.style.display = 'flex';
      document.getElementById('loading-spinner').classList.add('show');
    } else {
      document.getElementById('loading-spinner').classList.remove('show');
      setTimeout(function(){
        spinner.style.display = 'none';
      }, 1000);
    }

  }

}

export function toggleSpinner(show) {

  _toggleSpinner(show);

}
