import { inject } from 'vue';

export const useInjects = () => {
  let mouseX = inject('mouseX');
  let mouseY = inject('mouseY');
  let desktop = inject('desktop');
  let window = inject('window');
  let tablet = inject('tablet');
  let mobile = inject('mobile');
  let isIE = inject('isIE');
  let backend = inject('backend');
  let hubSpot = inject('hubSpot');
  let slug = inject('slug');

  return {
    mouseX,
    mouseY,
    desktop,
    window,
    tablet,
    mobile,
    isIE,
    backend,
    hubSpot,
    slug
  };
};

//Thoughts
 
