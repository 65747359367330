<template>
  <div class="clientLogo" @mouseover="state.hover = true" @mouseleave="state.hover = false" :class="{ active: state.hover }">
    <div class="clientLogo__content">
      <picture-sources v-if="$props.item.backgroundImage && $props.item.backgroundImage.sizes && $props.item.backgroundImage.sizes.length"
                       :targetWidth="window.innerWidth <= $props.mobile ? 50 : window.innerWidth <= $props.tablet ? 30 : 20"
                       :sizes="$props.item.backgroundImage.sizes"
                       :imgSrc="$props.item.backgroundImage.guid"
                       :imgAlt="$props.item.client"
                       imgClass="clientLogo__content__logosBackground objectFitImage" />
      <picture-sources v-if="$props.item.logoImage && $props.item.logoImage.sizes && $props.item.logoImage.sizes.length"
                       :targetWidth="window.innerWidth <= $props.mobile ? 50 : window.innerWidth <= tablet ? 30 : 20"
                       :sizes="$props.item.logoImage.sizes"
                       :imgSrc="$props.item.logoImage.guid"
                       :imgAlt="$props.item.client"
                       imgClass="clientLogo__content__logosImage" />
      <picture-sources v-if="$props.item.logoImage2 && $props.item.logoImage2.sizes && $props.item.logoImage2.sizes.length"
                       :targetWidth="window.innerWidth <= $props.mobile ? 50 : window.innerWidth <= $props.tablet ? 30 : 20"
                       :sizes="$props.item.logoImage2.sizes"
                       :imgSrc="$props.item.logoImage2.guid"
                       :imgAlt="$props.item.client"
                       imgClass="clientLogo__content__logosImage2" />
    </div>
  </div>
</template>
<script>
  import PictureSources from './picture-sources';
  import { reactive } from "vue";
  import { useInjects } from '../../utils/globals-inject';
  export default {
    name: 'client-logo',
    components: {
      PictureSources
    },
    props: {
      item: Object,
      window: Object,
      tablet: Number,
      mobile: Number,

    },
    setup(props) {
      let { window, mobile, tablet } = useInjects();

      const state = reactive({
        hover: false
      });

      const logoImageSrc = () => {
        return state.hover ? props.item.logoImage2 : props.item.logoImage;
      };

      return {
        state,
        logoImageSrc,
        window,
        mobile,
        tablet
      }
    }
  }</script>
