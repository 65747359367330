<template>
  <div id="job-detail" v-if="isLoaded">
    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />
    <header-component type='jobsDetail' class='header header--jobsDetail'>
    </header-component>
    <div id="job-detail-title" v-bind:style="{backgroundColor: (state.agencyColours[state.job.job_company.short_name]) ? state.agencyColours[state.job.job_company.short_name] : '#555' }">

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 326.6 457.6" id="svgGroup11">
        <g>
          <path id="svgGroup11__path3" class="svgpath svgpath--mist" d="M171.6,156.6c83.1,0,150.4,67.3,150.4,150.4v145.1" />
          <path id="svgGroup11__path1" class="svgpath svgpath--fuschia" d="M4.5,6.2C4.4,46.1,20.3,84.4,48.6,112.6c28.2,28.3,66.5,44.1,106.4,44.1" />
          <path id="svgGroup11__path2" class="svgpath svgpath--green" d="M57.1,104.1c54,54,141.6,54,195.7,0" />
        </g>
      </svg>

      <span>
        {{ state.job.job_company.short_name }} - {{ state.job.location.name.split(',')[0] }}
      </span>

      <h1>{{ state.job.title }}</h1>

      <!--<a target="_parent" href="#apply" v-bind:style="{color: agencyColours[job.job_company.short_name] }">Apply</a>-->

    </div>

    <section id="job-detail-job-description">

      <svg v-if="state.job.job_content" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 304.7 371.5" id="svgGroup12">
        <g>
          <path id="svgGroup12__path3" class="svgpath svgpath--mist" d="M85.1,43.6c-73.2,62-99.3,163.4-65.3,253" />
          <path id="svgGroup12__path2" class="svgpath svgpath--fuschia" d="M85.1,43.6c-26.2,22-47.1,49.7-61,81" />
          <path id="svgGroup12__path1" class="svgpath svgpath--mist" d="M299.1,17.6C223-9.4,138.2,9.9,81.4,67.2c-57.7,58-74.7,141.4-50.9,214.3
    c10.2,31.3,27.6,59.8,50.9,83.1" />
        </g>
      </svg>

    </section>

    <!--<section id="job-detail-role-person" v-bind:style="{backgroundColor: agencyColours[job.job_company.short_name] }">

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 266.1 326.6" id="svgGroup13">
        <g>
            <path id="svgGroup13__path1" class="svgpath svgpath--mist" d="M261.1,6v160.6c0,0,0,0,0,0c0,85.6-69.4,155-155,155"/>
            <path id="svgGroup13__path2" class="svgpath svgpath--fuschia" d="M261.1,166.6C261.1,166.6,261.1,166.6,261.1,166.6c0,85.6-69.4,155-155,155"/>
            <path id="svgGroup13__path3" class="svgpath svgpath--green" d="M206.9,267.5c-55.7,55.7-146,55.7-201.7,0"/>
        </g>
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 430.52 408.158" id="svgGroup9b">
        <g>
            <path id="svgGroup9__path3" class="svgpath svgpath--mist" d="M204.1,204.1C92.7,204.1,2.5,294.3,2.5,405.7"/>
            <path id="svgGroup9__path2" class="svgpath svgpath--fuschia" d="M428,2.5c0.1,53.5-21.2,104.8-59,142.5c-37.8,37.9-89.1,59.1-142.5,59"/>
            <path id="svgGroup9__path1" class="svgpath svgpath--green" d="M95.3,133.6c72.4,72.4,189.8,72.4,262.3,0"/>
        </g>
      </svg>

      <h2>The Role</h2>
      <div v-html="job.job_content"></div>

    </section>-->

    <section id="job-detail-apply">

      <svg v-if="!sentModal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 281.7 320.9" id="svgGroup15">
        <g>
          <path id="svgGroup15__path1" class="svgpath svgpath--mist" d="M277.4,4.4v53.4c0,0,0,0,0,0c0,71.4-57.9,129.3-129.3,129.3" />
          <path id="svgGroup15__path2" class="svgpath svgpath--fuschia" d="M133.8,187C62.5,187,4.6,244.9,4.6,316.2l0,0" />
          <path id="svgGroup15__path3" class="svgpath svgpath--green" d="M156.6,199.5c23.3,4.5,44.6,15.9,61.4,32.7" />
        </g>
      </svg>

      <div id="job-detail-apply-intro">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 328 455.6" id="svgGroup14">
          <g>
            <path id="svgGroup14__path3" class="svgpath svgpath--mist" d="M156,155.1c-83.1,0-150.4,67.3-150.4,150.4v145.1" />
            <path id="svgGroup14__path1" class="svgpath svgpath--fuschia" d="M323,4.7c0.1,39.9-15.8,78.2-44.1,106.4c-28.2,28.3-66.5,44.1-106.4,44.1" />
            <path id="svgGroup14__path2" class="svgpath svgpath--green" d="M270.5,102.5c-54,54-141.6,54-195.7,0" />
          </g>
        </svg>
      </div>
      <div id="grnhse_app"></div>
    </section>
  </div>
</template>
<script>
  import EventBus from '../eventbus.js';
  //import checkView from 'vue-check-view';
  import InputBox from './form-elements/input-box';
  import HeaderComponent from './widgets/header-component';
  //import VueResource from 'vue-resource';
  import VueScrollTo from 'vue-scrollto';
  //import vueFilterPrettyBytes from 'vue-filter-pretty-bytes';
  import { _toggleSpinner, api } from '../utils/api-gateway';
  import Captcha from './widgets/captcha';
  import { decodeHTML } from '../utils/decode-html';
  import HeaderBar from './widgets/header-bar';
  import {createApp, reactive, computed, nextTick} from 'vue';
  //import App from '../App';
  import { useRoute, useRouter } from "vue-router";
  import { useInjects } from '../utils/globals-inject';
  import MyPluginCPS from '../plugins/MyPluginCPS.js';

  //const app = createApp(App);
  //app.use(checkView);
  //app.use(VueResource);
  //app.use(VueScrollTo);
  //app.use(vueFilterPrettyBytes);

  //Commented out as causing a config.productionTip has been removed error
  //app.config.productionTip = false;

  export default {
    name: 'JobDetailPage',
    components: {
      HeaderBar,
      HeaderComponent,
      InputBox,
      Captcha
    },
    setup() {
      const router = useRouter();
      let { window, desktop, mouseX, mouseY, isIE, backend } = useInjects();
      const { svgAnimate } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);

      const state = reactive({
        job: {},
        agency: {},
        agencyColours: {},
        jsonData: [],
        filterLocation: null,
        filterAgency: null,
        inputCount: 1,
        locations: [],
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        salary: '',
        linkedin: '',
        url: '',
        documents: [],
        comments: '',
        places: [],
        files: [],
        uploadError: null,
        currentStatus: null,
        postResults: [],
        ajaxRequest: false,
        sentModal: false,
        notSentModal: false,
        loggedModal: false,
        uploadedFiles: [],
        preview_num: '',
        preview: ''
      })

      const isLoaded = computed(() => {
        return state.job.id > 0;
      });

      const onFileUpload = (value) => {
        state.uploadedFiles = value;
      }

      const oldMounted = () => {

        let objectFitImages = require('object-fit-images');

        // Object-fit fix for IE
        objectFitImages('img.objectFitImage');


        if (typeof Grnhse!== "undefined" && Grnhse.Iframe !== undefined){
          Grnhse.Iframe.load(state.job.id);
        }

        if (window.innerWidth >= desktop && !isIE) {

          let svgPathsJobDetail = [
            {
              id: 'svgGroup11__path1',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: "#job-detail-job-description",
              offset: '0'
            },
            {
              id: 'svgGroup11__path2',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: "#job-detail-job-description",
              offset: '0'
            },
            {
              id: 'svgGroup11__path3',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: "#job-detail-job-description",
              offset: '0'
            },
            {
              id: 'svgGroup12__path1',
              tweenId: '2',
              speed: 2,
              delay: 0,
              trigger: "#job-detail-apply-intro",
              offset: '-200'
            },
            {
              id: 'svgGroup12__path2',
              tweenId: '2',
              speed: 2,
              delay: 0,
              trigger: "#job-detail-apply-intro",
              offset: '-200'
            },
            {
              id: 'svgGroup12__path3',
              tweenId: '2',
              speed: 2,
              delay: 0,
              trigger: "#job-detail-apply-intro",
              offset: '-200'
            },
            {
              id: 'svgGroup13__path1',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: "#job-detail-apply",
              offset: '-200'
            },
            {
              id: 'svgGroup13__path2',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: "#job-detail-apply",
              offset: '-200'
            },
            {
              id: 'svgGroup13__path3',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: "#job-detail-apply",
              offset: '-200'
            },
            {
              id: 'svgGroup14__path1',
              tweenId: '4',
              speed: 2,
              delay: 0,
              trigger: "#svgGroup14",
              offset: '-200'
            },
            {
              id: 'svgGroup14__path2',
              tweenId: '4',
              speed: 2,
              delay: 0,
              trigger: "#svgGroup14",
              offset: '-200'
            },
            {
              id: 'svgGroup14__path3',
              tweenId: '4',
              speed: 2,
              delay: 0,
              trigger: "#svgGroup14",
              offset: '-200'
            },
            {
              id: 'svgGroup15__path1',
              tweenId: '5',
              speed: 2,
              delay: 0,
              trigger: "#svgGroup15",
              offset: '-300'
            },
            {
              id: 'svgGroup15__path2',
              tweenId: '5',
              speed: 2,
              delay: 0,
              trigger: "#svgGroup15",
              offset: '-300'
            },
            {
              id: 'svgGroup15__path3',
              tweenId: '5',
              speed: 2,
              delay: 0,
              trigger: "#svgGroup15",
              offset: '-300'
            },
          ]

          let svgTweensJobDetail = {};

          // Loop through SVG PATHS array / build tweens and scenes
          svgAnimate(svgPathsJobDetail, svgTweensJobDetail);
        }
      }

      const autosizeTextarea = (e) => {
        //e.target.style.cssText = 'height:1.3em;';
        //e.target.style.cssText = 'height:' + e.target.scrollHeight + 'px';
      }

      const submitForm = (job_title) => {
        let data = new FormData(document.getElementById('job_apply'));
        _toggleSpinner(true);
        data.append('job_title', job_title);

        let locs = document.querySelectorAll('input[name="locations"]');
        for (let loc of locs) {
          if (loc.checked) {
            state.places.push(loc.value);
          }
        }
        data.append('locations', state.places);
        let docs = document.querySelectorAll('input[name="documents"]');
        for (let doc of docs) {
          state.documents.push(doc.value);
        }
        data.append('documents', state.documents);

        state.ajaxRequest = true;
        this.$http.post(backend + 'job/apply/', data, { headers: { "content-type": "text/plain; charset=UTF-8" } })
          .then(response => {
            state.postResults = response.data;
            state.ajaxRequest = false;
            _toggleSpinner(false);
            if (response.status === 202) {
              state.loggedModal = true;
              state.sentModal = true;
              VueScrollTo.scrollTo(document.getElementById("job-detail-apply"));
            }
            else if (response.status !== 200) {
              state.notSentModal = true;
            } else {
              state.sentModal = true;
              VueScrollTo.scrollTo(document.getElementById("job-detail-apply"));
            }
          }).catch((err) => {
            state.notSentModal = true;
            _toggleSpinner(false);
          })
      }

      const filesChange = (fieldName, fileList) => {
        // handle file changes
        const formData = new FormData();

        if (!fileList.length) return;

        // append the files to FormData
        Array
          .from(Array(fileList.length).keys())
          .map(x => {
            formData.append(fieldName, fileList[x], fileList[x].name);
          });
        // save it
        save(formData);
      }

      const route = useRoute();

      api(backend + 'page/jobs/')
        .then((data) => {
          state.jsonData = data.data;
          EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
          EventBus.$emit('isLoaded', true);
          EventBus.$emit('isPurple', true);
          EventBus.$emit('footer', state.jsonData.site_details.tceg);
          EventBus.$emit('statement', state.jsonData.site_details.footer_statement);

          state.jsonData.site_details.all_companies.forEach(company => {
            state.agencyColours[company.short_name] = company.colour;
          });

          state.agencyColours['XD'] = state.jsonData.site_details.tceg.colour;

          var companies = [
            state.jsonData.site_details.tceg,
            ...state.jsonData.site_details.all_companies
          ];

          var boardCompany = null;

          companies.forEach((company) => {
            if (company.greenhouse_api_job_boards.length && company.greenhouse_api_job_boards.indexOf(route.params.board) > -1) {
              boardCompany = company;
            }
          });

          state.locations = state.jsonData.site_details.all_locations;
          let url = 'https://boards-api.greenhouse.io/v1/boards/' + route.params.board + '/jobs/' + route.params.job;

          let greenhouseJs = document.createElement('script');
          greenhouseJs.async = true
          greenhouseJs.src = 'https://boards.greenhouse.io/embed/job_board/js?for=' + route.params.board;
          document.head.appendChild(greenhouseJs);
          greenhouseJs.onload = () => {
            api(url)
              .then(jobData => {
                state.job = jobData;
                state.job.job_company = boardCompany;
                state.job.job_content = decodeHTML(jobData.content);

                nextTick().then(() => {
                  oldMounted();
                });
              });

          };

          api(url)
            .then(jobData => {
              state.job = jobData;
              state.job.job_company = boardCompany;
              state.job.job_content = decodeHTML(jobData.content);
              state.job.url = jobData.absolute_url;
              nextTick().then(() => {
                oldMounted();
              });
            });

        }).catch(error => {
          state.loading = false;
        });

      return {
        state,
        oldMounted,
        isLoaded,
        filesChange,
        submitForm,
        autosizeTextarea,
        onFileUpload
      }
    }
  }</script>
