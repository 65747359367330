<template>
  <button @click="recaptcha">Execute recaptcha</button>
</template>
<script >
  import * as Vue from 'vue';
  import { VueReCaptcha } from 'vue-recaptcha-v3';

  export default {
    name: 'captcha',
    components: {
    },
    props: {
      item: Object
    },
    data() {
      return {

      }
    },
    methods: {
      methods: {
        async recaptcha() {
          // (optional) Wait until recaptcha has been loaded.
          await this.$recaptchaLoaded()

          // Execute reCAPTCHA with action "login".
          const token = await this.$recaptcha('login')

          // Do stuff with the received token.
        }
      },
    }
  }</script>
