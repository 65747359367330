import HomePage from './components/home-page';
import JobsPage from './components/jobs-page';
import JobDetailPage from './components/job-detail-page';
import PeopleDetailPage from './components/people-detail-page';
import PeoplePage from './components/people-page';
import NewsPage from './components/news-page';
import ContactPage from './components/contact-page';
import AboutPage from './components/about-page';
import NewsItemPage from './components/news-item-page';
import ServicesPage from './components/services-page';
import ServiceDetailPage from './components/service-detail-page';
import WorkPage from './components/work-page';
import WorkDetailPage from './components/work-detail-page';
import CookiesPage from './components/cookies-page';
import PrivacyPage from './components/privacy-page';
import CodeOfEthicsPage from './components/code-of-ethics-page';
import SitemapPage from './components/sitemap-page';
import TermsOfUsePage from './components/terms-of-use';
import StatutoryInformationPage from './components/statutory-information';
import ModernSlaveryPage from './components/modern-slavery';
import CampaignPage from './components/campaign-page';
import CampaignsPage from './components/campaigns-page';
import FocusGroupPage from './components/focus-group-page';

export const routes = [
  { path: '/', name: 'home-page', component: HomePage },
  { path: '/jobs', name: 'jobs-page', component: JobsPage },
  { path: '/jobs/:board/:job/:title', name: 'job-item', component: JobDetailPage, props: true },
  { path: '/people/:person', name: 'person', component: PeopleDetailPage, props: true },
  { path: '/people', name: 'people-page', component: PeoplePage },
  { path: '/news', name: 'news-page', component: NewsPage },
  { path: '/news/:link', name: 'news-item', component: NewsItemPage, props: true },
  { path: '/contact', name: 'contact-page', component: ContactPage },
  { path: '/about', name: 'about-page', component: AboutPage },
  { path: '/services', name: 'services-page', component: ServicesPage },
  { path: '/work', name: 'work-page', component: WorkPage },
  { path: '/work/:link', name: 'work-item', component: WorkDetailPage, props: true },
  { path: '/services/:link', name: 'service-item', component: ServiceDetailPage, props: true },
  { path: '/cookies', name: 'cookies-page', component: CookiesPage },
  { path: '/privacy-policy', name: 'privacy-policy-page', component: PrivacyPage },
  { path: '/code-of-ethics', name: 'code-of-ethics-page', component: CodeOfEthicsPage },
  { path: '/sitemap', name: 'sitemap-page', component: SitemapPage },
  { path: '/terms-of-use', name: 'terms-of-use', component: TermsOfUsePage },
  { path: '/statutory-information', name: 'statutory-information', component: StatutoryInformationPage },
  { path: '/modern-slavery-statement', name: 'modern-slavery-statement', component: ModernSlaveryPage },

  { path: '/solutions', name: 'campaigns-page', component: CampaignsPage, props: true },
  { path: '/campaign/:link', name: 'campaign-item', component: CampaignPage,  props: true },
  { path: '/preview/campaign/:num?/:title?', name: 'campaign-item-preview', component: CampaignPage, props: true },

  { path: '/virtual-focus-group-info', name: 'focus-group-page', component: FocusGroupPage, props: true },

  { path: '/preview/people/:num/:title', name: 'preview-people-page', component: PeoplePage, props: true },
  { path: '/preview/jobs/:num/:title', name: 'preview-job-item', component: JobDetailPage, props: true },
  { path: '/preview/work/:num/:title', name: 'preview-work-item', component: WorkDetailPage, props: true },
  { path: '/preview/news/:num/:title', name: 'preview-news-item', component: NewsItemPage, props: true },
  { path: '/preview/location/:num/:title', name: 'preview-contact-page', component: ContactPage, props: true },
  { path: '/preview/services/:num/:title', name: 'preview-service-item', component: ServiceDetailPage, props: true },


  //These previews don't work as they cause loops
  //{ path: '/preview/page/:num/home', name: 'home-page', component: HomePage, props: true },
  //{ path: '/preview/page/:num/about-page', name: 'about-page', component: AboutPage, props: true },
  //{ path: '/preview/page/:num/services-page', name: 'services-page', component: ServicesPage, props: true },
  //{ path: '/preview/page/:num/cookies-page', name: 'cookies-page', component: CookiesPage, props: true },
  //{ path: '/preview/page/:num/privacy-policy-page', name: 'privacy-policy-page', component: PrivacyPage, props: true },
  //{ path: '/preview/page/:num/code-of-ethics-page', name: 'code-of-ethics-page', component: CodeOfEthicsPage, props: true },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/'
  }
];
