import { createApp, reactive } from 'vue';
import App from './App.vue';
import router from './router';

import "custom-event-polyfill";
import "isomorphic-fetch"
import './scss/style.scss';
import 'vue3-resize/dist/vue3-resize.css';
import Vue3Resize from 'vue3-resize'
import {decodeHTML} from "./utils/decode-html";

var apiUrl = null;
var useLocalApi = true;
var hubSpot = null;
var heddleID = null;
var slug = null;

var localBackend = 'http://tceg.local/be/';
var stagingBackend = 'https://staging-api-xd.inizioengage.com/be/';
//var liveBackend = 'https://tceg-api.azurewebsites.net/be/';
var liveBackend = 'https://live-api-xd.inizioengage.com/be/';

var localHubSpot = '7713795';
var liveHubSpot = '1968984';
var testOneTrust = '36209acf-3d4c-4c96-a3f3-a35140b8649f-test';
var liveOneTrust = '36209acf-3d4c-4c96-a3f3-a35140b8649f';

var stagingHeddleID = 6092;
var liveHeddleID = 6067;
var oneTrust = null;

if (document.location.host == '127.0.0.1:8081' ||
  document.location.host == 'localhost:8081' ||
  document.location.host == 'localhost:8082' ||
  document.location.host == 'localhost:8090' ||
  document.location.host == 'localhost:5173' ||
  document.location.host.indexOf('192.168') > -1 ||
  document.location.host.indexOf('808') > -1 ||
  document.location.host === 'tceg-fe.local') {

  if (useLocalApi) {
    apiUrl = localBackend;
    hubSpot = localHubSpot;
    heddleID = stagingHeddleID;
  }
  else {
    apiUrl = stagingBackend;
    //hubSpot = liveHubSpot;
    hubSpot = localHubSpot;
    heddleID = stagingHeddleID;
  }
} else if (document.location.host == 'tceg-stage.azurewebsites.net') {

  apiUrl = stagingBackend;
  //hubSpot = liveHubSpot;
  heddleID = stagingHeddleID;
}
else {
  apiUrl = liveBackend;
  hubSpot = liveHubSpot;
  heddleID = liveHeddleID;
  oneTrust = liveOneTrust;
  //setCookie('__hs_initial_opt_in', true, 90); // hiding hubspot cookiebar
}

let windowReactive = reactive( window );

const app = createApp(App, {
}).use(router).use(Vue3Resize);
app.provide('window', windowReactive);
app.provide('isIE', /MSIE|Trident/.test(window.navigator.userAgent));
app.provide('desktop', 1200);
app.provide('tablet', 768);
app.provide('mobile', 480);
app.provide('maps_key' ,'AIzaSyCIpTTLUg4s64IcQhpbFrKr0yOcyiQKfXk');
app.provide('svgPaths', []);
app.provide('tweens' , {});
app.provide('cursorFollower', null);
app.provide('posX' , 0);
app.provide('posY' , 0);
app.provide('mouseX' ,0);
app.provide('mouseY' , 0);
app.provide('menuItem', null);
app.provide('backend', apiUrl);
app.provide('slug', slug);
app.provide('hubSpot', hubSpot);
app.provide('heddleID', heddleID);
app.provide('oneTrust', oneTrust);
app.mount('#app');

router.afterEach((to, from) => {
  window.scrollTo(0,0);
  if (!to.path.includes('jobs')) {
    fetch(apiUrl + 'seo/?p=' + encodeURI(to.path))
      .then(response => response.json())
      .then((data) => {
        // remove any html symbols
        document.title = decodeHTML(data.data.title);
        gtag('config', gtagID, {
          'page_title': data.data.title,
          'page_path': to.path
        });
      }).catch(error => {
        //this.loading = false;
      });
  }
});
