<template>
  <div v-if="isLoaded" class="footerPage">
    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />
    <header-component type="footer" class='header--footerLinks'>
    </header-component>
    <main>
      <h1>{{ state.jsonData.content.title }}</h1>
      <template v-for='(content, index) in state.jsonData.content'>
        <div v-if='index !== "title"'>
          <h2 style="margin:10px 0 -10px"> {{ index.toLowerCase().split('_').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ') }}</h2>
          <ul>
            <template v-for="c in content">
              <li class='sitemap'
                  @click="goToItem(c.link, c.itemLink)"
                  v-if="c.id !== 5376"
              >
                {{ c.post_title }}
              </li>
            </template>

          </ul>
        </div>
      </template>
    </main>
  </div>
</template>
<script>
  import EventBus from '../eventbus.js';
  import HeaderComponent from './widgets/header-component';
  import { api } from '../utils/api-gateway';
  import HeaderBar from './widgets/header-bar';
  import { useRouter } from "vue-router";
  import { reactive, computed, nextTick } from 'vue';
  import { useInjects } from '../utils/globals-inject';
  import MyPluginCPS from '../plugins/MyPluginCPS.js';
  export default {
    name: 'SitemapPage',
    components: {
      HeaderBar,
      HeaderComponent
    },
    setup() {
      const router = useRouter();
      let { window, desktop, mobile, tablet, mouseX, mouseY, backend } = useInjects();
      const { goToItem } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);

      const state = reactive({
        jsonData: []
      });

      const isLoaded = computed(() => {
        if (state.jsonData.hasOwnProperty('content')) {
          return true;
        }
        return false;
      });

      const oldMounted = () => {
      };

      api(backend + 'page/sitemap/')
        .then((data) => {
          state.jsonData = data.data;
          EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
          EventBus.$emit('isLoaded', true);
          EventBus.$emit('footer', state.jsonData.site_details.tceg);
          EventBus.$emit('isPurple', true);
          EventBus.$emit('statement', state.jsonData.site_details.footer_statement);
          nextTick().then(() => {
            oldMounted();
          });
        }).catch(error => {
          state.loading = false;
        });

      return {
        state,
        window,
        tablet,
        mobile,
        goToItem,
        isLoaded
      };
    }
  }</script>
