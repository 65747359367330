<template>
  <div v-if="isLoaded" class="aboutPage">

    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />

    <header-component type='about'
                      class='header--about'
                      :title=state.jsonData.content.banner_large_text
                      :image=state.jsonData.content.banner_image>
    </header-component>
    <main>
      <modal-component v-show="state.isModalVisible" @close-modal="state.aboutVideo.stop(); state.isModalVisible = false">
        <template v-slot:body>
          <div :class=state.jsonData.content.showreel.colour>
          </div>
        </template>
      </modal-component>
      <div class="aboutPage__intro">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 338.1 687.1" id="svgGroup22">
          <g>
            <path id="svgGroup22__path5" class="svgpath svgpath--mist" d="M160.8,364.9C74.4,365,4.4,295.1,4.3,208.8c0,0,0,0,0,0v-63.2" />
            <path id="svgGroup22__path4" class="svgpath svgpath--mist" d="M177.9,364.9c85.6,0,155,69.4,155,155c0,0,0,0,0,0v160.6" />
            <path id="svgGroup22__path1" class="svgpath svgpath--mist" d="M5.8,6.3v203.6c0,85.6,69.4,155,155,155" />
            <path id="svgGroup22__path3" class="svgpath svgpath--fuschia" d="M177.9,364.9c85.6,0,155,69.4,155,155c0,0,0,0,0,0" />
            <path id="svgGroup22__path2" class="svgpath svgpath--green" d="M77.1,419.1c55.7-55.7,146-55.7,201.7,0" />
          </g>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213.4 428.3" id="svgGroup32">
          <g>
            <path id="svgGroup32__path4" class="svgpath svgpath--mist" d="M101.1,227c-53.5-0.1-96.9,43.2-97,96.7V424" />
            <path id="svgGroup32__path1" class="svgpath svgpath--mist" d="M112.1,226c53.5,0.1,96.9-43.3,97-96.8c0,0,0,0,0,0V2" />
            <path id="svgGroup32__path2" class="svgpath svgpath--fuschia" d="M101.1,227c-53.6,0-97,43.4-97,97" />
            <path id="svgGroup32__path3" class="svgpath svgpath--green" d="M38.1,260c34.8-34.7,91.2-34.7,126,0" />
          </g>
        </svg>
        <a class="button button--purple button--aboutReel" @click="state.isModalVisible = true">
          <span>view reel</span>
        </a>
        <h2>{{state.jsonData.content.introduction_bullet_header}}</h2>
        <div v-html="state.jsonData.content.introduction_text" class="aboutPage__intro__description" />
      </div>

      <div class="aboutPage__agencies">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 287.9 109.6" id="svgGroup30">
          <g>
            <path id="svgGroup30__path2" class="svgpath svgpath--mist" d="M2.3,2.3c9.4,49.4,52.6,85,102.9,85h143.4" />
            <path id="svgGroup30__path5" class="svgpath svgpath--fuschia" d="M136.8,67.8h-26.7c-31.6,0-61.5,14.3-81.3,39" />
            <path id="svgGroup30__path3" class="svgpath svgpath--fuschia" d="M45.3,69.3C63,81.1,83.7,87.4,105,87.3h51.4" />
            <path id="svgGroup30__path4" class="svgpath svgpath--fuschia" d="M252.6,66.3h-68.2" />
            <path id="svgGroup30__path1" class="svgpath svgpath--green" d="M12.8,3.8c9.2,29.2,30.8,53,59.1,64.8c12.9,5.4,26.7,8.2,40.7,8.2h173.3" />
          </g>
        </svg>
        <h2>
          {{state.jsonData.content.companies_title}}
        </h2>
        <img src="../assets/drag-arrows-pink.svg" alt="Drag Slider Icon" class="aboutPage__agencies__dragIcon">
        <ul v-if="window.innerWidth >= tablet" class="aboutPage__agencies__list">
          <li v-for="(item, index) in state.jsonData.site_details.all_companies" class="aboutPage__agencies__list__listItem">
            <agency :item=item></agency>
          </li>
        </ul>
        <carousel v-else
                  ref="slick"
                  :items-to-show="state.slickOptions.itemsToShow"
                  :breakpoints="state.slickOptions.breakpoints"
                  :snap-align="state.slickOptions.snapAlign"
                  :wrap-around="state.slickOptions.wrapAround"
                  class="carousel carousel--agencies">
          <slide v-for="(item, index) in state.jsonData.site_details.all_companies" aria-hidden="null">
            <agency :item=item></agency>
          </slide>
        </carousel>
        <p class="aboutPage__agencies__summary">
          {{state.jsonData.content.companies_end_text}}
        </p>
      </div>

      <div class="aboutPage__awards">
        <h2 class="aboutPage__awards__title">
          {{state.jsonData.content.awards.title}}
        </h2>
        <div class="aboutPage__awards__text" v-html="state.jsonData.content.awards.text"></div>
        <img class="aboutPage__awards__drag-icon" src="../assets/drag-label.svg" alt="Drag Icon">

        <ul class="aboutPage__awards__list">
          <carousel id="awardsScroll"
                    ref="slick"
                    :items-to-show="state.slickOptions2.itemsToShow"
                    :breakpoints="state.slickOptions2.breakpoints"
                    :snap-align="state.slickOptions2.snapAlign"
                    :wrap-around="state.slickOptions2.wrapAround"
                    class="carousel carousel--awards">
            <slide v-for="(item, index) in state.jsonData.content.awards.images" class="aboutPage__awards__list__listItem" aria-hidden="null">
              <picture-sources v-if="item.image && item.image.sizes && item.image.sizes.length"
                               :targetWidth="50"
                               :sizes="item.image.sizes"
                               :imgSrc="item.image.guid"
                               imgAlt=""
                               imgClass="objectFitImage" />
            </slide>
          </carousel>
        </ul>
      </div>

      <div class="aboutPage__gallery2">
        <ul class="aboutPage__gallery2__list">
          <li class="aboutPage__gallery2__imgWrapper" v-for="(item, index) in state.jsonData.content.gallery_2_images">
            <picture-sources v-if="item && item.sizes && item.sizes.length"
                             :targetWidth="window.innerWidth <= tablet ? 50 : 33"
                             :sizes="item.sizes"
                             :imgSrc="item.guid"
                             imgAlt=""
                             imgClass="objectFitImage" />
          </li>
        </ul>
      </div>

      <div class="aboutPage__inside_tceg">
        <div class="aboutPage__inside_tceg__col1">
          <h2 class="aboutPage__inside_tceg__title">
            {{ state.jsonData.content.inside_title }}
          </h2>
          <picture-sources v-if="state.jsonData.content.inside_image && state.jsonData.content.inside_image.sizes && state.jsonData.content.inside_image.sizes.length"
                           :targetWidth="100"
                           :sizes="state.jsonData.content.inside_image.sizes"
                           :imgSrc="state.jsonData.content.inside_image.guid"
                           imgAlt="Inside TCEG" />
          <p class="aboutPage__inside_tceg__text" v-html="state.jsonData.content.inside_text"></p>
        </div>
      </div>

      <div class="aboutPage__summary">
        <div class="aboutPage__summary__col1">
          <h2 class="aboutPage__summary__title">
            {{state.jsonData.content.end_small_title}}
          </h2>
          <p class="aboutPage__summary__text">
            {{state.jsonData.content.end_text}}
          </p>
          <a href="https://inizio.health/" target="_blank" rel="noreferrer noopener">
            <img src="../assets/logos/Inizio_Logo_White_RGB_v02.png" alt="Inizio Logo" class="aboutPage__summary__logo">
          </a>
        </div>
        <div class="aboutPage__summary__col2">
          <div class="aboutPage__summary__contact">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 773.2 843.7" id="svgGroup31">
              <g>
                <path id="svgGroup31__path2" class="svgpath svgpath--fuschia" d="M349.2,25.6c-119.6-0.2-232.9,53.9-308,147" />
                <path id="svgGroup31__path1" class="svgpath svgpath--green" d="M51.6,133.1C178.2,3,374.6-29.7,536.4,52.4C707.8,139.1,801,336.8,749.3,529.7
                            c-32.1,119.9-113.6,213.4-216.8,264.4c-90.3,44.8-193.8,55-291.1,28.8" />
                <path id="svgGroup31__path3" class="svgpath svgpath--fuschia" d="M692.3,223.9c109.5,189.7,44.6,432.2-145.1,541.7S115,810.2,5.5,620.5" />
              </g>
            </svg>
            <img src="https://liveapi.tceg.com//content/uploads/2022/07/TCEG-map-updated-01.png" alt="TCEG Contact" class="objectFitImage">
            <a class="button button--purple" id="buttonAboutContact" href="/contact">
              <span>contact us</span>
            </a>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
  import { Carousel, Slide } from 'vue3-carousel';
  import EventBus from '../eventbus.js';
  import HeaderComponent from './widgets/header-component';
  import Agency from './widgets/agency';
  import ModalComponent from './widgets/modal-component';
  import Slider from './widgets/slider';
  import { TweenMax, Elastic, Bounce } from 'gsap';
  import { api } from '../utils/api-gateway';
  import PictureSources from './widgets/picture-sources';
  import { TimelineMax } from "gsap";
  import HeaderBar from './widgets/header-bar';
  import 'vue3-carousel/dist/carousel.css';
  import {computed, nextTick, reactive, getCurrentInstance, onUpdated} from "vue";
  import objectFitImages from "object-fit-images";
  import { useRoute } from "vue-router";
  import { useInjects } from '../utils/globals-inject';

  export default {
    name: 'AboutPage',
    components: {
      HeaderComponent,
      PictureSources,
      ModalComponent,
      Agency,
      Slider,
      Carousel,
      Slide,
      TweenMax,
      HeaderBar
    },
    setup() {
      let { window, mobile, tablet, backend } = useInjects();

      const state = reactive({
        jsonData: [],
        aboutVideo: null,
        isModalVisibe: false,
        slickOptions: {
          itemsToShow: 1,
          snapAlign: 'start',
          wrapAround: false
        },
        slickOptions2: {
          itemsToShow: 5,
          snapAlign: 'start',
          wrapAround: false,
          breakpoints: {
            0: {
              itemsToShow: 3
            },
            981: {
              itemsToShow: 4,
            },
            1200: {
              itemsToShow: 4
            },
            1201: {
              itemsToShow: 4
            }
          }
        }
      });

      const isLoaded = computed(() => {
        if (state.jsonData.hasOwnProperty('content')) {
          return true;
        }
        return false;
      });

      const currentInstance = getCurrentInstance();

      let url;
      const route = useRoute();

      if (route.params.num) {
        state.preview_num = route.params.num;
        url = backend + 'preview/page/' + state.preview_num + '/about-page';
      } else {
        url = backend + 'page/about/';
      }
      api(url)
        .then((data) => {
          state.jsonData = data.data;
          EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
          EventBus.$emit('isLoaded', true);
          EventBus.$emit('footer', state.jsonData.site_details.tceg);
          EventBus.$emit('isPurple', false);
          EventBus.$emit('statement', state.jsonData.site_details.footer_statement);
          nextTick().then(() => {
            oldMounted();
          });
        }).catch(error => {
          state.loading = false;
        });

      const initReelVideo = () => {
        state.aboutVideo = jwplayer("aboutVideo");
        if (typeof state.aboutVideo.setup === 'function') {
          state.aboutVideo.setup({
            //file: this.jsonData.content.showreel,
            id: "aboutVideo",
            width: "100%",
            aspectratio: "16:9",
            file: "https://cdn.jwplayer.com/manifests/" + state.jsonData.content.showreel.code + ".m3u8",
            image: "https://content.jwplatform.com/thumbs/" + state.jsonData.content.showreel.code + ".jpg",
            customProps: { skin: { url: './css/player.css', name: 'svp' }, controls: true }
          });
        }
      };

      const oldMounted = () => {
        let objectFitImages = require('object-fit-images');

        // Object-fit fix for IE
        objectFitImages('img.objectFitImage');

        initReelVideo();

        if (currentInstance.parent.data.window && currentInstance.parent.data.window.innerWidth >= currentInstance.parent.data.desktop && !currentInstance.parent.data.isIE) {
          let svgPathsAbout = [
            {
              id: 'svgGroup22__path1',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: ".aboutPage__intro",
              offset: '100'
            },
            {
              id: 'svgGroup22__path2',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: ".aboutPage__intro",
              offset: '100'
            },
            {
              id: 'svgGroup22__path3',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: ".aboutPage__intro",
              offset: '100'
            },
            {
              id: 'svgGroup22__path4',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: ".aboutPage__intro",
              offset: '100'
            },
            {
              id: 'svgGroup31__path1',
              tweenId: '6',
              speed: 4,
              delay: 0,
              trigger: ".aboutPage__summary",
              offset: '0'
            },
            {
              id: 'svgGroup31__path2',
              tweenId: '6',
              speed: 2,
              delay: 0,
              trigger: ".aboutPage__summary",
              offset: '0'
            },
            {
              id: 'svgGroup31__path3',
              tweenId: '6',
              speed: 2,
              delay: 0,
              trigger: ".aboutPage__summary",
              offset: '0'
            },
            {
              id: 'svgGroup64__path1',
              tweenId: '7',
              speed: 3,
              delay: 0.5,
              trigger: ".aboutPage__feature",
              offset: '0'
            },
            {
              id: 'svgGroup64__path2',
              tweenId: '7',
              speed: 3,
              delay: 0,
              trigger: ".aboutPage__feature",
              offset: '0'
            }
          ]

          let svgTweensAbout = {};

          // Loop through SVG PATHS array / build tweens and scenes

          svgAnimate(svgPathsAbout, svgTweensAbout);

          // Build tween & scene 17 (Portfolio section details)
          let tween7 = new TimelineMax()
            .add(TweenMax.from('.aboutPage__feature__content', 0.5, { opacity: 0, ease: Linear.easeInOut }))
            .add(TweenMax.from('.aboutPage__feature__info h3', 0.7, { y: -50, opacity: 0, ease: Linear.easeInOut }))
            .add(TweenMax.fromTo('.aboutPage__feature .button--healthcare', 0.5, { scale: 0.001, ease: Elastic.easeInOut }, { scale: 1.4, ease: Elastic.easeInOut }))
            .add(TweenMax.to('.aboutPage__feature .button--healthcare', 0.3, { scale: 1, delay: 0.2, ease: Elastic.easeInOut }))
            .add(TweenMax.fromTo('.aboutPage__feature .button--healthcare span', 0.5, { opacity: 0 }, { opacity: 1, delay: 0.2 }));


          let scene7 = new ScrollMagic.Scene({
            triggerElement: ".aboutPage__feature",
            offset: -200
          })
            .setTween(tween7)
          // .addIndicators({name: "17"})

          new ScrollMagic.Controller().addScene(scene7);

          let circletween = new TimelineMax();
          circletween.to('.circle__1', .1, { backgroundColor: '#00CA9F', delay: 1 });
          circletween.from('.circle__1', .1, { backgroundColor: '#E2E2F2', delay: .5 });
          circletween.to('.circle__2', .1, { backgroundColor: '#00CA9F' });
          circletween.from('.circle__2', .1, { backgroundColor: '#7949F0', delay: .5 });
          circletween.to('.circle__3', .1, { backgroundColor: '#00CA9F' });

          let scenecircle = new ScrollMagic.Scene({
            triggerElement: ".aboutPage__agencies__list",
            //offset: -200
          })
            .setTween(circletween)
          // .addIndicators({name: "17"})

          new ScrollMagic.Controller().addScene(scenecircle);

        }
      };

      onUpdated(() => {
        initReelVideo();
      })

      return {
        state,
        isLoaded,
        oldMounted,
        window,
        tablet,
        mobile
      }
    }
  }
</script>
