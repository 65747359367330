<template>

  <div class="input-box" v-bind:data-type="$attrs.type">

    <label>
      <span v-if="labelText">{{ labelText }}</span>
      <input v-bind="$attrs" />
    </label>

    <small v-if="isRequired" class="input-box-required">Required</small>

  </div>
</template>
<script >export default {
    name: 'input-box',
    inheritAttrs: false,
    props: {
      agencyColor: String
    },
    data() {
      return {
        isRequired: 'required' in this.$attrs,
        labelText: 'label' in this.$attrs ? this.$attrs.label : false,
      }
    },
    methods: {
    }
  }</script>
