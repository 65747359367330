<template>
  <header v-if="$props.type !== 'footer'" class="header" :class="[!$props.image ? 'header--noBannerImg' : '']" :style="{backgroundImage: $props.image ? 'url('+$props.image+')' : null}">

    <div v-if="$props.code" id="service-header-video"></div>
    <div v-else-if="$props.video" id="service-header-video"></div>

    <picture-sources v-else-if="state.videoID !== 6092 && $props.image && $props.image.sizes && $props.image.sizes.length" :targetWidth="100" :sizes="$props.image.sizes" :imgSrc="$props.image.guid" :imgAlt="state.name" imgClass="objectFitHeaderImage" />

    <div v-if="$props.type == 'workDetail'" class="header__intro">
      <h2>{{ $props.title }}</h2>
      <p>{{ $props.intro }}</p>
      <span class="header__intro__services">{{ $props.services }}</span>
    </div>
    <div v-else-if="$props.type === 'serviceDetail' && $props.additionalContent !== undefined && $props.additionalContent !== ''" class="header__intro">
      <div class="textContainer" v-html="$props.additionalContent">

      </div>
      <div class="svgPathContainer">
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 350.194 783.249"
             id="svgGroup1">
          <g>
            <path id="svgGroup1__path7"
                  class="svgpath svgpath--green"
                  d="M267.4,519.3c-55.7-55.7-146-55.7-201.7,0" />
            <path id="svgGroup1__path6"
                  class="svgpath svgpath--mist"
                  d="M166.5,465.1c-85.6,0-155,69.4-155,155l0,0v160.6" />
            <path id="svgGroup1__path5"
                  class="svgpath svgpath--fuschia"
                  d="M166.5,465.1c-85.6,0-155,69.4-155,155l0,0" />
            <path id="svgGroup1__path4"
                  class="svgpath svgpath--mist"
                  d="M338.7,106.5v203.6c0,85.6-69.4,155-155,155" />
            <path id="svgGroup1__path3"
                  class="svgpath svgpath--mist"
                  d="M338.7,59.1v80" />
            <path id="svgGroup1__path2"
                  class="svgpath svgpath--green"
                  d="M327.7,94v205" />
            <path id="svgGroup1__path1"
                  class="svgpath svgpath--fuschia"
                  d="M316.7,39v205" />
          </g>
          <g id="scroll-text">
            <path class="svgText"
                  d="M304.3,12.5c0-0.5-0.2-0.8-0.5-1.1s-0.9-0.6-1.8-0.8c-1.1-0.3-1.9-0.6-2.5-1.1c-0.6-0.5-0.9-1.1-0.9-1.8
                            c0-0.8,0.3-1.4,1-1.9s1.5-0.8,2.5-0.8c1.1,0,2,0.3,2.6,0.9c0.6,0.6,1,1.3,0.9,2.1l0,0h-1.3c0-0.6-0.2-1.1-0.6-1.4
                            c-0.4-0.4-0.9-0.5-1.7-0.5c-0.7,0-1.2,0.2-1.5,0.5c-0.4,0.3-0.5,0.7-0.5,1.2c0,0.4,0.2,0.8,0.6,1.1c0.4,0.3,1,0.5,1.9,0.8
                            c1,0.3,1.8,0.6,2.4,1.1s0.8,1.1,0.8,1.9c0,0.8-0.3,1.4-1,1.9s-1.5,0.7-2.6,0.7c-1,0-1.9-0.3-2.6-0.8c-0.8-0.5-1.1-1.3-1.1-2.2l0,0
                            h1.3c0,0.6,0.2,1.1,0.7,1.5c0.5,0.3,1.1,0.5,1.7,0.5c0.7,0,1.2-0.1,1.6-0.4C304.1,13.4,304.3,13,304.3,12.5z" />
            <path class="svgText"
                  d="M316.7,11.8L316.7,11.8c0,1-0.3,1.8-1,2.4c-0.7,0.6-1.6,0.9-2.7,0.9c-1.1,0-2-0.4-2.8-1.2
                            c-0.7-0.8-1.1-1.8-1.1-3V9.1c0-1.2,0.4-2.2,1.1-3c0.7-0.8,1.6-1.2,2.8-1.2c1.1,0,2,0.3,2.7,0.9c0.7,0.6,1,1.4,1,2.4l0,0h-1.3
                            c0-0.7-0.2-1.2-0.6-1.7S313.8,6,313,6c-0.8,0-1.4,0.3-1.8,0.9c-0.4,0.6-0.7,1.4-0.7,2.3v1.8c0,0.9,0.2,1.7,0.7,2.3
                            c0.4,0.6,1,0.9,1.8,0.9c0.7,0,1.3-0.2,1.7-0.6c0.4-0.4,0.6-1,0.6-1.7H316.7z" />
            <path class="svgText"
                  d="M321.8,10.7V15h-1.3V5.1h3.6c1.1,0,1.9,0.2,2.5,0.7s0.9,1.2,0.9,2.1c0,0.5-0.1,1-0.4,1.3s-0.7,0.7-1.2,0.9
                            c0.5,0.2,0.9,0.5,1.2,0.9c0.2,0.4,0.4,0.9,0.4,1.5v0.9c0,0.3,0,0.6,0.1,0.8s0.2,0.4,0.4,0.6V15h-1.4c-0.2-0.2-0.3-0.4-0.3-0.7
                            c-0.1-0.3-0.1-0.6-0.1-0.9v-0.9c0-0.5-0.2-1-0.5-1.3s-0.7-0.5-1.3-0.5H321.8z M321.8,9.6h2.1c0.8,0,1.3-0.1,1.6-0.4
                            c0.3-0.3,0.5-0.7,0.5-1.3c0-0.6-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.5-0.5h-2.3V9.6z" />
            <path class="svgText"
                  d="M339.3,10.9c0,1.2-0.4,2.2-1.1,3c-0.8,0.8-1.7,1.2-2.9,1.2c-1.2,0-2.1-0.4-2.8-1.2c-0.7-0.8-1.1-1.8-1.1-3
                            V9.1c0-1.2,0.4-2.2,1.1-3c0.7-0.8,1.7-1.2,2.8-1.2c1.2,0,2.2,0.4,2.9,1.2c0.8,0.8,1.1,1.8,1.1,3V10.9z M337.9,9.1
                            c0-0.9-0.2-1.7-0.7-2.2S336,6,335.2,6c-0.8,0-1.4,0.3-1.9,0.9c-0.5,0.6-0.7,1.3-0.7,2.2v1.8c0,0.9,0.2,1.7,0.7,2.3
                            c0.5,0.6,1.1,0.9,1.9,0.9c0.8,0,1.5-0.3,2-0.9c0.5-0.6,0.7-1.3,0.7-2.3V9.1z" />
            <path class="svgText" d="M344.6,13.9h4.7V15h-6.1V5h1.3V13.9z" />
            <path class="svgText" d="M354.2,13.9h4.7V15h-6.1V5h1.3V13.9z" />
          </g>
        </svg>
        <a class="button button--purple button--about"
           id="serviceButton"
           @click="state.isModalVisible = true">
          <span>view reel</span>
        </a>
        <modal-component v-show="state.isModalVisible" @close-modal="state.popupVideo.stop(); state.isModalVisible = false">
        </modal-component>
      </div>
    </div>
    <div v-else-if="$props.type === 'serviceDetail'" class="header__intro">
      <h1>{{ $props.title }}</h1>
      <p>{{ $props.intro }}</p>
    </div>
    <div v-else-if="$props.type === 'campaignPage'" class="header__intro">
      <h1>{{ $props.title }}</h1>
      <p>{{ $props.intro }}</p>
    </div>
    <div v-else-if="$props.title">
      <h1 v-html="($props.title?$props.title:'')+' '+($props.title2?$props.title2:'')"></h1>
    </div>
  </header>

  <header v-else class="header header--footerMenu">
  </header>
</template>
<script>
  import PictureSources from './picture-sources';
  import { nextTick, onMounted, onUpdated, reactive, watch } from "vue";
  import ModalComponent from "./modal-component.vue";

  export default {
    name: 'header-component',
    components: {
      ModalComponent,
      PictureSources
    },
    props: {
      type: String,
      title: String,
      title2: String,
      image: [Object, Boolean],
      video: String,
      code: String,
      popupCode: String,
      intro: String,
      services: String,
      additionalContent: String
    },
    setup(props) {
      const state = reactive({
        name: '',
        videoID: '',
        isModalVisible: false,
        popupVideo: null,
      });
      onMounted(() => {
        let playerInstance = window.jwplayer("service-header-video");
        if (props.code !== undefined && props.code !== null) {
          let videoURL = "https://cdn.jwplayer.com/videos/" + props.code + "-r1emxXWY.mp4";
          playerInstance.setup({
            id: "headerVid",
            file: videoURL,
            image: props.image,
            aspectratio: "x:y",
            autostart: true,
            repeat: true,
            mute: true,
            //stretching: (this.window.innerWidth <= this.mobile) ? "uniform" : "fill",
            stretching: "fill",
            controls: false
          });
        }
      }),
      onUpdated(() => {
        let playerInstance = window.jwplayer("service-header-video");
        if (props.code !== undefined && props.code !== null) {
          let videoURL = "https://cdn.jwplayer.com/videos/" + props.code + "-r1emxXWY.mp4";
          playerInstance.setup({
            id: "headerVid",
            file: videoURL,
            image: props.image,
            aspectratio: "x:y",
            autostart: true,
            repeat: true,
            mute: true,
            //stretching: (this.window.innerWidth <= this.mobile) ? "uniform" : "fill",
            stretching: "fill",
            controls: false
          });
        }
        if (props.popupCode !== undefined && props.popupCode !== null) {
          state.popupVideo = jwplayer("serviceVideo");
          if (typeof state.popupVideo.setup === 'function') {
            state.popupVideo.setup({
              //file: this.jsonData.showreel.video,
              width: "100%",
              aspectratio: "16:9",
              file: "https://cdn.jwplayer.com/manifests/" + props.popupCode + ".m3u8",
              image: "https://content.jwplatform.com/thumbs/" + props.popupCode + ".jpg",
              customProps: { skin: { url: './css/player.css', name: 'svp' }, controls: true }
            });
          }
          watch(() => state.isModalVisible, (isModalVisible) => {
            if(isModalVisible){
              document.querySelector('body').classList.add('modalActive');
            } else {
              document.querySelector('body').classList.remove('modalActive');
            }
          });
        }
        /*if (props.video !== undefined && props.code !== null) {
          playerInstance.setup({
            file: props.video,
            image: props.image,
            aspectratio: "x:y",
            autostart: true,
            repeat: true,
            mute: true,
            //stretching: (this.window.innerWidth <= this.mobile) ? "uniform" : "fill",
            stretching: "fill",
            controls: false
          });
        }*/
      });

      return {
        state
      }
    }
  }</script>
