<template>
  <div v-if="isLoaded" class="campaignPage">
    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />

    <header-component type='serviceDetail'
                      class='header--serviceDetail header--overlay header--overlay--solid'
                      :title=state.largeTitle
                      :image="state.bannerImage ? state.bannerImage : null"
                      :video="state.bannerVideo ? state.bannerVideo : null"
                      :code="state.bannerVideoCode ? state.bannerVideoCode : null"
                      :intro=state.smallTitle
                      id="header">
    </header-component>
    <main>
      <div v-if="state.CampaignForm > 0" class="campaignPage__contactCtaWrapper">
        <a class="button button--purple" id="buttonContact" @click="getInTouch">
          get in<br>touch
        </a>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 316.1 480" id="svgGroup57_0" class="svgGroup57">
          <g>
            <path name="path1" id="svgGroup57_0__path1" class="svgpath svgpath--mist" d="M313.7,2.4v158.9c0,83.1-67.3,150.4-150.4,150.4c0,0,0,0,0,0h-52.3" />
            <path name="path2" id="svgGroup57_0__path2" class="svgpath svgpath--green" d="M97.4,311.8c-25.2,0-49.4,10-67.2,27.8c-37.1,37.1-37.1,97.3,0,134.4c0,0,0,0,0,0" />
            <path name="path3" id="svgGroup57_0__path3" class="svgpath svgpath--fuschia" d="M146.1,337.2c-38.7-25.8-91-15.4-116.8,23.3c-9.2,13.8-14.1,30-14.2,46.5" />
          </g>
        </svg>
      </div>
      <div class="campaignPage__content">
        <template v-for="(item, index) in state.CampaignCustomContent">

          <!-- CIRCLES (COLOURED / WHITE LAYOUTS) -->
          <div v-if="item.circles"
               class="campaignPage__circles component-background">
            <div :style="{paddingBottom: item.circles.spacing + 'px'}">
              <div class="campaignPage__circles__intro">
                <h2 v-if="item.circles.title">{{ item.circles.title }}</h2>
                <div v-if="item.circles.text" v-html="item.circles.text"></div>
                <circles :circlesListItems="item.circles.content"
                         :layout="item.circles.layout"
                         :animation="item.circles.animation"
                         :itemIndex="index"
                         :colours="item.circles.colours">
                </circles>
              </div>
              <svg v-if="item.circles.layout == 'coloured'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 430.52 408.158" :id="'svgGroup9c_' + index" class="svgGroup9c">
                <g>
                  <path name="path3" :id="'svgGroup9c_' + index + '__path3'" class="svgpath svgpath--mist" d="M204.1,204.1C92.7,204.1,2.5,294.3,2.5,405.7" />
                  <path name="path2" :id="'svgGroup9c_' + index + '__path2'" class="svgpath svgpath--fuschia" d="M428,2.5c0.1,53.5-21.2,104.8-59,142.5c-37.8,37.9-89.1,59.1-142.5,59" />
                  <path name="path1" :id="'svgGroup9c_' + index + '__path1'" class="svgpath svgpath--green" d="M95.3,133.6c72.4,72.4,189.8,72.4,262.3,0" />
                </g>
              </svg>
            </div>
          </div>

          <!-- CTA -->
          <div v-else-if="item.cta" class="campaignPage__cta component-background">
            <div class="campaignPage__cta__col1">
              <h2 class="campaignPage__cta__title">{{ item.cta.accompanying.title }}</h2>
              <div class="campaignPage__cta__text" v-html=item.cta.accompanying.textarea>
              </div>
            </div>
            <div class="campaignPage__cta__col2">
              <a :href=item.cta[0].url target="_blank" rel="noreferrer noopener" class="outside_the_box">
                <div class="campaignPage__cta__col2__wrapper" :style="{background: state.CampaignGradient}">
                  <p v-html=item.cta[0].text class="campaignPage__cta__desc"></p>
                  <a :href=item.cta[0].url target="_blank" rel="noreferrer noopener">Click here</a>

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.2 221.1" :id="'svgGroup47_' + index" class="svgGroup47">
                    <g>
                      <path :id="'svgGroup47_' + state.index + '__path2'" class="svgpath svgpath--mist" d="M4.2,4.7c62.1-4.9,123.2,17.7,167.2,61.8" />
                      <path :id="'svgGroup47_' + state.index + '__path1'" class="svgpath svgpath--fuschia" d="M4.2,4.7C36.5,2.2,68.9,7.1,99.1,19" />
                      <path :id="'svgGroup47_' + state.index + '__path4'" class="svgpath svgpath--mist" d="M22.6,19c74.5,0,142.6,41.9,176.2,108.3c14,27.6,21.3,58.2,21.2,89.1" />
                      <path :id="'svgGroup47_' + state.index + '__path3'" class="svgpath svgpath--green" d="M42.9,20c45.1,4.6,87.3,24.7,119.3,56.8" />
                    </g>
                  </svg>
                </div>
              </a>
            </div>
          </div>

          <!-- TEXT BLOCK -->
          <div v-else-if="item.text_block" class="component-background" :ref="item.text_block.link_reference" :class="item.text_block.additional_class">
            <div :style="{paddingBottom: item.text_block.spacing + 'px'}">
              <div class="campaignPage__textBlock" v-html=item.text_block.value></div>
            </div>
          </div>

          <!-- IMAGE -->
          <div v-else-if="item.image" class="component-background">
            <div :style="{paddingBottom: item.image.spacing + 'px'}">
              <div class="campaignPage__image">
                <picture-sources v-if="item.image.value && item.image.value.sizes && item.image.value.sizes.length"
                                 :targetWidth="100"
                                 :sizes="item.image.value.sizes"
                                 :imgSrc="item.image.value.guid"
                                 :imgAlt="state.largeTitle"
                                 imgClass="objectFitImage"
                                 :imagefit="(item.image.blurred === '1') ? 'width:unset;height:unset;' : (item.image.stretch === 'width') ? 'width:unset;' : (item.image.stretch === 'height') ? 'height:unset;' : ''" />
                <svg v-if="item.image.animation && item.image.animation.includes('Animation 1') && window.innerWidth >= desktop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 232.975 733.041" :id="'svgGroup42_' + index" class="svgGroup42">
                  <g>
                    <path :id="'svgGroup42_' + index + '__path8'" class="svgpath svgpath--fuschia" d="M84.6,338.2v192.6" />
                    <path :id="'svgGroup42_' + index + '__path7'" class="svgpath svgpath--mist" d="M231.2,2.9c-97.3,18.3-171,103.7-171,206.3v312.9" />
                  </g>
                  <g>
                    <path :id="'svgGroup42_' + index + '__path1'" class="svgpath svgpath--mist" d="M231.5,2.9c-97.3,18.3-171,103.7-171,206.3V731" />
                    <path :id="'svgGroup42_' + index + '__path2'" class="svgpath svgpath--fuschia" d="M3.5,54.9c49.6,39.8,78.4,100,78.3,163.6v53.7" />
                    <path :id="'svgGroup42_' + index + '__path6'" class="svgpath svgpath--fuschia" d="M60.5,562.2v144" />
                    <path :id="'svgGroup42_' + index + '__path4'" class="svgpath svgpath--fuschia" d="M97.7,90c-24.3,35-37.2,76.7-37.2,119.3v102.8" />
                    <path :id="'svgGroup42_' + index + '__path5'" class="svgpath svgpath--fuschia" d="M84.9,368.2v256.7" />
                    <path :id="'svgGroup42_' + index + '__path3'" class="svgpath svgpath--green" d="M218.4,18c-59,18.4-106.9,61.9-131,118.8c-10.9,25.9-16.5,53.7-16.5,81.7v265.6" />
                  </g>
                </svg>
                <svg v-if="item.image.animation && item.image.animation.includes('Animation 2') && window.innerWidth >= desktop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335.7 762.3" :id="'svgGroup43_' + index" class="svgGroup43">
                  <g>
                    <path :id="'svgGroup43_' + index + '__path4'" class="svgpath svgpath--mist" d="M159.2,361.5c-85.6,0-155,69.4-155,155v241" />
                    <path :id="'svgGroup43_' + index + '__path1'" class="svgpath svgpath--mist" d="M331.2,2.5v203.8c0,85.7-69.4,155.2-155,155.2" />
                    <path :id="'svgGroup43_' + index + '__path2'" class="svgpath svgpath--green" d="M159.2,361.5c-85.6,0-155,69.4-155,155" />
                    <path :id="'svgGroup43_' + index + '__path3'" class="svgpath svgpath--fuschia" d="M58.2,415.5c55.5-55.8,145.7-56,201.4-0.6c0.2,0.2,0.4,0.4,0.6,0.6" />
                  </g>
                </svg>
                <svg v-if="item.image.animation && item.image.animation.includes('Animation 3') && window.innerWidth >= desktop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.4 1752.3" :id="'svgGroup44_' + index" class="svgGroup44">
                  <g>
                    <path :id="'svgGroup44_' + index + '__path1'" class="svgpath svgpath--mist" d="M175.4,4.3C78.1,22.6,4.5,108,4.5,210.7v587.9" />
                    <path :id="'svgGroup44_' + index + '__path5'" class="svgpath svgpath--green" d="M4.5,563.6v144" />
                    <path :id="'svgGroup44_' + index + '__path7'" class="svgpath svgpath--mist" d="M4.3,1187.7v559.8" />
                    <path :id="'svgGroup44_' + index + '__path3'" class="svgpath svgpath--fuschia" d="M41.6,91.4C17.4,126.4,4.4,168,4.5,210.7v102.8" />
                    <path :id="'svgGroup44_' + index + '__path4'" class="svgpath svgpath--fuschia" d="M15.3,518.1v338.7" />
                    <path :id="'svgGroup44_' + index + '__path6'" class="svgpath svgpath--green" d="M14.8,956.2v561" />
                    <path :id="'svgGroup44_' + index + '__path2'" class="svgpath svgpath--fuschia" d="M162.4,19.3c-59,18.4-106.9,61.9-131,118.8c-10.9,25.9-16.5,53.7-16.5,81.7v265.6" />
                    <path :id="'svgGroup44_' + index + '__path8'" class="svgpath svgpath--fuschia" d="M4.3,821.7v376.5" />
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <!-- LIST -->
          <div v-else-if="item.list"
               class="campaignPage__list component-background"
               :class="item.list.additional_class">
            <div :style="{paddingBottom: item.list.spacing + 'px'}">
              <h2>{{ item.list.introduction }}</h2>
              <ul class="outputList">
                <li v-for="(value, index) in item.list.items"
                    class="outputList__item"
                    :key="index">
                  <a v-if="!!value.link" :href="value.link" :title="value.item" :style="{color: ((index % 2) === 0) ? state.CampaignColor1 :state.CampaignColor2 }">{{ value.item }}</a>
                  <span v-else :style="{color: ((index % 2) === 0) ? state.CampaignColor1 : state.CampaignColor2 }">{{ value.item }}</span>
                </li>
              </ul>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 174 967.7" :id="'svgGroup61_' + index" class="svgGroup61" style="height:50rem">
                <g>
                  <path name="path3" :id="'svgGroup61_' + index + '__path3'" class="svgpath svgpath--mist" d="M16.5,2.5v809.9v-2.2c0,85.6,69.4,155,155,155" />
                  <path name="path1" :id="'svgGroup61_' + index + '__path1'" class="svgpath svgpath--fuschia" d="M30.5,215.2v376.5" />
                  <path name="path2" :id="'svgGroup61_' + index + '__path2'" class="svgpath svgpath--green" d="M2.5,312.9v116.8" />
                </g>
              </svg>
            </div>
          </div>

          <!-- STATISTICS -->
          <div v-else-if="item.statistics"
               class="campaignPage__stats component-background">
            <div :style="{paddingBottom: item.statistics.spacing + 'px'}">
              <h2 v-if="item.statistics.intro.title">{{ item.statistics.intro.title }}</h2>
              <div v-if="item.statistics.intro.text" class="campaignPage__stats__text" v-html="item.statistics.intro.text"></div>
              <ul class="campaignPage__stats__list"
                  :class="{'serviceDetailPage__stats__list--four': item.statistics.intro.num == '4', 'serviceDetailPage__stats__list--three': item.statistics.intro.num == '3'}">
                <li v-for="(value, index) in item.statistics.stats"
                    class="campaignPage__stats__list__item">
                  <span>{{ value.stat }}</span>
                  <span>{{ value.description }}</span>
                </li>
              </ul>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 221 217.3" :id="'svgGroup59_' + index" class="svgGroup59">
                <g>
                  <path name="path2" :id="'svgGroup59_' + index + '__path2'" class="svgpath svgpath--mist" d="M2.7,3.2c62.1-4.9,123.2,17.7,167.2,61.8" />
                  <path name="path1" :id="'svgGroup59_' + index + '__path1'" class="svgpath svgpath--fuschia" d="M2.7,3.2C35,0.7,67.4,5.5,97.5,17.4" />
                  <path name="path3" :id="'svgGroup59_' + index + '__path3'" class="svgpath svgpath--mist" d="M21.1,17.4c74.5,0,142.6,41.9,176.2,108.3c14,27.6,21.3,58.2,21.2,89.1" />
                  <path name="path4" :id="'svgGroup59_' + index + '__path4'" class="svgpath svgpath--green" d="M41.4,18.4c45.1,4.6,87.3,24.7,119.3,56.8" />
                </g>
              </svg>
            </div>
          </div>

          <!-- Gallery -->
          <div v-else-if="item.gallery"
               class="component-background">
            <div :style="{paddingBottom: item.gallery.spacing + 'px'}">
              <image-reveal v-if="item.gallery && item.gallery.type === 'reveal'"
                            :beforeImage=item.gallery.images[0].guid
                            :afterImage=item.gallery.images[1].guid
                            class="imageReveal--noInfo imageReveal--addMargin">
              </image-reveal>

              <ul v-else-if="item.gallery && item.gallery.type === 'grid'" class="campaignPage__gallery">
                <li v-for="(value, index) in item.gallery.images"
                    class="campaignPage__gallery__galleryItem"
                    :class="{'campaignPage__gallery__galleryItem--two': item.gallery.images_per_row == '2', 'campaignPage__gallery__galleryItem--three': item.gallery.images_per_row == '3'}">
                  <picture-sources v-if="value && value.sizes && value.sizes.length"
                                   :targetWidth="window.innerWidth <= mobile ? 100 : window.innerWidth <= tablet ? 80 : 25"
                                   :sizes="value.sizes"
                                   :imgSrc="value.guid"
                                   imgClass="objectFitImage" />
                </li>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.3 549.8" :id="'svgGroup45_' + index" class="svgGroup45">
                  <g>
                    <path name="path2" :id="'svgGroup45_' + index + '__path2'" class="svgpath svgpath--green" d="M17.8,72v473.5" />
                    <path name="path1" :id="'svgGroup45_' + index + '__path1'" class="svgpath svgpath--mist" d="M4.8,4v166.4" />
                    <path name="path3" :id="'svgGroup45_' + index + '__path3'" class="svgpath svgpath--fuschia" d="M4.3,111.5v81.3" />
                  </g>
                </svg>
              </ul>
            </div>
          </div>

          <!-- TEXT BOXES -->
          <div v-else-if="item.text_box"
               class="campaignPage__textbox component-background">
            <div :style="{paddingBottom: item.text_box.spacing + 'px'}">
              <ul class="campaignPage__textbox__list">
                <li v-for="(value, index) in item.itemTextBoxSpacing"
                    class="campaignPage__textbox__list__item"
                    :key="index">
                  <h3>{{ value.title }}</h3>
                  <p>{{ value.text }}</p>
                </li>
              </ul>
            </div>
          </div>

          <!-- News -->
          <div v-else-if="item.news"
               class="campaignPage__news component-background">
            <div :style="{paddingBottom: item.news.spacing + 'px;text-align:left'}">
              <h2 v-if="item.news.title" class="grey">{{ item.news.title }}</h2>
              <div id="news_list">
                <!--<img class="news-drag-icon" src="/src/assets/drag2.svg" alt="Drag Icon">-->
                <carousel id="NewsScroll"
                          ref="case-studies_slick"
                          :items-to-show="state.slickOptions3.itemsToShow"
                          :breakpoints="state.slickOptions3.breakpoints"
                          :snap-align="state.slickOptions3.snapAlign"
                          :wrap-around="state.slickOptions3.wrapAround"
                          class="slick-slide carousel carousel--case-studies">
                  <slide v-for="(item, index) in item.news" :key="index" class="carousel__slide carousel__slide--case-studies news-item">
                    <div class="news-item__wrapper" v-if="index !== 'spacing' && index !== 'title' && item.image.sizes && item.image.sizes.length">
                      <picture-sources v-if="item.image && item.image.sizes && item.image.sizes.length"
                                       :targetWidth="window.innerWidth <= mobile ? 100 : window.innerWidth <= tablet ? 50 : 33"
                                       :sizes="item.image.sizes"
                                       :imgSrc="item.image.guid"
                                       :imgAlt="item.name"
                                       imgClass="news-item__image objectFitImage" />
                    </div>
                    <div class="text-container" v-if="item.title" @mouseover="mouseOver">
                      <div class="text-container__category">
                        <h2>{{ item.title }}</h2>
                        <div v-if="typeof(item.author) == 'object'" class="text-container__category__authorImg" v-bind:style="{backgroundImage: 'url(' + item.author.image.guid + ')'}" alt=""></div>
                      </div>

                      <p v-if="item.summary">
                        {{ item.summary }}
                      </p>
                      <span>{{item.date}}</span>
                      <span :class="['campaignPage__news__read-more', item.type]" @click="goToItem(item.link, 'news-item')">Read More</span>
                    </div>
                  </slide>
                </carousel>
              </div>
            </div>
          </div>

          <!-- SERVICE TYPES -->
          <div v-else-if="item.service_types" class="component-background">
            <div :style="{paddingBottom: item.service_types.spacing + 'px'}">
              <div class="campaignPage__service-types">
                <h2 v-if="item.service_types.introduction">{{ item.service_types.introduction }}</h2>
                <ul class="campaignPage__service-types__details">
                  <li v-for="(type, index) in item.service_types.types" class="campaignPage__service-types__details__item"
                      :key="index"
                      :class="{ active: active == index }">
                    {{ type }}.&nbsp;
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- CASE STUDIES -->
          <div v-else-if="item.case_study" class="campaignPage__case-study case-study-list component-background" :class="item.case_study.additional_class">
            <div :style="{paddingBottom: item.case_study.spacing + 'px'}">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 174 967.7" :id="'svgGroup61_' + index" class="svgGroup61">
                <g>
                  <path name="path3" :id="'svgGroup61_' + index + '__path3'" class="svgpath svgpath--mist" d="M16.5,2.5v809.9v-2.2c0,85.6,69.4,155,155,155" />
                  <path name="path1" :id="'svgGroup61_' + index + '__path1'" class="svgpath svgpath--fuschia" d="M30.5,215.2v376.5" />
                  <path name="path2" :id="'svgGroup61_' + index + '__path2'" class="svgpath svgpath--green" d="M2.5,312.9v116.8" />
                </g>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.3 367.8" :id="'svgGroup62_' + index" class="svgGroup62">
                <g>
                  <path name="path3" :id="'svgGroup62_' + index + '__path3'" class="svgpath svgpath--mist" d="M97,227.9c-35.9,35.8-51.3,87.4-40.9,137" />
                  <path name="path1" :id="'svgGroup62_' + index + '__path1'" class="svgpath svgpath--fuschia" d="M108.8,216.1c28.3-28.2,44.1-66.5,44.1-106.4c0.1-39.9-15.8-78.2-44.1-106.4" />
                  <path name="path2" :id="'svgGroup62_' + index + '__path2'" class="svgpath svgpath--green" d="M140.8,109.8c0,76.4-62,138.4-138.4,138.4" />
                </g>
              </svg>
              <h2 v-if="item.case_study.title" class="grey">{{ item.case_study.title }}</h2>

              <carousel :items-to-show="state.slickOptions.itemsToShow"
                        :breakpoints="state.slickOptions.breakpoints"
                        :snap-align="state.slickOptions.snapAlign"
                        id="case-studiesScroll"
                        ref="case-studies_slick"
                        class="carousel carousel--case-studies">
                <slide v-for="work in item.case_study.case_studies" class="carousel__slide carousel__slide--case-studies">
                  <div class="carousel__slide__imgwrapper">
                    <picture-sources v-if="work[0].banner_image && work[0].banner_image.sizes && work[0].banner_image.sizes.length"
                                     :targetWidth="window.innerWidth <= mobile ? 100 : 50"
                                     :sizes="work[0].banner_image.sizes"
                                     :imgSrc="work[0].banner_image.guid"
                                     :imgAlt="work[0].content[1].summary[0].client"
                                     imgClass="objectFitImage" />

                    <div class="carousel__slide__imgwrapper__info">
                      <router-link :to="'/work/' + work[0].link">
                        <div class="carousel__slide__imgwrapper__info__wrapper">
                          <h3 :style="{color: work[0].left_col}" v-if=work[0].content[1]>
                            {{ work[0].content[1].summary[0].client }}
                          </h3>
                          <h3 :style="{color: work[0].left_col}" v-if='work[0].content[1] == null'>
                            Client Name
                          </h3>
                          <p if="work[0].content[0]">{{ work[0].content[0].banner_image_text[0].large }}</p>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>

          <!-- TABLE -->
          <div v-else-if="item.table"
               class="campaignPage__tables component-background">
            <div :style="{paddingBottom: item.table.spacing + 'px'}">
              <h2 v-if="item.table.title">
                {{ item.table.title }}
              </h2>
              <p v-if="item.table.text">{{ item.table.text }}</p>
              <div class="campaignPage__tables__wrapper">
                <div v-for="(value, index) in item.table.tables"
                     class="campaignPage__tables__table"
                     :class="{'campaignPage__tables__table--layout1': item.table.layout == 'layout1', 'campaignPage__tables__table--layout2': item.table.layout == 'layout2'}">
                  <h3>{{ value.header }}</h3>
                  <ul class="campaignPage__tables__table__list">
                    <li v-for="(value2, index) in value.items"
                        class="campaignPage__tables__table__list__listItem">
                      {{ value2 }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- TABLE & icon -->
          <div v-else-if="item.table_icon"
               class="campaignPage__table_icon component-background">
            <div :style="{paddingBottom: item.table_icon.spacing + 'px'}">
              <h2 v-if="item.table_icon.title">
                {{ item.table_icon.title }}
              </h2>
              <p v-if="item.table_icon.text">{{ item.table_icon.text }}</p>
              <div class="campaignPage__table_icon__wrapper">
                <div class="campaignPage__table_icon__table" role="table"
                     :class="{'serviceDetailPage__table_icon__table__2col': item.table_icon.layout == '2col', 'serviceDetailPage__table_icon__table__3col': state.table_icon.layout == '3col', 'serviceDetailPage__table_icon__table__4col': state.table_icon.layout == '4col'}">
                  <div v-for="(value, index) in item.table_icon.table"
                       class="campaignPage__table_icon__table__cell">
                    <picture-sources v-if="value.icon && value.icon.sizes && value.icon.sizes.length"
                                     :targetWidth="100"
                                     :sizes="value.icon.sizes"
                                     :imgSrc="value.icon.guid"
                                     :imgAlt="value.icon.title"
                                     imgClass="objectFitImage" />
                    <div v-html="value.text"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- SQUARES -->
          <div v-if="item.squares"
               class="campaignPage__squares component-background"
               :style="{backgroundImage: 'url(' + item.squares.background_image.guid + ')'}">
            <div :style="{paddingBottom: item.squares.spacing + 'px'}">
              <div v-if="item.squares.title" class="campaignPage__squares__intro">
                <h2>{{ item.squares.title }}</h2>
              </div>
              <ul :class="{ 'campaignPage__list--row' : item.squares.grid }"
                  class="campaignPage__squares__list">
                <li class="campaignPage__squares__list__listItem"
                    v-for="value in item.squares.rows"
                    :style="[value.background_colour.length > 1 ? {backgroundColor: value.background_colour} : {backgroundColor:'#ffffff'}]">
                  <h3 :style="[value.title_colour.length > 1 ? {color: value.title_colour} : {color:'#868686'}]">{{ value.title }}</h3>
                  <div class="campaignPage__list__listItem__text" v-html=value.content></div>
                  <picture-sources v-if="value.icon && value.icon.sizes && value.icon.sizes.length"
                                   :targetWidth="20"
                                   :sizes="value.icon.sizes"
                                   :imgSrc="value.icon.guid" />
                </li>
              </ul>
            </div>
          </div>

          <!-- TESTIMONIAL -->
          <div v-else-if="item.testimonial"
               class="campaignPage__testimonial"
               :class="item.testimonial.additional_class"
               :style="{ background: state.CampaignGradient }">
            <div class="campaignPage__testimonial__quotes">
              <div class="campaignPage__testimonial__quotes__wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 321 483.2" :id="'svgGroup53_' + index" class="svgGroup53">
                  <g>
                    <path :id="'svgGroup53_' + index + '__path1'" class="svgpath svgpath--mist" d="M316.1,4.9v158.9c0,83.1-67.3,150.4-150.4,150.4c0,0,0,0,0,0h-52.3" />
                    <path :id="'svgGroup53_' + index + '__path2'" class="svgpath svgpath--green" d="M99.9,314.2c-25.2,0-49.4,10-67.2,27.8c-37.1,37.1-37.1,97.3,0,134.4c0,0,0,0,0,0" />
                    <path :id="'svgGroup53_' + index + '__path3'" class="svgpath svgpath--fuschia" d="M148.5,339.6c-38.7-25.8-91-15.4-116.8,23.3c-9.2,13.8-14.1,30-14.2,46.5" />
                  </g>
                </svg>
                <div class="campaignPage__testimonial__quotes__circle"
                     :style="{ background: state.CampaignGradient }">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.5 36.7" :id="'svgGroup54_' + index" class="svgGroup54">
                    <g>
                      <path :id="'svgGroup54_' + index + '__path1'" class="svgpath svgpath--white" d="M10.6,19.9c-4.7,0-8.5-3.9-8.5-8.6c0-4.7,3.8-8.6,8.5-8.6c4.7,0,8.2,3.9,8.5,8.6
                                    C20.1,31.8,8.2,34.7,8.2,34.7v-4.9c5.2-2.2,4.5-10.1,4.5-10.1C12,19.9,11.3,19.9,10.6,19.9z" />
                      <path :id="'svgGroup54_' + index + '__path2'" class="svgpath svgpath--white" d="M33.6,19.9c-4.7,0-8.5-3.9-8.5-8.6c0-4.7,3.8-8.6,8.5-8.6c4.7,0,8.2,3.9,8.5,8.6
                                    c1.1,20.4-10.9,23.4-10.9,23.4v-4.9c5.2-2.2,4.5-10.1,4.5-10.1C35,19.9,34.3,19.9,33.6,19.9z" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>

            <div v-if="window.innerWidth < desktop" class="campaignPage__testimonial__wrapper">

              <div v-if=item.testimonial[0].image class="campaignPage__testimonial__imageWrapper">

                <picture-sources v-if="item.testimonial[0].image && item.testimonial[0].image.sizes && item.testimonial[0].image.sizes.length"
                                 :targetWidth="window.innerWidth <= mobile ? 20 : window.innerWidth <= tablet ? 20 : 80"
                                 :sizes="item.testimonial[0].image.sizes"
                                 :imgSrc="item.testimonial[0].image.guid"
                                 imgClass="objectFitImage"
                                 :imgAlt="item.testimonial[0].image.alt" />

              </div>

              <div class="campaignPage__testimonial__info">
                <h2 v-if="item.testimonial[0].hide < 1" class="campaignPage__testimonial__info__title">client testimonial</h2>
                <p class="campaignPage__testimonial__info__text">{{ item.testimonial[0].text }}</p>
                <span class="campaignPage__testimonial__info__name" v-html="item.testimonial[0].name"></span>
              </div>

            </div>

            <div v-if="window.innerWidth >= desktop" class="campaignPage__testimonial__wrapper">

              <div class="campaignPage__testimonial__info_lg">
                <h2 v-if="item.testimonial[0].hide < 1" class="workDetailPage__testimonial__info__title">client testimonial</h2>
              </div>

              <div class="campaignPage__testimonial__hack">
                <div style="width: 25%">
                  <div v-if=item.testimonial[0].image class="workDetailPage__testimonial__imageWrapper">

                    <picture-sources v-if="item.testimonial[0].image && item.testimonial[0].image.sizes && item.testimonial[0].image.sizes.length"
                                     :targetWidth="window.innerWidth <= mobile ? 20 : window.innerWidth <= tablet ? 20 : 80"
                                     :sizes="item.testimonial[0].image.sizes"
                                     :imgSrc="item.testimonial[0].image.guid"
                                     imgClass="objectFitImage"
                                     :imgAlt="item.testimonial[0].image.alt" />
                  </div>
                  <span class="campaignPage__testimonial__info__name" v-html="item.testimonial[0].name"></span>
                </div>
                <p class="campaignPage__testimonial__info__text">
                  {{ item.testimonial[0].text }}
                </p>
              </div>

            </div>

          </div>

          <!-- VIDEO -->
          <div v-if="item.video" class="component-background">
            <div :style="{paddingBottom: item.video.spacing + 'px'}">
              <div class="campaignPage__video" itemscope itemtype="https://schema.org/VideoObject">
                <div style="position:relative;overflow:hidden;padding-bottom:56.25%" :class=item.video.colour>
                  <iframe :src="'https://cdn.jwplayer.com/players/'+item.video.code+'-p3ckDi0X.html'" width="100%" height="100%" style="position:absolute;" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>

          <!-- QUOTE WALL -->
          <div class="campaignPage__socialCarousel campaignPage__quoteWall component-background"
               v-else-if="item.quote_wall">
            <div :style="{paddingBottom: item.quote_wall.spacing + 'px'}">
              <div class="carousel carousel--quotes">
                <h2 v-if="item.quote_wall.title">{{ item.quote_wall.title }}</h2>
                <div class="campaignPage__quoteWall__text"><p v-html=item.quote_wall.text></p></div>
                <div class="campaignPage__quoteWall__sliderWrapper">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 493.2 703.6" :id="'svgGroup48_' + index" class="svgGroup48">
                    <g>
                      <path :id="'svgGroup48_' + index + '__path4'" class="svgpath svgpath--mist" d="M316,231.2c-85.6,0-155,69.4-155,155c0,0,0,0,0,0v160.6" />
                      <path :id="'svgGroup48_' + index + '__path1'" class="svgpath svgpath--mist" d="M488.2,6.1v70.1c0,85.6-69.4,155-155,155" />
                      <path :id="'svgGroup48_' + index + '__path2'" class="svgpath svgpath--fuschia" d="M316,231.2c-85.6,0-155,69.4-155,155c0,0,0,0,0,0" />
                      <path :id="'svgGroup48_' + index + '__path3'" class="svgpath svgpath--green" d="M215.2,285.4c55.7-55.7,146-55.7,201.7,0" />
                      <path :id="'svgGroup48_' + index + '__path5'" class="svgpath svgpath--fuschia" d="M161.2,542.9c0.1,34-11.1,67.1-31.8,94.1c-29.3,38.4-74.9,61-123.2,60.9" />
                    </g>
                  </svg>

                  <carousel id="quoteWallScroll"
                            ref="slick"
                            :options="state.slickOptions2"
                            class="carousel carousel--case-studies">
                    <slide v-for="(value, index) in item.quote_wall.quotes"
                           class="carousel__slide carousel--quotes__slide">
                      <div class="carousel--quotes__slide__wrapper"
                           :style="[item.quote_wall.gradient ? { background: caseStudyGradient} : { backgroundColor: getQuoteIndex(index, item.quote_wall.colours)}]">
                        <div class="carousel--quotes__slide__info">
                          <h3 class="carousel--quotes__slide__info__heading">{{ value.source }}</h3>
                          <p>{{ value.quote }}</p>
                        </div>
                      </div>
                    </slide>
                  </carousel>
                </div>
                <!--<img src="/src/assets/drag-arrows-grey.svg" alt="Drag Slider Icon" class="workDetailPage__quoteWall__dragIcon">-->
              </div>
            </div>
          </div>

          <!-- PEOPLE -->
          <div v-if="item.people" class="people component-background">
            <div :style="{paddingBottom: item.people.spacing + 'px'}">
              <!--<div class="people-list__container">-->
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.7 374.2" id="svgGroup20">
                <g>
                  <path id="svgGroup20__path3" class="svgpath svgpath--mist" d="M60.3,231.5c35.9,35.8,51.3,87.4,40.9,137" />
                  <path id="svgGroup20__path1" class="svgpath svgpath--fuschia" d="M48.5,7c-58.7,58.7-58.7,154,0,212.7" />
                  <path id="svgGroup20__path2" class="svgpath svgpath--green" d="M16.5,113.3c0,76.4,62,138.4,138.4,138.4" />
                </g>
              </svg>
              <h2 v-if="item.people.title">{{ item.people.title }}</h2>
              <!--<img class="people-drag-icon" src="/src/assets/drag2.svg" alt="Drag Icon">-->
              <carousel id="peopleScroll" ref="people_slick"
                        :items-to-show="state.slickOptions3.itemsToShow"
                        :breakpoints="state.slickOptions3.breakpoints"
                        :snap-align="state.slickOptions3.snapAlign"
                        :wrap-around="state.slickOptions3.wrapAround"
                        class="carousel carousel--people">
                <slide v-for="item in item.people.people" class="carousel__slide carousel__slide--people">
                  <div class="carousel__slide__info">
                    <div class="carousel__slide__info__wrapper">
                      <h3>{{ item.name }}</h3>
                      <span>{{ item.job_title }}</span>
                    </div>
                  </div>
                  <div class="carousel__slide__imgwrapper">
                    <picture-sources v-if="item && item.image.sizes && item.image.sizes.length"
                                     :targetWidth="window.innerWidth <= mobile ? 100 : 50"
                                     :sizes="item.image.sizes"
                                     :imgSrc="item.image.guid"
                                     imgAlt="item.name "
                                     imgClass="objectFitHeaderImage" />
                    <router-link v-if="item.key == 1" :to="'/people/' + item.link" class="button button--white">more</router-link>
                    <!-- <a :href="'/people#' + item.link" class="button button--white">meet</a> -->
                  </div>
                </slide>
              </carousel>

              <a class="button button--purple" id="buttonPeople"
                 @click="goToItem(state.jsonData.site_details.menu_items.header[4].menu_item.toLowerCase(), 'people-page')">
                <span>more key people</span>
              </a>
            </div>
          </div>
        </template>
        <!-- CONTACT FORM -->
        <div v-if="state.CampaignForm > 0" class="campaignPage__contactForm component-background" id="contactForm" ref="anchor">

          <div v-if="!state.sentModal" class="campaignPage__contactForm-content">

            <div id="hubspotForm" v-once></div>
          </div>

          <div v-if="state.sentModal" class="campaignPage__contactForm-content">
            <h2>
              Thank you
            </h2>
            <p id="campaignPage-contact-sent">
              Your enquiry has been submitted. We will contact you shortly.
            </p>
          </div>

          <div v-if="state.notSentModal" class="campaignPage__contactForm-content">
            <h2>
              Error
            </h2>
            <p id="campaignPage-contact-not-sent">
              There was a problem sending your Enquiry. Please try again later.
            </p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
  import { Carousel, Slide } from 'vue3-carousel';
  import EventBus from '../eventbus.js';
  import HeaderComponent from './widgets/header-component';
  import ImageReveal from './widgets/image-reveal';
  import WorkDetails from './widgets/work-details';
  import VideoComponent from './widgets/video-component';
  import Slider from './widgets/slider';
  import PictureSources from './widgets/picture-sources';
  import ModalComponent from './widgets/modal-component';
  import { _toggleSpinner, api } from '../utils/api-gateway';
  import Circles from './widgets/circles';
  import InputBox from './widgets/form-elements/input-box';
  import VueScrollTo from "vue-scrollto";
  import LoadingSpinner from './widgets/loading-spinner';
  import { TweenMax, Elastic, Bounce } from 'gsap';
  import SlideEvent from 'slide-event';
  import HeaderBar from './widgets/header-bar';
  import { computed, nextTick, onBeforeMount, onMounted, onUpdated, reactive, ref } from "vue";
  import 'vue3-carousel/dist/carousel.css';
  import objectFitImages from "object-fit-images";
  import { useRoute, useRouter } from "vue-router";
  import MyPluginCPS from '../plugins/MyPluginCPS.js';
  import { useInjects } from '../utils/globals-inject';
  export default {
    name: 'CampaignPage',
    components: {
      HeaderBar,
      HeaderComponent,
      ModalComponent,
      VideoComponent,
      ImageReveal,
      WorkDetails,
      Slider,
      Carousel,
      Slide,
      PictureSources,
      Bounce,
      Circles,
      InputBox,
      LoadingSpinner,
      SlideEvent,
    },
    setup() {
      const router = useRouter();
      let { window, desktop, mobile, tablet, mouseX, mouseY, backend, heddleID, isIE } = useInjects();
      let { goToItem, svgAnimate } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);

      const state = reactive({
        jsonData: [],
        campaignVideo: null,
        campaignVideo3: null,
        campaignVideo6: null,
        campaignVideo9: null,
        campaignVideo12: null,
        campaignVideo15: null,
        campaignVideo18: null,
        campaignVideo21: null,
        campaignVideo24: null,
        CampaignData: [],
        CampaignContent: [],
        CampaignCustomContent: [],
        CampaignServices: [],
        CampaignServicesString: null,
        CampaignForm: null,
        CampaignFormID: null,
        contactUrl: "",
        id: null,
        slug: null,
        url: null,
        largeTitle: null,
        smallTitle: null,
        bannerImage: null,
        bannerVideo: null,
        bannerVideoCode: null,
        CampaignColor1: '',
        CampaignColor2: '',
        CampaignGradient: '',
        videoID: 'SuA8whSg',
        preview_num: '',
        preview_title: '',
        sentModal: false,
        first_name: '',
        last_name: '',
        company: '',
        email: '',
        type: '',
        enquiry: '',
        phone: '',
        job_title: '',
        ajaxRequest: false,
        notSentModal: false,
        loggedModal: false,
        personBio: null,
        bioOrderNumber: null,
        firstName: '',
        lastName: '',
        selected: false,
        name: null,
        isActive: true,
        legal: null,
        heddleID: null,
        hash: false,
        slickOptions: { /* Case Studies */
          itemsToShow: 1,
          snapAlign: 'start',
          breakpoints: {
            0: {
              itemsToShow: 1,
              snapAlign: 'center'
            },
            600: {
              itemsToShow: 2,
            },
            982: {
              itemsToShow: 2
            }
          }
        },
        slickOptions2: /** Quote Wall **/ {
          itemsToShow: 4,
          snapAlign: 'start',
          breakpoints: {
            0: {
              itemsToShow: 2
            },
            981: {
              itemsToShow: 2,
            },
            1200: {
              itemsToShow: 3
            },
            1201: {
              itemsToShow: 4
            }
          }
        },
        slickOptions3: { /* PEOPLE & NEWS */
          // Options can be used from the plugin documentation
          itemsToShow: 2,
          wrapAround: false,
          snapAlign: 'start',
          breakpoints: {
            0: {
              itemsToShow: 1,
              snapAlign: 'center'
            },
            600: {
              itemsToShow: 2,
              snapAlign: 'center'
            },
            982: {
              itemsToShow: 2
            }
          }
        },
        active: null,
        blJWPlayer: false
      });

      const isLoaded = computed(() => {
        if (state.jsonData.hasOwnProperty('content')) {
          state.CampaignData = state.jsonData;
          state.CampaignContent = state.CampaignData.content;
          state.CampaignCustomContent = state.CampaignContent.content
          state.CampaignServices = state.CampaignContent.content.services;
          state.largeTitle = state.CampaignContent.large;
          state.smallTitle = state.CampaignContent.small;

          state.bannerImage = (state.CampaignContent.banner_image !== false) ? state.CampaignContent.banner_image : false;
          state.bannerVideo = (state.CampaignContent.banner_video !== false) ? state.CampaignContent.banner_video : false;
          state.bannerVideoCode = (state.CampaignContent.banner_video_code !== false) ? state.CampaignContent.banner_video_code : false;
          return true;
        }
        return false;
      });

      const getInTouch = (event) => {
        event.preventDefault();
        if(document.querySelector('#contactForm')){
           document.querySelector('#contactForm').scrollIntoView({
             behavior: "smooth"
           })
        }
      };

      const oldMounted = () => {

        let objectFitImages = require('object-fit-images');

        // Object-fit fix for IE
        objectFitImages('img.objectFitImage');

        state.CampaignData = state.jsonData;
        state.CampaignContent = state.CampaignData.content;

        let index = 0;
        for (var prop in state.CampaignContent.content) {
          if (typeof state.CampaignContent.content[prop].video !== "undefined") {
            if (index === 0) {
              state.campaignVideo = jwplayer("campaignVideo1");
            } else {
              state.campaignVideo = jwplayer("campaignVideo" + (index * 3));
            }
            if (typeof state.campaignVideo.setup === 'function') {
              state.campaignVideo.setup({
                //file: this.jsonData.content.showreel,
                id: "campaignVid" + index,
                width: "100%",
                aspectratio: "16:9",
                file: "https://cdn.jwplayer.com/manifests/" + state.CampaignContent.content[prop].video.code + ".m3u8",
                image: "https://content.jwplatform.com/thumbs/" + state.CampaignContent.content[prop].video.code + ".jpg",
                customProps: { skin: { url: './css/player.css', name: 'svp' }, controls: true }
              });
            }
            index++;
          };

        }




        state.CampaignCustomContent = state.CampaignContent.content
        state.CampaignServices = state.CampaignContent.content.services;
        state.largeTitle = state.CampaignContent.large;
        state.smallTitle = state.CampaignContent.small;

        state.bannerImage = (state.CampaignContent.banner_image !== false) ? state.CampaignContent.banner_image : false;
        state.bannerVideo = (state.CampaignContent.banner_video !== false) ? state.CampaignContent.banner_video : false;
        state.bannerVideoCode = (state.CampaignContent.banner_video_code !== false) ? state.CampaignContent.banner_video_code : false;

        // Contact Form
        state.CampaignForm = state.CampaignContent.form;
        state.CampaignFormID = state.CampaignContent.form_id;

        // Legal blurb for form
        state.legal = state.jsonData.site_details.form;

        nextTick().then(() => {
          slickStuff();
          if (state.hash)
            scrollToAnchor(state.hash.slice(1));
        });

        state.CampaignColor1 = state.CampaignContent.right_col;
        state.CampaignColor2 = state.CampaignContent.left_col;
        state.CampaignGradient = 'linear-gradient(-143deg, ' + state.CampaignColor1 + ' 4%,' + state.CampaignColor2 + ' 90%)';

        if (parseInt(state.CampaignForm) > 0) {
          const script = document.createElement("script");
          script.src = "//js.hsforms.net/forms/v2.js";
          document.body.appendChild(script);
          script.addEventListener("load", () => {
            if (window.hbspt) {
              window.hbspt.forms.create({
                region: "na1",
                portalId: "1968984",
                formId: state.CampaignFormID,
                target: "#hubspotForm",
                cssClass: 'cps_form',
                width: '100%'
              })
            }
          });
        }
      };

      const getServices = () => {
        let CampaignServices = '';
        for (let i = 0; i < state.CampaignServices.length; i++) {
          CampaignServices += state.CampaignServices[i].service;

          if (i !== state.CampaignServices.length - 1) {
            CampaignServices += ', ';
          }
        }
        return CampaignServices;
      };

      const getQuoteIndex = (quoteIndex, colours) => {
        let bgColour = '';

        for (let i = 0; i < colours.length; i++) {
          if (quoteIndex < colours.length) {
            bgColour = colours[quoteIndex];
          } else {
            bgColour = colours[quoteIndex % colours.length];
          }
        }

        return bgColour;
      };

      const openFullScreen = (videoID) => {
        let elem = document.getElementById(videoID);

        // Set iframe src if unset
        var iframe = document.getElementById("testingVideoIframe");
        if (iframe.hasAttribute('data-src')) {
          iframe.setAttribute('src', iframe.getAttribute('data-src'));
          iframe.removeAttribute('data-src');
        }

        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
          elem.msRequestFullscreen();
        }

        /* Unset iframe src to stop audio playing when exiting fullscreen. */
        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);

        function exitHandler() {
          if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            var iframe = document.getElementById("testingVideoIframe");
            iframe.setAttribute('data-src', iframe.getAttribute('src'));
            iframe.setAttribute('src', '');
          }
        }

      };

      const mouseOver = (index) => {
        if (window.innerWidth >= state.desktop) {
          state.active = index
        }
      };

      const mouseOut = () => {
        if (window.innerWidth >= state.desktop) {
          state.active = null
        }
      };

      const submitForm = () => {
        let data = new FormData(document.getElementById('sd_form'));
        _toggleSpinner(true);
        document.getElementById('loading-spinner').classList.add('show');
        state.ajaxRequest = true;
        this.$http.post(backend + 'services/enquiry/', data, { headers: { "content-type": "text/plain; charset=UTF-8" } })
          .then(response => {
            //document.getElementById('loading-spinner').classList.remove('show');
            state.postResults = response.data;
            state.ajaxRequest = false;
            _toggleSpinner(false);
            if (state.postResults.status === 200) {
              state.loggedModal = true;
              state.sentModal = true;
              VueScrollTo.scrollTo(document.getElementById("campaignPage__contactForm"));
            } else if (state.postResults.status !== 200) {
              state.notSentModal = true;
            } else {
              state.sentModal = true;
              VueScrollTo.scrollTo(document.getElementById("campaignPage__contactForm"));
            }
          }).catch((err) => {
            state.notSentModal = true;
            _toggleSpinner(false);
          })
      };

      const toggleClass = (e) => {
        state.isActive = !state.isActive;
      };

      const revealBio = (person, index) => {

        if (person) {

          state.personBio = person;
          state.selected = true;
          state.firstName = person.name.split(' ').slice(0, -1).join(' ');
          let columnRowNo = 3;
          if (window.innerWidth >= desktop) {
            columnRowNo = 3;
          } else if (window.innerWidth <= 1200 && window.innerWidth > mobile) {
            columnRowNo = 2;
          } else if (window.innerWidth <= mobile) {
            columnRowNo = 1;
          }
          // Give time for page to re-render before scrolling to new profile
          setTimeout(function () {
            let element = document.getElementById(person.link);
            element.scrollIntoView({
              behavior: 'smooth'
            });
          }, 100);
          state.bioOrderNumber = Math.ceil(index / columnRowNo) * columnRowNo;

          if (window.innerWidth >= desktop && !isIE) {
            let svgPathsPeopleTwo = [
              {
                id: 'svgGroup17__path1__' + person.name,
                tweenId: '2',
                speed: 2,
                delay: 0,
                trigger: ".people-list",
                offset: '0'
              },
              {
                id: 'svgGroup17__path2__' + person.name,
                tweenId: '2',
                speed: 2,
                delay: 0,
                trigger: ".people-list",
                offset: '0'
              },
              {
                id: 'svgGroup17__path3__' + person.name,
                tweenId: '2',
                speed: 2,
                delay: 0,
                trigger: ".people-list",
                offset: '0'
              },
              {
                id: 'svgGroup17__path4__' + person.name,
                tweenId: '2',
                speed: 2,
                delay: 0,
                trigger: ".people-list",
                offset: '0'
              },
              {
                id: 'svgGroup17__path5__' + person.name,
                tweenId: '2',
                speed: 2,
                delay: 0,
                trigger: ".people-list",
                offset: '0'
              },
              {
                id: 'svgGroup17__path6__' + person.name,
                tweenId: '2',
                speed: 2,
                delay: 0,
                trigger: ".people-list",
                offset: '0'
              },
            ]

            let svgTweensPeople = {};

            // Loop through SVG PATHS array / build tweens and scenes
            svgAnimate(svgPathsPeopleTwo, svgTweensPeople);

            renderSvgAnimationsAfterBioPopup();
          }
        }
      };

      const renderSvgAnimationsAfterBioPopup = () => {
        setTimeout(function () {
          let svgPathsPeople = [
            {
              id: 'svgGroup18__path1',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__container__images",
              offset: '0'
            },
            {
              id: 'svgGroup18__path2',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__container__images",
              offset: '0'
            },
            {
              id: 'svgGroup18__path3',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__container__images",
              offset: '0'
            },
            /*{
              id: 'svgGroup19__path1',
              tweenId: '4',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__container__images",
              offset: '0'
            },
            {
              id: 'svgGroup19__path2',
              tweenId: '4',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__container__images",
              offset: '0'
            },*/
          ]

          let svgTweensPeople = {};

          // Loop through SVG PATHS array / build tweens and scenes
          svgAnimate(svgPathsPeople, svgTweensPeople);
        })

      };

      const dismissBio = () => {
        state.personBio = null;
        state.bioOrderNumber = null
      };

      const checkBioIsOpen = (person) => {
        if (state.personBio === null) {
          return true;
        } else if (state.personBio.name === person.name) {
          return false;
        } else if (state.personBio.name !== person.name) {
          return true;
        }
      };

      const activeHover = (index) => {
      };

      const onLongPressStart = (index) => {
        if (window.innerWidth < desktop) {
          state.active = index
        }
      };

      const onLongPressStop = () => {
        if (window.innerWidth < desktop) {
          state.active = null
        }
      };

      const next = () => {
        //this.$refs.slick.next();
      };

      const prev = () => {
        //this.$refs.slick.prev();
      };

      const reInit = () => {
        // Helpful if you have to deal with v-for to update dynamic lists
        //this.$refs.slick.reSlick();
      };

      const handleSwipe = (event, slick, direction) => {
      };

      const initAnimate = () => {
        // Check if the element exists
        if (window.innerWidth >= desktop && !isIE) {

          let svgPathsWork = [];

          if (document.getElementsByClassName("svgGroup42")) {
            document.querySelectorAll(".svgGroup42").forEach((ele) => {
              // Do stuff here
              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path2',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path3',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path4',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path5',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-300'
                },
                {
                  id: index + '__path6',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path7',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '-300'
                },
                {
                  id: index + '__path8',
                  tweenId: '1' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
              )
            });
          }

          if (document.getElementsByClassName("svgGroup43")) {
            document.querySelectorAll(".svgGroup43").forEach((ele) => {
              // Do stuff here
              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '2' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path2',
                  tweenId: '2' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path3',
                  tweenId: '2' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path4',
                  tweenId: '2' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
              )
            });
          }

          if (document.getElementsByClassName("svgGroup44")) {
            document.querySelectorAll(".svgGroup44").forEach((ele) => {
              // Do stuff here
              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '3' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path2',
                  tweenId: '3' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path3',
                  tweenId: '3' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path4',
                  tweenId: '3' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path5',
                  tweenId: '3' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path6',
                  tweenId: '3' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path7',
                  tweenId: '3' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path8',
                  tweenId: '3' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                }
              )
            });
          }

          if (document.getElementsByClassName("svgGroup45")) {
            document.querySelectorAll(".svgGroup45").forEach((ele) => {
              // Do stuff here
              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '4' + index,
                  speed: 2,
                  delay: 0,
                  trigger: '#' + index,
                  offset: '0'
                },
                {
                  id: index + '__path2',
                  tweenId: '4' + index,
                  speed: 2,
                  delay: 0,
                  trigger: '#' + index,
                  offset: '0'
                },
                {
                  id: index + '__path3',
                  tweenId: '4' + index,
                  speed: 2,
                  delay: 0,
                  trigger: '#' + index,
                  offset: '0'
                },
              )
            });

          }

          if (document.getElementsByClassName("svgGroup46")) {
            document.querySelectorAll(".svgGroup46").forEach((ele) => {
              // Do stuff here
              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path2',
                  tweenId: '5' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path3',
                  tweenId: '5' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                }
              )
            });
          }

          if (document.getElementsByClassName("svgGroup47")) {
            document.querySelectorAll(".svgGroup47").forEach((ele) => {
              // Do stuff here
              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '6' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path2',
                  tweenId: '6' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path3',
                  tweenId: '6' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path4',
                  tweenId: '6' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                }
              )
            });
          }

          if (document.getElementsByClassName("svgGroup48")) {
            document.querySelectorAll(".svgGroup48").forEach((ele) => {
              // Do stuff here
              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '7' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path2',
                  tweenId: '7' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path3',
                  tweenId: '7' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path4',
                  tweenId: '7' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path5',
                  tweenId: '7' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                }
              )
            });
          }

          if (document.getElementById("svgGroup49")) {
            svgPathsWork.push(
              {
                id: 'svgGroup49__path1',
                tweenId: '8' + index,
                speed: 2,
                delay: 0,
                trigger: "#svgGroup49",
                offset: '0'
              },
              {
                id: 'svgGroup49__path2',
                tweenId: '8' + index,
                speed: 2,
                delay: 0,
                trigger: "#svgGroup49",
                offset: '0'
              },
              {
                id: 'svgGroup49__path3',
                tweenId: '8' + index,
                speed: 2,
                delay: 0,
                trigger: "#svgGroup49",
                offset: '0'
              }
            )
          }

          if (document.getElementsByClassName("svgGroup53")) {
            document.querySelectorAll(".svgGroup53").forEach((ele) => {
              // Do stuff here
              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '9' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path2',
                  tweenId: '9' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path3',
                  tweenId: '9' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                }
              )
            });
          }

          if (document.getElementsByClassName("svgGroup54")) {
            document.querySelectorAll(".svgGroup54").forEach((ele) => {
              let index = ele.id;

              svgPathsWork.push(
                {
                  id: index + '__path1',
                  tweenId: '10' + index,
                  speed: 1.5,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '0'
                },
                {
                  id: index + '__path2',
                  tweenId: '10' + index,
                  speed: 1.5,
                  delay: 0.5,
                  trigger: "#" + index,
                  offset: '0'
                }
              )
            });
          }

          if (document.getElementsByClassName("svgGroup55")) {
            document.querySelectorAll(".svgGroup55").forEach((ele) => {

              let index = ele.id;

              svgPathsWork.push(
                // {
                //     id: 'svgGroup55__path1',
                //     tweenId: '11',
                //     speed: 2,
                //     delay: 0,
                //     trigger: "#svgGroup55",
                //     offset: '0'
                // },
                // {
                //     id: 'svgGroup55__path2',
                //     tweenId: '11',
                //     speed: 2,
                //     delay: 0,
                //     trigger: "#svgGroup55",
                //     offset: '0'
                // },
                {
                  id: index + '__path3',
                  tweenId: '11' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '100'
                },
                {
                  id: index + '__path4',
                  tweenId: '11' + index,
                  speed: 2,
                  delay: 0,
                  trigger: "#" + index,
                  offset: '100'
                }
              )
            });
          }

          let svgTweensWork = {};

          // Loop through SVG PATHS array / build tweens and scenes
          svgAnimate(svgPathsWork, svgTweensWork);
        }
      };

      const slickStuff = () => {

        function getTransformValues(transform) {
          let results = transform.match(/translate3d\(\s*([^ ,]+)\s*,\s*([^ ,]+)\s*,\s*([^ )]+)\s*\)/)

          if (!results) return [0, 0, 0];

          return [
            parseInt(results[1].replace('px', ''), 10),
            parseInt(results[2].replace('px', ''), 10),
            parseInt(results[3].replace('px', ''), 10),
          ];
        }
        let matches = document.querySelectorAll('.slick-list');

        if (matches.length) {
          matches.forEach(function (slickList) {

            slickList.onwheel = function (e) {

              if ((e.deltaY == 0 || e.deltaY == -0) && e.deltaX != 0 && e.deltaX != -0) {

                let slickTrack = slickList.querySelector('.slick-track');

                let transform = slickTrack.style.transform;

                let transform3d = getTransformValues(transform);

                let newX = transform3d[0] - e.deltaX;

                let limit = (slickTrack.offsetWidth - slickList.offsetWidth) * -1;

                if (newX < 0 && newX > limit) {
                  slickTrack.style.transform = 'translate3d(' + newX + 'px,' + transform3d[1] + 'px,' + transform3d[2] + 'px)';
                }

              }

            };

          });
        }
      };

      const scrollToAnchor = () => {
        const anchor = ref(null);
        //anchor.scrollIntoView({ behavior: 'smooth' });
      };

      const setElementPositions = (arrElements) => {
        for (let intElm = 0; arrElements.length >= intElm; intElm++) {
          let campaignElm = arrElements[intElm];
          if (campaignElm !== undefined) {
            const elmPositioning = campaignElm.getBoundingClientRect();
            campaignElm.classList.add('animationTriggerPoint');
            campaignElm.setAttribute('data-page-position', elmPositioning.top);
            if (window.innerWidth <= 767) {
              if (intElm === 0) {
                campaignElm.classList.add('active');
              }
            }
          }
        }
      };

      const scrollAnimationTrigger = () => {
        let intWindowTop = window.scrollY;
        let intWindowHeight = window.outerHeight;

        document.querySelectorAll('.animationTriggerPoint').forEach(function (elm, index) {
          let elmTopPositioning = elm.getAttribute('data-page-position');
          if (intWindowTop >= (elmTopPositioning - ((intWindowHeight / 2) + (intWindowHeight / 2)))) {
            elm.classList.add('active');
          } else {
            elm.classList.remove('active');
          }
        });
      }

      onBeforeMount(() => {

        let url;
        const route = useRoute();

        if (route.hash) {
          state.hash = route.hash;
        }

        if (route.params.num) {
          state.preview_num = route.params.num;
          state.preview_title = route.params.title;
          url = backend + 'preview/campaign/' + state.preview_num + '/' + state.preview_title;
        } else {
          state.slug = route.params.link;
          url = backend + 'campaign/' + state.slug;
        }
        state.contactUrl = "/campaign/" + state.slug + "/#contactForm";
        api(url)
          .then((data) => {
            state.jsonData = data.data;
            state.url = window.location.href;
            state.heddleID = heddleID;

            nextTick().then(() => {
              oldMounted();
              EventBus.$emit('isLoaded', true);
              EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
              EventBus.$emit('footer', state.jsonData.site_details.tceg);
              EventBus.$emit('statement', state.jsonData.site_details.footer_statement);
            });
          }).catch(error => {
            state.loading = false;
          });
      });

      onMounted((...args) => {
        if (state.bannerVideoCode !== null) {
          const script = document.createElement('script');
          script.src = 'https://cdn.jwplayer.com/players/dtDL8nZV-bp9Ov19Y.js';
          document.head.appendChild(script);
        }

      })

      onUpdated(() => {

        // custom animation triggers on immersive learning page
        if (document.location.pathname.includes('immersive')) {
          // get all parent elements and pass to function to add classes and set top position
          var campaignElms = document.querySelector('.campaignPage__content').children;
          setElementPositions(campaignElms);

          setTimeout(function () {
            setElementPositions(campaignElms);
          }.bind(this), 500);

          // set elements position on resize also
          window.removeEventListener('resize', setElementPositions);
          window.addEventListener('resize', setElementPositions(campaignElms));

          // trigger scroll event listeners
          window.removeEventListener("scroll", scrollAnimationTrigger);
          window.addEventListener("scroll", scrollAnimationTrigger);

          // move the testimonial name to another div
          document.querySelectorAll('.campaignPage__testimonial__info__name').forEach(function (elm) {
            if (elm) {
              if (!elm.parentNode.parentNode.querySelector('.campaignPage__testimonial__info__text .campaignPage__testimonial__info__name')) {
                elm.parentNode.parentNode.querySelector('.campaignPage__testimonial__info__text').append(elm);
              }
            }
          });
        }
        initAnimate();
      });

      return {
        state,
        isLoaded,
        getServices,
        getQuoteIndex,
        openFullScreen,
        mouseOver,
        mouseOut,
        submitForm,
        toggleClass,
        revealBio,
        renderSvgAnimationsAfterBioPopup,
        dismissBio,
        checkBioIsOpen,
        activeHover,
        onLongPressStart,
        onLongPressStop,
        next,
        prev,
        reInit,
        handleSwipe,
        initAnimate,
        slickStuff,
        scrollToAnchor,
        setElementPositions,
        scrollAnimationTrigger,
        window,
        tablet,
        mobile,
        desktop,
        goToItem,
        getInTouch
      };
    }
  }</script>
