<template>
  <div v-if="state.isLoaded" id="news_list">

    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />

    <header-component type='newsList'
                      class="header--news"
                      :title=state.jsonData.content.page_title
                      :image=state.jsonData.content.banner_image>
    </header-component>
    <main>
      <div class="masonry" id="news_list">
        <div class="masonry-column-1">

        </div>
        <div class="masonry-column-2">

        </div>
        <div class="masonry-column-3">

        </div>
        <div v-for="(item, index) in state.newsItems" :key="index" class="news-item" @click="goToItem(item.link, item.itemLink)">
          <div class="news-item__wrapper" v-if="item.image.sizes && item.image.sizes.length">
            <picture-sources v-if="item.image && item.image.sizes && item.image.sizes.length"
                             :targetWidth="window.innerWidth <= mobile ? 100 : window.innerWidth <= tablet ? 50 : 33"
                             :sizes="item.image.sizes"
                             :imgSrc="item.image.guid"
                             :imgAlt="item.image.alt"
                             imgClass="news-item__image objectFitImage" />
          </div>
          <div class="text-container" v-if="item.title" @mouseover="mouseOver">
            <div class="text-container__category">
              <label v-if="item.type !== 'Campaign'" :class="item.type">{{item.type}}</label>
              <div v-if="typeof(item.author) == 'object'" class="text-container__category__authorImg" v-bind:style="{backgroundImage: 'url(' + item.author.image.guid + ')'}" alt=""></div>
            </div>
            <h2>{{ item.title }}</h2>
            <p v-if="item.summary">
              {{ item.summary }}
            </p>
            <span>{{item.date}}</span>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
  const emit = defineEmits([
    'isLoaded',
    'menu_items',
    'footer',
    'statement'
  ])
  import EventBus from '../eventbus.js';
  import { api } from '../utils/api-gateway';
  import { useRouter } from "vue-router";
  import { computed, nextTick, onUpdated, reactive } from "vue";
  import { useInjects } from '../utils/globals-inject';
  import objectFitImages from "object-fit-images";
  import HeaderComponent from './widgets/header-component';
  import HeaderBar from './widgets/header-bar';
  import PictureSources from './widgets/picture-sources';
  import MyPluginCPS from '../plugins/MyPluginCPS.js';

  const router = useRouter();
  let { window, tablet, mobile, desktop, mouseX, mouseY } = useInjects();
  const { goToItem } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);
  const { backend } = useInjects();

  const state = reactive({
    jsonData: [],
    newsItems: [],
    blReOrdered: false,
    isLoaded: false
  });

  const isLoaded = computed(() => {
    if (state.jsonData.hasOwnProperty('content')) {
      return true;
    }
    return false;
  });

  const oldMounted = () => {
    let objectFitImages = require('object-fit-images');

    // Object-fit fix for IE
    objectFitImages('img.objectFitImage');

    state.isLoaded = true;
  };

  const mouseOver = (event) => {

  };
  const mouseLeave = () => {

  };

  api(backend + 'page/news/')
    .then((data) => {
      state.jsonData = data.data;
      state.newsItems = state.jsonData.content.articles;

      EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
      // EventBus.$emit('header_menu_items', this.jsonData.site_details.menu_items.header);
      // EventBus.$emit('footer_menu_items', this.jsonData.site_details.menu_items.footer);
      EventBus.$emit('isLoaded', true);
      EventBus.$emit('footer', state.jsonData.site_details.tceg);
      EventBus.$emit('isPurple', true);
      EventBus.$emit('statement', state.jsonData.site_details.footer_statement);

      nextTick().then(() => {
        oldMounted();
      });
    }).catch(error => {
      state.loading = false;
    });

  onUpdated(() => {
    if (!state.blReOrdered) {
      const elmNewItems = document.querySelectorAll('.masonry .news-item');
      // Calculate the number of columns
      var numColumns = 3;
      if (window.innerWidth <= 1024) {
        numColumns = 2;
      }

      // Create an array to hold items for each column
      const columns = new Array(numColumns).fill([]).map(() => []);

      // Iterate through the items and distribute them across columns
      for (let intNewsItem = 0; intNewsItem < elmNewItems.length; intNewsItem++) {
        const columnIndex = intNewsItem % numColumns;
        columns[columnIndex].push(elmNewItems[intNewsItem]);
      }
      columns.forEach((columnItems, index) => {
        columnItems.forEach((item, itemIndex) => {
          item.setAttribute('data-index', itemIndex);
          switch (index) {
            case 0:
              document.querySelector('.masonry-column-1').appendChild(item);
              break;
            case 1:
              document.querySelector('.masonry-column-2').appendChild(item);
              break;
            case 2:
              document.querySelector('.masonry-column-3').appendChild(item);
          }
        });
      });
      state.blReOrdered = true;
    }
  });

</script>
