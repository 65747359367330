
import { createWebHistory, createRouter } from "vue-router";
import { routes } from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
     if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
});

router.beforeEach((to,from) => {
  //console.log("to",to);
  //console.log("from",from);
});

export default router;
