<template>
  <div v-if="isLoaded">

    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />

    <header class="header contact-header">
      <div class="header__wrapper">
      </div>
    </header>
    <main>
      <section class="contact__maps">
        <div class="contact__maps__filter-container">
          <div class="contact__maps__title">
            <h1>Finding us...</h1>
          </div>
          <ul ref="locationSelector" class="contact__maps__filter-regions">
            <li v-for="(item, i) in state.offices" v-bind:class="{selectedRegion: state.selectedOffice == item, contact__maps__slide__info:true}">
              <a @click="selectLocation(i)">{{ item.location }}</a>
            </li>
          </ul>
          <img v-bind:style="{left:state.indicatorPos+'px'}" class="region-indicator" src="../assets/contact-line.svg" alt="">
        </div>

        <div class="contact__maps__map-container">
          <div class="contact__maps__contact-card" @click="gotoGoogleDirections()">
            <!--<span class="contact__maps__contact-card__city">{{state.selectedOffice.city}}</span>
            <h2>{{state.selectedOffice.name}}</h2>-->
            <span class="contact__maps__contact-card__address">
              {{state.selectedOffice.address_1}},&nbsp;{{state.selectedOffice.address_2}}<br />
              {{state.selectedOffice.city}}, {{state.selectedOffice.postcode}}, {{state.selectedOffice.country}}
            </span>
            <span v-if="state.selectedOffice.phone" class="contact__maps__contact-card__address">
              <br><span class="hide_mobile">Tel: {{state.selectedOffice.phone}}</span>
              <span class="hide_desktop">Tel: <a href=":tel:state.selectedOffice.phone">{{state.selectedOffice.phone}}</a></span>
            </span>
          </div>
          <div class="contact__maps__map">
            <a :href="'https://maps.google.com/?q='+state.selectedOffice.latitude+','+state.selectedOffice.longitude" target="_blank">
              <img class="show_desktop" :src="state.selectedOffice.map_desktop" :alt="state.selectedOffice.city" />
              <img class="show_tablet" :src="state.selectedOffice.map_tablet" :alt="state.selectedOffice.city" />
              <img class="show_mobile" :src="state.selectedOffice.map_mobile" :alt="state.selectedOffice.city" />
            </a>
          </div>
        </div>
      </section>
    </main>
  </div>

</template>
<script>
  import EventBus from '../eventbus.js';
  import { api } from '../utils/api-gateway';
  import PictureSources from './widgets/picture-sources';
  import HeaderBar from './widgets/header-bar';
  import {computed, onUnmounted, onMounted, reactive, ref, watchEffect} from "vue";
  import { useRoute } from "vue-router";
  import { useInjects } from '../utils/globals-inject';
  export default {
    name: 'ContactPage',
    components: {
      HeaderBar,
      PictureSources
    },
    setup() {
      let { window, backend } = useInjects();
      const locationSelector = ref(null);

      const state = reactive({
        jsonData: [],
        offices: [],
        selectedOffice: null,
        selectedOfficeIndex: 0,
        indicatorPos: 0,
        mapStyle: [
          {
            "featureType": "administrative",
            "elementType": "all",
            "stylers": [
              {
                "saturation": "-100"
              }
            ]
          },
          {
            "featureType": "administrative.province",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
              {
                "saturation": -100
              },
              {
                "lightness": 65
              },
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
              {
                "saturation": -100
              },
              {
                "lightness": "50"
              },
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
              {
                "saturation": "-100"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "all",
            "stylers": [
              {
                "lightness": "30"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "all",
            "stylers": [
              {
                "lightness": "40"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
              {
                "saturation": -100
              },
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "hue": "#ffff00"
              },
              {
                "lightness": -25
              },
              {
                "saturation": -97
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels",
            "stylers": [
              {
                "lightness": -25
              },
              {
                "saturation": -100
              }
            ]
          }
        ]
      });

      const isLoaded = computed(() => {
        if (state.jsonData.hasOwnProperty('content')) {
          return true;
        }
        return false;
      });

      onUnmounted(() => {
        window.removeEventListener('resize', function () { selectLocation(state.selectedOfficeIndex) });
      });

      onMounted(() => {
        setTimeout(function () {
          selectLocation(0);
        }, 1500);
      });

      watchEffect(() => {
        if (locationSelector.value!==null) {
          if(state.selectedOfficeIndex === 0){
            var width = locationSelector.value.childNodes[state.selectedOfficeIndex+1].scrollWidth;
            var left = locationSelector.value.childNodes[state.selectedOfficeIndex+1].offsetLeft;

            state.indicatorPos = parseInt(left + (width / 2), 10);
            state.selectedOffice = state.offices[state.selectedOfficeIndex];
            state.selectedOfficeIndex = state.selectedOfficeIndex;
          }
        }
      });

      const gotoGoogleDirections = () => {
        let company;

        if (state.selectedOffice.location === 'Yardley')
          company = 'Axiom Professional Health';
        else if (state.selectedOffice.location === 'Buckingham')
          company = 'Just Communicate';
        else if (state.selectedOffice.location === 'Philadelphia')
          company = '';
        else
          company = 'The Creative Engagement Group';
      }

      const selectLocation = (i) => {
        if (locationSelector.value!==null) {
          var width = locationSelector.value.childNodes[i+1].scrollWidth;
          var left = locationSelector.value.childNodes[i+1].offsetLeft;
          state.indicatorPos = parseInt(left + (width / 2), 10);
          state.selectedOffice = state.offices[i];
          state.selectedOfficeIndex = i;
        }
      }

      const renderMaps = (lat, long) => {
        const element = document.getElementById('map');
        const options = {
          zoom: 15,
          styles: this.mapStyle,
          center: new google.maps.LatLng(lat, long),
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
        }
        const map = new google.maps.Map(element, options);
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(lat, long),
          icon: {
            url: '../assets/map-marker.svg',
            scaledSize: new google.maps.Size(42, 42)
          },
          map: map
        });
        marker.addListener('click', gotoGoogleDirections);
      }

      const mapClicked = () => {
        window.open("'https://maps.google.com/?q='+state.selectedOffice.latitude+','+state.selectedOffice.longitude")
      }

      let url;
      const route = useRoute();

      if (route.params.num) {
        state.preview_num = route.params.num;
        url = backend + 'preview/page/' + state.preview_num + '/location-page';
      } else {
        url = backend + 'home-page/';
      }
      api(url)
        .then((data) => {
          state.jsonData = data.data;
          if (state.jsonData.site_details.all_locations) {
            state.offices = state.jsonData.site_details.all_locations;
          }

          state.selectedOffice = state.offices[0];
          EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
          // EventBus.$emit('header_menu_items', this.jsonData.site_details.menu_items.header);
          // EventBus.$emit('footer_menu_items', this.jsonData.site_details.menu_items.footer);
          EventBus.$emit('isLoaded', true);
          EventBus.$emit('footer', state.jsonData.site_details.tceg);
          EventBus.$emit('contactPage', true);
          EventBus.$emit('isPurple', true);
          EventBus.$emit('statement', state.jsonData.site_details.footer_statement);
          //this.$nextTick(() => this.renderMaps(this.state.offices[0].latitude, this.state.offices[0].longitude));
        }).catch(error => {
          state.loading = false;
        });

      window.addEventListener('resize', function () { selectLocation(state.selectedOfficeIndex) });

      return {
        state,
        window,
        isLoaded,
        gotoGoogleDirections,
        selectLocation,
        mapClicked,
        renderMaps,
        locationSelector
      }

    }

  }</script>
