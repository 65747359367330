<template>
  <transition class="modal" name="modal">
    <div class="modal__mask">
      <div id="outsideModal" class="modal__wrapper">
        <div class="modal__container">
          <a class="modal__closeButton" @click="emit('closeModal')"></a>
          <div :class="colour">
            <div id="homeVideo"></div>
            <div id="aboutVideo"></div>
            <div id="playlist"></div>
            <div id="serviceVideo"></div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import {onClickOutside} from "@vueuse/core";
import { ref, inject } from "vue";

const props = defineProps({
  colour: String
})

const emit = defineEmits(['closeModal'])

const target = ref(null)
onClickOutside(target, () => emit('closeModal'))
</script>
