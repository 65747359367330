<template>
  <div class="select-dropdown" :style="{width:state.elementWidth+'px'}">
    <span v-on:click="toggleOptions()">{{ state.currentOption }}</span>
    <ul v-bind:style="{
      display: state.showOptions || state.ghostOptions ? 'block' : 'none',
      visibility: state.ghostOptions ? 'hidden' : 'visible',
    }"
        ref="optionsContainer">
      <li v-on:click="updateCurrentOption($props.defaultVal)">{{ $props.defaultVal }}</li>
      <li v-for="(option, index) in $props.options" v-on:click="updateCurrentOption(option)">
        {{ option }}
      </li>
    </ul>
  </div>
</template>
<script >
  import  EventBus  from '../../eventbus.js';
  import {onMounted, onUnmounted, reactive, ref} from "vue";

  //Another defunct add-in
  //  import ClickOutside from 'vue-click-outside'

  export default {
    name: 'select-dropdown',
    components: {
    },
    props: {
      options: Array,
      defaultVal: String,
      onChange: Function,
    },
    setup(props){

      const state = reactive({
        ghostOptions: false, /* this is to set the display to block and visibility to hidden, so the dropdown width can be measured */
        showOptions: false,
        elementWidth: null,
        currentOption: null,
        timeout: null,
        optionsContainerRef: null,
      });

      const updateCurrentOption = (newValue) => {
        state.currentOption = newValue;
        state.showOptions = false;
        props.onChange(newValue);
      };

      const toggleOptions = () => {
        state.showOptions = !state.showOptions;
      };

      const hideOptions = () => {
        if (state.showOptions)
          state.showOptions = false;
      };

      const setWidth = () => {

        clearTimeout(state.timeout);

        //var ref = this.$refs.optionsContainer;
        var optionsContainer = ref(null);

        if (!state.showOptions)
          state.ghostOptions = true;

        var _this = state;

        state.timeout = setTimeout(function () { /* I don't know why it only works with this timeout */

          let optionsWidth = optionsContainer.clientWidth;
          _this.elementWidth = optionsWidth + 20;

          if (_this.currentOption === null)
            _this.currentOption = props.defaultVal;

          if (!_this.showOptions)
            _this.ghostOptions = false;

        }, 0);

      }

      window.addEventListener('resize', setWidth);
      onUnmounted(() => {
        window.removeEventListener('resize', setWidth)
      });

      onMounted(() => {
        state.timeout = setTimeout(function () {
          setWidth();
        }, 1000);
      });

      return {
        state,
        updateCurrentOption,
        toggleOptions,
        hideOptions,
        setWidth
      }
    },
  }
</script>
