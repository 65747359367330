<template>
  <div v-if="state.isLoaded">

    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />

    <header-component :title=null
                      :image=null
                      class="header--newsItem">
    </header-component>
    <main id="news-item-full">
      <h2 class="news-item-settings">
        {{state.jsonData.type}} &#8226; <span>{{state.jsonData.date}}</span>
      </h2>
      <h1 :class="state.jsonData.type">
        {{state.jsonData.title}}
      </h1>

      <div v-if="!state.noImage" class="wrapper">
        <picture-sources v-if="state.jsonData.image && state.jsonData.image.sizes && state.jsonData.image.sizes.length"
                         :targetWidth="100"
                         :sizes="state.jsonData.image.sizes"
                         :imgSrc="state.jsonData.image.guid"
                         imgAlt=""
                         imgClass="image objectFitImage" />
      </div>

      <div v-if="state.jsonData.video">
        <div class="workDetailPage__video" itemscope itemtype="https://schema.org/VideoObject">
          <meta itemprop="contentUrl" :content="state.jsonData.video" />
          <div style="position:relative; overflow:hidden; padding-bottom:56.25%">
            <iframe :src="state.jsonData.embed"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    scrolling="auto"
                    :title="largeTitle"
                    style="position: absolute;"
                    allowfullscreen>
            </iframe>
          </div>
        </div>

      </div>

      <div :class="[{'newsItem-content__noImage' : state.noImage}, 'newsItem-content']">
        <div class="newsItem-content__bio">
          <div class="newsItem-content__bio__title" v-if="state.jsonData.author">
            <a v-if="state.jsonData.author.key == 1" :href="'/people/' + state.jsonData.author.link" :title="'Link to ' + state.jsonData.author.name">
              <div v-if="state.jsonData.author.image" class="newsItem-content__bio__authorImgLarge" v-bind:style="{backgroundImage: 'url(' + state.jsonData.author.image.guid + ')'}" alt=""></div>
            </a>
            <div v-else-if="state.jsonData.author.image" class="newsItem-content__bio__authorImgLarge" v-bind:style="{backgroundImage: 'url(' + state.jsonData.author.image.guid + ')'}" alt=""></div>
            <label v-if="state.jsonData.author.name">{{state.jsonData.author.name}}</label>
            <label v-else>{{state.jsonData.author}}</label>
            <span v-if="state.jsonData.author.job_title">{{state.jsonData.author.job_title}}</span>
          </div>
          <div class="newsItem-content__bio__share-news">
            <span>Share</span>
            <a :href="'https://twitter.com/intent/tweet?text=' + state.jsonData.title + '&url=' + state.url2" target="_blank" rel="noreferrer noopener">
              <img src="../assets/twitter-logo_blue.svg" alt="Twitter Icon">
            </a>
            <a :href="'https://www.linkedin.com/shareArticle?url=' + state.url2" target="_blank" rel="noreferrer noopener">
              <img src="../assets/linkdin-logo_blue.svg" alt="Linkedin Icon">
            </a>
          </div>
        </div>
        <div class="newsItem-content__feature">
          <div class="newsItem-content__feature__text" v-html=state.jsonData.content></div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
const emit = defineEmits([
  'isLoaded',
  'menu_items',
  'footer',
  'statement'
])

  import EventBus from '../eventbus.js';
  import { api } from '../utils/api-gateway';
  import { computed, nextTick, onMounted, reactive } from "vue";
  import HeaderComponent from './widgets/header-component';
  //import VueScrollTo from 'vue-scrollto';
  import HeaderBar from './widgets/header-bar';
  import PictureSources from './widgets/picture-sources';
  import { useRoute } from 'vue-router';
  import { useInjects } from '../utils/globals-inject';

  const { backend } = useInjects();
  const state = reactive({
    jsonData: [],
    newsTitle: 'News',
    id: null,
    noImage: false,
    preview_num: null,
    preview_title: null,
    name: null,
    isLoaded: false,
    url2: encodeURI(window.location.href)
  });

  onMounted (() => {
    let url;
    const route = useRoute();

      if (route.num) {
        state.preview_num = route.num;
        state.preview_title = route.title;
        url = backend + 'preview/news/' + state.preview_num + '/' + state.preview_title;
      } else {
        state.name = route.params.link;
        url = backend + 'news/' + state.name + '/';
      }
      api(url)
        .then((data) => {
          state.jsonData = data.data;
          EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
          EventBus.$emit('isLoaded', true);
          EventBus.$emit('footer', state.jsonData.site_details.tceg);
          EventBus.$emit('isPurple', true);
          EventBus.$emit('statement', state.jsonData.site_details.footer_statement);
          if (!state.jsonData.image) {
            state.noImage = true;
          }
          nextTick().then(() => {
            oldMounted();
          });
        }).catch(error => {
          state.loading = false;
        });
    });

  const isLoaded = computed(() => {
    if (state.jsonData.hasOwnProperty('content')) {
      return true;
    }
    return false;
  });

  const oldMounted = () => {
    let objectFitImages = require('object-fit-images');

    // Object-fit fix for IE
    objectFitImages('img.objectFitImage');

    state.isLoaded = true;
  };

</script>
