<template>
  <div class="header__white-bar">
    <!-- <a href="https://inizioengage.com" target="_blank" title="link to Inizio Engage"> -->
    <router-link :to="'/'" class="header__logo">
      <img src="/logos/inizio_engage_logo.png" alt="Inizio Engage logo" />
    </router-link>
    <div class="header__statement" v-html="statement"></div>
  </div>
</template>
<script>
  export default {
    name: 'HeaderBar',
    props: {
      statement: String
    }
  }</script>
