<template>
  <div v-if="isLoaded">

    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />

    <header-component class='header--people'
                      :class="{'header--overlay header--overlay--solid': state.overlay}"
                      :title=state.jsonData.content.post_title
                      :image=state.jsonData.content.banner_image>
    </header-component>
    <main>
      <section class="people-description">
        <div class="people-description__container">
          <div class="people-description__intro__description" v-html="state.jsonData.content.post_content"></div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.4 733.5" id="svgGroup16">
          <g>
            <path id="svgGroup16__path1" class="svgpath svgpath--mist" d="M173.9,2.5v521.7c0,102.6-73.6,188.1-171,206.3" />
            <path id="svgGroup16__path6" class="svgpath svgpath--fuschia" d="M152.6,461.3V515c-0.1,63.6,28.7,123.8,78.3,163.6" />
            <path id="svgGroup16__path2" class="svgpath svgpath--fuschia" d="M173.9,27.3v144" />
            <path id="svgGroup16__path5" class="svgpath svgpath--fuschia" d="M173.9,421.4v102.8c0.1,42.6-12.9,84.3-37.2,119.3" />
            <path id="svgGroup16__path3" class="svgpath svgpath--fuschia" d="M149.5,108.6v256.7" />
            <path id="svgGroup16__path4" class="svgpath svgpath--green" d="M163.5,249.4V515c0,28.1-5.6,55.9-16.5,81.7c-24.1,56.9-72,100.3-131,118.8" />
          </g>
        </svg>
      </section>
      <section class="people-list">
        <div class="people-list__container">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.7 374.2" id="svgGroup20">
            <g>
              <path id="svgGroup20__path3" class="svgpath svgpath--mist" d="M60.3,231.5c35.9,35.8,51.3,87.4,40.9,137" />
              <path id="svgGroup20__path1" class="svgpath svgpath--fuschia" d="M48.5,7c-58.7,58.7-58.7,154,0,212.7" />
              <path id="svgGroup20__path2" class="svgpath svgpath--green" d="M16.5,113.3c0,76.4,62,138.4,138.4,138.4" />
            </g>
          </svg>
          <div v-for="(person, index) in state.jsonData.content.people" class="cps-class people-list__container__person" :id="'personContainer__' + index" v-bind:class="{selectedBio: state.personBio == person}"
               v-bind:style="{ order: (index + 1) }" v-bind:id="person.link" :data-order="index">
            <a @click="goToItem(person.link, 'people-page')" :id="person.link" :href="/people/ + person.link">
              <div class="people-list__container__person__imageWrapper">

                <picture-sources v-if="person.image && person.image.sizes && person.image.sizes.length"
                                 :targetWidth="window.innerWidth <= mobile ? 100 : window.innerWidth <= tablet ? 50 : 33"
                                 :sizes="person.image.sizes"
                                 :imgSrc="person.image.guid"
                                 :imgAlt="person.name"
                                 imgClass="people-list__container__person__image objectFitImage" />

              </div>
              <h2 v-bind:class="{selectedBio: state.personBio == person}" class="people-list__container__person__name">{{ person.name }}</h2>
              <span>{{ person.job_title }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 554.3" id="svgGroup17">
                <g>
                  <path :id="'svgGroup17__path2__' + state.person.name" class="svgpath svgpath--fuschia" d="M2.5,85.2v256.7" />
                  <path :id="'svgGroup17__path5__' + state.person.name" class="svgpath svgpath--fuschia" d="M2.5,369.4v121.5" />
                  <path :id="'svgGroup17__path4__' + state.person.name" class="svgpath svgpath--green" d="M14.5,173.2v256.7" />
                  <path :id="'svgGroup17__path6__' + state.person.name" class="svgpath svgpath--mist" d="M14.5,463.7v86.2" />
                  <path :id="'svgGroup17__path3__' + state.person.name" class="svgpath svgpath--mist" d="M26.5,129.7v180.1" />
                  <path :id="'svgGroup17__path1__' + state.person.name" class="svgpath svgpath--fuschia" d="M26.5,3.5v144" />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </section>
    </main>
  </div>

</template>
<script>
  import { useRoute, useRouter } from "vue-router";
  import { computed, nextTick, reactive } from "vue";
  import EventBus from '../eventbus.js';
  import HeaderComponent from './widgets/header-component.vue';
  import { api } from '../utils/api-gateway';
  import PictureSources from './widgets/picture-sources';
  import VueScrollTo from "vue-scrollto";
  import HeaderBar from './widgets/header-bar';
  import MyPluginCPS from '../plugins/MyPluginCPS.js';
  import { useInjects } from '../utils/globals-inject';
  export default {
    name: 'PeoplePage',
    components: {
      HeaderBar,
      HeaderComponent,
      PictureSources,
    },
    setup() {
      const router = useRouter();
      let { window, desktop, mobile, tablet, mouseX, mouseY, backend } = useInjects();
      const { svgAnimate, goToItem } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);

      const state = reactive({
        jsonData: [],
        personBio: null,
        firstName: '',
        lastName: '',
        bioOrderNumber: null,
        selected: false,
        name: null,
        isActive: true,
        person: '',
        overlay: null,
      });

      const isLoaded = computed(() => {
        if (state.jsonData.hasOwnProperty('content')) {
          return true;
        }
        return false;
      });

      const toggleClass = (e) => {
        state.isActive = !state.isActive;
      };

      const revealBio = (person, index) => {
        if (person) {
          state.personBio = person;
          state.selected = true;
          state.firstName = person.name.split(' ').slice(0, -1).join(' ');
          let columnRowNo = 3;
          if (window.innerWidth >= state.desktop) {
            columnRowNo = 3;
          } else if (window.innerWidth <= 1200 && window.innerWidth > mobile) {
            columnRowNo = 2;
          } else if (window.innerWidth <= mobile) {
            columnRowNo = 1;

            // Give time for page to re-render before scrolling to new profile
            setTimeout(function () {
              let element = document.getElementById(person.link);
              element.scrollIntoView({
                behavior: 'smooth'
              });
            }, 100);
          }
          state.bioOrderNumber = Math.ceil(index / columnRowNo) * columnRowNo;

          if (window.innerWidth >= state.desktop && !isIE) {
            let svgPathsPeopleTwo = [
              {
                id: 'svgGroup17__path1__' + person.name,
                tweenId: '2',
                speed: 2,
                delay: 0,
                trigger: ".people-list",
                offset: '0'
              },
              {
                id: 'svgGroup17__path2__' + person.name,
                tweenId: '2',
                speed: 2,
                delay: 0,
                trigger: ".people-list",
                offset: '0'
              },
              {
                id: 'svgGroup17__path3__' + person.name,
                tweenId: '2',
                speed: 2,
                delay: 0,
                trigger: ".people-list",
                offset: '0'
              },
              {
                id: 'svgGroup17__path4__' + person.name,
                tweenId: '2',
                speed: 2,
                delay: 0,
                trigger: ".people-list",
                offset: '0'
              },
              {
                id: 'svgGroup17__path5__' + person.name,
                tweenId: '2',
                speed: 2,
                delay: 0,
                trigger: ".people-list",
                offset: '0'
              },
              {
                id: 'svgGroup17__path6__' + person.name,
                tweenId: '2',
                speed: 2,
                delay: 0,
                trigger: ".people-list",
                offset: '0'
              },
            ]

            let svgTweensPeople = {};

            // Loop through SVG PATHS array / build tweens and scenes
            svgAnimate(svgPathsPeopleTwo, svgTweensPeople);

            renderSvgAnimationsAfterBioPopup();
          }
        }
      };

      const renderSvgAnimationsAfterBioPopup = () => {
        let _this = this;
        setTimeout(function () {
          let svgPathsPeople = [
            {
              id: 'svgGroup18__path1',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__container__images",
              offset: '0'
            },
            {
              id: 'svgGroup18__path2',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__container__images",
              offset: '0'
            },
            {
              id: 'svgGroup18__path3',
              tweenId: '3',
              speed: 2,
              delay: 0,
              trigger: ".people-list__single-person__container__images",
              offset: '0'
            },
          ]

          let svgTweensPeople = {};

          // Loop through SVG PATHS array / build tweens and scenes
          svgAnimate(svgPathsPeople, svgTweensPeople);
        })

      };

      const dismissBio = () => {
        state.personBio = null;
        state.bioOrderNumber = null
      };

      const checkBioIsOpen = (person) => {
        if (state.personBio === null) {
          return true;
        } else if (state.personBio.name === person.name) {
          return false;
        } else if (state.personBio.name !== person.name) {
          return true;
        }
      };

      const oldMounted = () => {
        let objectFitImages = require('object-fit-images');

        // Object-fit fix for IE
        objectFitImages('img.objectFitImage');

        if (window.innerWidth >= state.desktop && !isIE) {

          let svgPathsPeople = [
            {
              id: 'svgGroup16__path1',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: ".people-description",
              offset: '0'
            },
            {
              id: 'svgGroup16__path2',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: ".people-description",
              offset: '0'
            },
            {
              id: 'svgGroup16__path3',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: ".people-description",
              offset: '0'
            },
            {
              id: 'svgGroup16__path4',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: ".people-description",
              offset: '0'
            },
            {
              id: 'svgGroup16__path5',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: ".people-description",
              offset: '0'
            },
            {
              id: 'svgGroup16__path6',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: ".people-description",
              offset: '0'
            },

          ]

          let svgTweensPeople = {};

          // Loop through SVG PATHS array / build tweens and scenes
          svgAnimate(svgPathsPeople, svgTweensPeople);
        }

        /*let ele = route.hash;
        if (route.hash.length) {
          ele = ele.substr(1);
          let element = document.getElementById(ele);
          var _this = this;
          setTimeout(function () {
            revealBio(_this.jsonData.content.people[element.getAttribute('data-order')], parseInt(element.getAttribute('data-order')) + 1);
            element.scrollIntoView();
          }, 500);
        }*/

        const route = useRoute();

        /*if (route.params.person) {
          state.person = route.params.person;
          Object.entries(state.jsonData.content.people).forEach(([key, value]) => {
            if (value.link === state.person) {
              revealBio(value, parseInt(key) + 1);
              //document.getElementById(value.link).scrollIntoView({behavior: 'smooth'});

              //VueScrollTo.scrollTo(document.getElementById(value.link), 500);
            }
          });
        }*/
        state.overlay = (state.jsonData.content.overlay != 0);
      }

      let url;
      const route = useRoute();

      if (route.params.num) {
        state.preview_num = route.params.num;
        url = backend + 'preview/people/' + state.preview_num + '/people-page';
      } else {
        state.name = route.params.link;
        url = backend + 'page/people/';
      }
      api(url)
        .then((data) => {
          state.jsonData = data.data;
          nextTick().then(() => {
            oldMounted();
            EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
            EventBus.$emit('isLoaded', true);
            EventBus.$emit('footer', state.jsonData.site_details.tceg);
            EventBus.$emit('isPurple', false);
            EventBus.$emit('statement', state.jsonData.site_details.footer_statement);
          });

          revealBio(state.jsonData.content.amended, state.jsonData.content.index);
        }).catch(error => {
          state.loading = false;
        });
      return {
        state,
        isLoaded,
        window,
        tablet,
        mobile,
        svgAnimate,
        goToItem
      };
    },
  }</script>
