<template>
  <div class="newsItem">
    <div v-if="type.gradient" class="newsItem--gradient" @click="goToItem(link, itemLink)">
      <div class="overlay"
           :style="{'background':'linear-gradient(90deg, ' + colour + ' 25%, transparent 100%)'}">
      </div>
      <picture-sources v-if="image && image.sizes && image.sizes.length"
                       :targetWidth="window.innerWidth <= $props.mobile ? 100 : 50"
                       :sizes="image.sizes"
                       :imgSrc="image.guid"
                       :imgAlt="image.alt"
                       imgClass="newsItem__bg objectFitImage" />
      <div class="newsItem__info">
        <h2>{{ newsType }}</h2>
        <div class="newsItem__info__title">
          <h3>{{ title }}</h3>
        </div>
      </div>
    </div>
    <div v-if="type.curve" class="newsItem--curve" @click="goToItem(link, itemLink)"
         :class="{'newsItem--purple': brandColour == '#5718A0', 'newsItem--pink': brandColour == '#D53CA8', 'newsItem--pink2': brandColour == '#EB456F'}">
      <div class="newsItem--curve--image">
        <picture-sources v-if="image && image.sizes && image.sizes.length"
                         :targetWidth="50"
                         :sizes="image.sizes"
                         :imgSrc="image.guid"
                         :imgAlt="image.alt" />
      </div>
      <div class="newsItem--curve--text">
        <h2>{{ newsType }}</h2>
        <h3>{{ title }}</h3>
      </div>
    </div>
    <div v-if="type.circle" class="newsItem--circle" @click="goToItem(link, itemLink)"
         :class="{'newsItem--purple': brandColour == '#5718A0', 'newsItem--pink': brandColour == '#D53CA8', 'newsItem--pink2': brandColour == '#EB456F'}">
      <div class="newsItem__wrapper newsItem__wrapper--absolute">
        <div class="newsItem__col1">
          <!-- <a class="button button--white button--portfolio">
              <span>watch</span>
          </a> -->
        </div>
        <div class="newsItem__col2">
        </div>
      </div>
      <div class="newsItem__wrapper">
        <div class="newsItem__col1 newsItem__col1--filled">
          <img class="newsItem__col1__img objectFitImage" :src=image alt="News Image" />
        </div>
        <div class="newsItem__col2 newsItem__col2--filled">
          <div class="newsItem__info">
            <h2>{{ newsType }}</h2>
            <div class="newsItem__info__title">
              <h3>{{ title }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import PictureSources from './picture-sources';
  import { reactive } from "vue";
  import MyPluginCPS from '../../plugins/MyPluginCPS.js';
  import { useRouter } from "vue-router";
  import { useInjects } from '../../utils/globals-inject';
  export default {
    name: 'news-item',
    components: {
      PictureSources
    },
    props: {
      type: Object,
      title: String,
      image: Object,
      colour: String,
      brandColour: String,
      newsType: String,
      newsID: Number,
      itemLink: String,
      link: String,
      mobile: Number,
    },
    setup(props) {
      const router = useRouter();
      let { window, desktop, mouseX, mouseY } = useInjects();
      const { goToItem } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);
      const state = reactive({
        mobile: props.mobile
      });
      return {
        state,
        window,
        goToItem
      }
    }
  }</script>
