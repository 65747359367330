<template>
  <div v-if="isLoaded" class="footerPage">
    <header-component type="footer" class='header--footerLinks'>
    </header-component>
    <main>
      <h1>{{ state.jsonData.content.post_title }}</h1>
      <div v-html='state.jsonData.content.post_content'>
      </div>
    </main>
  </div>
</template>
<script>
  import EventBus from '../eventbus.js';
  import HeaderComponent from './widgets/header-component';
  import { api } from '../utils/api-gateway';
  import { useRoute } from "vue-router";
  import { useInjects } from '../utils/globals-inject';

  export default {
    name: 'CodeOfEthicsPage',
    components: {
      HeaderComponent
    },
    setup() {
      const { backend } = useInjects();
      const state = reactive({
        jsonData: []
      });

      const isLoaded = computed(() => {
        if (state.jsonData.hasOwnProperty('content')) {
          return true;
        }
        return false;
      });
    
      let url;
      const route = useRoute();
      if (route.params.num) {
        state.preview_num = route.params.num;
        url = backend + 'preview/page/' + state.preview_num + '/code-of-ethics-page';
      } else {
        url = backend + 'page/code-of-ethics-page/';
      }
      api(url)
        .then((data) => {
          state.jsonData = data.data;
          EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
          EventBus.$emit('isLoaded', true);
          EventBus.$emit('footer', state.jsonData.site_details.tceg);
          EventBus.$emit('isPurple', true);
          EventBus.$emit('statement', state.jsonData.site_details.footer_statement);
          nextTick().then(() => {
            oldMounted();
          });
        }).catch(error => {
          state.loading = false;
        });

      return {
        state,
        isLoaded
      }
    },
  }</script>
