<template>
  <div v-if="isLoaded" class="home">

    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />

    <header class="header header--home">
      <div class="header--home__banner">
        <div class="header--home__banner__text"
             v-html="state.jsonData.banner_text"></div>
        <div class="header--home__banner__images">
          <img ref="header__image" :src="state.headerImage" />
          <div ref="blob1" class="blob blob1"></div>
          <div ref="blob2" class="blob blob2 vertical-fast"></div>
          <div ref="blob3" class="blob blob3"></div>
          <div ref="blob4" class="blob blob4"></div>
          <div ref="blob5" class="blob blob5"></div>
          <div ref="blob6" class="blob blob6"></div>
          <div ref="blob7" class="blob blob7"></div>
          <div ref="blob8" class="blob blob8"></div>
          <div ref="blob9" class="blob blob9 vertical-slow"></div>
          <div ref="blob10" class="blob blob10"></div>
        </div>
      </div>
      <!--<div v-if="!isIE && showIframe" id="header-animation"></div>-->
      <!--<LoadingSpinner v-if="!isIE && showIframe" id="home-loading-spinner" />-->
      <img class="header__scroll"
           src="../assets/svg-paths/scroll-mobile.svg"
           alt="Scroll lines" />
    </header>
    <main>
      <modal-component v-show="state.isModalVisible" :colour="state.jsonData.showreel.colour" @close-modal="state.homeVideo.stop(); state.isModalVisible = false">
      </modal-component>
      <section class="about content-wrap" id="aboutSection">
        <div class="about__intro" id="aboutIntro">
          <div class="about__intro__description"
               v-html="state.jsonData.content.post_content"></div>
          <svg xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 350.194 783.249"
               id="svgGroup1">
            <g>
              <path id="svgGroup1__path7"
                    class="svgpath svgpath--green"
                    d="M267.4,519.3c-55.7-55.7-146-55.7-201.7,0" />
              <path id="svgGroup1__path6"
                    class="svgpath svgpath--mist"
                    d="M166.5,465.1c-85.6,0-155,69.4-155,155l0,0v160.6" />
              <path id="svgGroup1__path5"
                    class="svgpath svgpath--fuschia"
                    d="M166.5,465.1c-85.6,0-155,69.4-155,155l0,0" />
              <path id="svgGroup1__path4"
                    class="svgpath svgpath--mist"
                    d="M338.7,106.5v203.6c0,85.6-69.4,155-155,155" />
              <path id="svgGroup1__path3"
                    class="svgpath svgpath--mist"
                    d="M338.7,59.1v80" />
              <path id="svgGroup1__path2"
                    class="svgpath svgpath--green"
                    d="M327.7,94v205" />
              <path id="svgGroup1__path1"
                    class="svgpath svgpath--fuschia"
                    d="M316.7,39v205" />
            </g>
            <g id="scroll-text">
              <path class="svgText"
                    d="M304.3,12.5c0-0.5-0.2-0.8-0.5-1.1s-0.9-0.6-1.8-0.8c-1.1-0.3-1.9-0.6-2.5-1.1c-0.6-0.5-0.9-1.1-0.9-1.8
                            c0-0.8,0.3-1.4,1-1.9s1.5-0.8,2.5-0.8c1.1,0,2,0.3,2.6,0.9c0.6,0.6,1,1.3,0.9,2.1l0,0h-1.3c0-0.6-0.2-1.1-0.6-1.4
                            c-0.4-0.4-0.9-0.5-1.7-0.5c-0.7,0-1.2,0.2-1.5,0.5c-0.4,0.3-0.5,0.7-0.5,1.2c0,0.4,0.2,0.8,0.6,1.1c0.4,0.3,1,0.5,1.9,0.8
                            c1,0.3,1.8,0.6,2.4,1.1s0.8,1.1,0.8,1.9c0,0.8-0.3,1.4-1,1.9s-1.5,0.7-2.6,0.7c-1,0-1.9-0.3-2.6-0.8c-0.8-0.5-1.1-1.3-1.1-2.2l0,0
                            h1.3c0,0.6,0.2,1.1,0.7,1.5c0.5,0.3,1.1,0.5,1.7,0.5c0.7,0,1.2-0.1,1.6-0.4C304.1,13.4,304.3,13,304.3,12.5z" />
              <path class="svgText"
                    d="M316.7,11.8L316.7,11.8c0,1-0.3,1.8-1,2.4c-0.7,0.6-1.6,0.9-2.7,0.9c-1.1,0-2-0.4-2.8-1.2
                            c-0.7-0.8-1.1-1.8-1.1-3V9.1c0-1.2,0.4-2.2,1.1-3c0.7-0.8,1.6-1.2,2.8-1.2c1.1,0,2,0.3,2.7,0.9c0.7,0.6,1,1.4,1,2.4l0,0h-1.3
                            c0-0.7-0.2-1.2-0.6-1.7S313.8,6,313,6c-0.8,0-1.4,0.3-1.8,0.9c-0.4,0.6-0.7,1.4-0.7,2.3v1.8c0,0.9,0.2,1.7,0.7,2.3
                            c0.4,0.6,1,0.9,1.8,0.9c0.7,0,1.3-0.2,1.7-0.6c0.4-0.4,0.6-1,0.6-1.7H316.7z" />
              <path class="svgText"
                    d="M321.8,10.7V15h-1.3V5.1h3.6c1.1,0,1.9,0.2,2.5,0.7s0.9,1.2,0.9,2.1c0,0.5-0.1,1-0.4,1.3s-0.7,0.7-1.2,0.9
                            c0.5,0.2,0.9,0.5,1.2,0.9c0.2,0.4,0.4,0.9,0.4,1.5v0.9c0,0.3,0,0.6,0.1,0.8s0.2,0.4,0.4,0.6V15h-1.4c-0.2-0.2-0.3-0.4-0.3-0.7
                            c-0.1-0.3-0.1-0.6-0.1-0.9v-0.9c0-0.5-0.2-1-0.5-1.3s-0.7-0.5-1.3-0.5H321.8z M321.8,9.6h2.1c0.8,0,1.3-0.1,1.6-0.4
                            c0.3-0.3,0.5-0.7,0.5-1.3c0-0.6-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.5-0.5h-2.3V9.6z" />
              <path class="svgText"
                    d="M339.3,10.9c0,1.2-0.4,2.2-1.1,3c-0.8,0.8-1.7,1.2-2.9,1.2c-1.2,0-2.1-0.4-2.8-1.2c-0.7-0.8-1.1-1.8-1.1-3
                            V9.1c0-1.2,0.4-2.2,1.1-3c0.7-0.8,1.7-1.2,2.8-1.2c1.2,0,2.2,0.4,2.9,1.2c0.8,0.8,1.1,1.8,1.1,3V10.9z M337.9,9.1
                            c0-0.9-0.2-1.7-0.7-2.2S336,6,335.2,6c-0.8,0-1.4,0.3-1.9,0.9c-0.5,0.6-0.7,1.3-0.7,2.2v1.8c0,0.9,0.2,1.7,0.7,2.3
                            c0.5,0.6,1.1,0.9,1.9,0.9c0.8,0,1.5-0.3,2-0.9c0.5-0.6,0.7-1.3,0.7-2.3V9.1z" />
              <path class="svgText" d="M344.6,13.9h4.7V15h-6.1V5h1.3V13.9z" />
              <path class="svgText" d="M354.2,13.9h4.7V15h-6.1V5h1.3V13.9z" />
            </g>
          </svg>
          <a class="button button--purple button--about"
             id="aboutButton"
             @click="state.isModalVisible = true">
            <span>view reel</span>
          </a>
        </div>
      </section>
      <section class="engagement" id="engagementSection">
        <ul class="engagement__details">
          <li v-for="(item, index) in state.jsonData.services"
              class="engagement__details__item"
              :key="index"
              :class="{ active: state.active == index }">
            <a :href="item.detail_page ? '/services/' + item.link : '/services#' + item.link"
               :alt="item.service"
               class="engagement__details__item__category"
               @mouseover="mouseOver(index)"
               @mouseleave="mouseOut">{{item.service}}.&nbsp;</a>
            <div class="engagement__details__item__info">
              <div class="engagement__details__item__info__wrapper">
                <div class="engagement__details__item__info__overlay"></div>
                <picture-sources v-if="item && item.image.sizes && item.image.sizes.length"
                                 :targetWidth="50"
                                 :sizes="item.image.sizes"
                                 :imgSrc="item.image.guid"
                                 imgAlt=""
                                 imgClass="objectFitImage"
                                 :mobile="mobile" />
              </div>
            </div>
          </li>
        </ul>
        <!-- <span class="engagement__prompt">press + hold to peek</span> -->
      </section>

      <section class="portfolio" id="portfolioSection">
        <div class="portfolio__feature portfolio__feature--sm">
          <div class="portfolio__feature--sm__wrapper">
            <svg xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 1224 1696"
                 id="svgGroup3">
              <g>
                <path id="svgGroup3__path8"
                      class="svgpath svgpath--mist"
                      d="M1000.5,1260.2c62.1-4.9,123.2,17.7,167.2,61.8" />
                <path id="svgGroup3__path7"
                      class="svgpath svgpath--fuschia"
                      d="M1000.5,1260.2c32.3-2.5,64.7,2.4,94.9,14.2" />
                <path id="svgGroup3__path10"
                      class="svgpath svgpath--mist"
                      d="M840.8,1386.6c47-98.4,164.8-140.1,263.2-93.1c68.7,32.8,112.4,102.1,112.3,178.2V1696" />
                <path id="svgGroup3__path9"
                      class="svgpath svgpath--green"
                      d="M1158.5,1332.2c-77.1-77.1-202.1-77.1-279.2,0" />
                <g>
                  <path id="svgGroup3__path6"
                        class="svgpath svgpath--fuschia"
                        d="M233.5,1123.8c86.6,149.9,278.3,201.3,428.2,114.7" />
                  <path id="svgGroup3__path5"
                        class="svgpath svgpath--green"
                        d="M213.4,1039.7c40.1,161,203.2,259,364.3,218.9" />
                </g>
                <g>
                  <path id="svgGroup3__path4"
                        class="svgpath svgpath--mist"
                        d="M744.5,653.5v381c0,147.3,75.7,223,227,227" />
                  <path id="svgGroup3__path3"
                        class="svgpath svgpath--fuschia"
                        d="M589.5,497.5c85.6,0,155,69.4,155,155c0,0,0,0,0,0" />
                  <path id="svgGroup3__path2"
                        class="svgpath svgpath--green"
                        d="M689.5,551.3c-55.7-55.7-146-55.7-201.7,0" />
                </g>
                <path id="svgGroup3__path1"
                      class="svgpath svgpath--mist"
                      d="M9.5,2.5v808c0,93.3,51.7,145,155,155" />
              </g>
            </svg>
            <div class="portfolio__feature__image"
                 v-if="state.jsonData.case_study[0].image">
              <div class="portfolio__feature__image__imageWrapper">
                <picture-sources v-if="state.jsonData.case_study[0] && state.jsonData.case_study[0].image.sizes && state.jsonData.case_study[0].image.sizes.length"
                                 :targetWidth="50"
                                 :sizes="state.jsonData.case_study[0].image.sizes"
                                 :imgSrc="state.jsonData.case_study[0].image.guid"
                                 imgAlt=""
                                 imgClass="objectFitImage"
                                 :mobile="mobile" />
              </div>
            </div>
            <h3>{{ state.jsonData.case_study[0].case_study }}</h3>
            <p class="portfolio__feature--sm__description">
              {{ state.jsonData.case_study[0].content[0].banner_image_text[0].large }}
            </p>
            <a class="button button--purple button--feature"
               id="featureButton"
               @click="goToItem(state.jsonData.case_study[0].link,'work-item' )">

              <span>explore</span>
            </a>
          </div>
        </div>
        <div class="portfolio__news" id="portfolioNews">
          <svg xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 430.52 408.158"
               id="svgGroup4">
            <g>
              <path id="svgGroup4__path3"
                    class="svgpath svgpath--mist"
                    d="M204.1,204.1C92.7,204.1,2.5,294.3,2.5,405.7" />
              <path id="svgGroup4__path2"
                    class="svgpath svgpath--fuschia"
                    d="M428,2.5c0.1,53.5-21.2,104.8-59,142.5c-37.8,37.9-89.1,59.1-142.5,59" />
              <path id="svgGroup4__path1"
                    class="svgpath svgpath--green"
                    d="M95.3,133.6c72.4,72.4,189.8,72.4,262.3,0" />
            </g>
          </svg>
          <news-item v-for="(item, index) in state.jsonData.news"
                     :type="{'gradient': state.jsonData.news[index].gradient, 'curve': state.jsonData.news[index].arc}"
                     :title="state.jsonData.news[index].title"
                     :image="state.jsonData.news[index].second_image"
                     :colour="state.jsonData.news[index].gradient"
                     :brandColour="state.jsonData.news[index].arc"
                     :newsType="state.jsonData.news[index].type"
                     :newsID="state.jsonData.news[index].ID"
                     :itemLink="state.jsonData.news[index].itemLink"
                     :link="state.jsonData.news[index].link"
                     :mobile="mobile">
          </news-item>
        </div>
        <image-reveal :dynamicWidth="window.innerWidth"
                      :title="state.jsonData.case_study2[0].case_study"
                      :description="state.jsonData.case_study2[0].content[0].banner_image_text[0].large"
                      :beforeImage="state.jsonData.slider_images[0].guid"
                      :afterImage="state.jsonData.slider_images[1].guid"
                      :caseStudyId="state.jsonData.case_study2[0].ID"
                      :caseStudyLink="state.jsonData.case_study2[0].link">
        </image-reveal>
        <div class="svgWrapper">
          <svg xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 595.514 719.537"
               id="svgGroup5">
            <g>
              <path id="svgGroup5__path1"
                    class="svgpath svgpath--fuschia"
                    d="M2.5,2.5C2.4,42.4,18.3,80.7,46.6,108.9c28.1,28.2,66.4,44.1,106.3,44" />
              <path id="svgGroup5__path2"
                    class="svgpath svgpath--green"
                    d="M55.1,100.3c54,54,141.6,54,195.7,0" />
              <path id="svgGroup5__path3"
                    class="svgpath svgpath--mist"
                    d="M169.6,152.9c83.1,0,150.4,67.3,150.4,150.4v145.1l0-43.3v53.4c0,0,0,0,0,0
                            c0,71.4,57.9,129.3,129.3,129.3" />
              <path id="svgGroup5__path4"
                    class="svgpath svgpath--fuschia"
                    d="M463.8,587.8c71.4,0,129.3,57.9,129.3,129.3l0,0" />
              <path id="svgGroup5__path5"
                    class="svgpath svgpath--green"
                    d="M441,600.3c-23.3,4.5-44.6,15.9-61.4,32.7" />
              <path id="svgGroup5__path6"
                    class="svgpath svgpath--mist"
                    d="M169.6,152.9c83.1,0,150.4,67.3,150.4,150.4v70" />
            </g>
          </svg>
        </div>
      </section>
      <section class="people" id="peopleSection">
        <div class="people__intro">
          <h2>Our people</h2>
          <p>{{ state.jsonData.people.content }}</p>
          <img src="../assets/drag2.svg" alt="Drag Icon" />
        </div>
        <carousel id="peopleScroll" ref="people_slick"
                  :items-to-show="state.slickOptions.itemsToShow"
                  :breakpoints="state.slickOptions.breakpoints"
                  :snap-align="state.slickOptions.snapAlign"
                  :wrap-around="state.slickOptions.wrapAround"
                  class="carousel carousel--people" aria-hidden="null">
          <slide v-for="item in state.jsonData.people" class="carousel__slide carousel__slide--people" :key="item" aria-hidden="null">
            <div class="carousel__slide__info">
              <div class="carousel__slide__info__wrapper">
                <h3>{{ item.name }}</h3>
                <span>{{ item.job_title }}</span>
              </div>
            </div>
            <div class="carousel__slide__imgwrapper">
              <picture-sources v-if="item && item.image.sizes && item.image.sizes.length"
                               :targetWidth="window.innerWidth <= mobile ? 100 : 50"
                               :sizes="item.image.sizes"
                               :imgSrc="item.image.guid"
                               imgAlt="item.name"
                               imgClass="objectFitImage"
                               :mobile="mobile" />
              <router-link :to="'/people/' + item.link"
                           class="button button--purple button--purple-carousel">more</router-link>
              <!-- <a :href="'/people#' + item.link" class="button button--white">meet</a> -->
            </div>
          </slide>
        </carousel>
        <div class="svgWrapper">
          <svg xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 334 894.1"
               id="svgGroup6">
            <g>
              <path id="svgGroup6__path3"
                    class="svgpath svgpath--mist"
                    d="M220.9,512.4c-117.6,0-213,95.3-213,213c-0.1,62.3,27.2,121.5,74.5,161.9" />
              <path id="svgGroup6__path2"
                    class="svgpath svgpath--green"
                    d="M232.4,314.6c25.2,0,49.4,10,67.2,27.8c37.1,37.1,37.1,97.3,0,134.3l0,0" />
              <path id="svgGroup6__path1"
                    class="svgpath svgpath--mist"
                    d="M16.2,5.3v158.9c0,83.1,67.3,150.4,150.4,150.4l0,0h52.3" />
              <path id="svgGroup6__path4"
                    class="svgpath svgpath--fuschia"
                    d="M7.9,725.4c-0.1,62.3,27.2,121.5,74.5,161.9" />
              <g transform="translate(228 6344)">
                <path id="svgGroup6__path5"
                      class="svgpath svgpath--mist"
                      d="M-178.7-5679.2c20.9-87.4,98.7-152.3,191.4-152.3" />
                <path id="svgGroup6__path6"
                      class="svgpath svgpath--fuschia"
                      d="M-183.1-5618.6c-4.1-58,17.2-114.9,58.4-156" />
              </g>
            </g>
          </svg>
        </div>
        <!--<span class="people__prompt">drag for more</span>-->
        <a class="button button--purple"
           id="buttonPeople"
           @click="goToItem(state.jsonData.site_details.menu_items.header[4].menu_item.toLowerCase(), 'people-page')">
          <span>Key people</span>
        </a>
      </section>

      <section class="logos" id="logosSection">
        <div id="logos_intro" class="content-wrap">
          <h2>{{ state.jsonData.client_content.heading }}</h2>
          <div v-html="state.jsonData.client_content.introduction" />
        </div>
        <div ref="client_slick"
             :items-to-show="state.slickOptions2.itemsToShow"
             :breakpoints="state.slickOptions2.breakpoints"
             :snap-align="state.slickOptions2.snapAlign"
             :wrap-around="state.slickOptions2.wrapAround"
             class="carousel carousel--logos carousel--slidetext">
          <div v-for="index in totalLogoSlides" :key="index" aria-hidden="null">
            <client-logo v-for="item in getLogosForSlide(index)" :item="item" :window="state.window" :tablet="state.tablet" :mobile="state.mobile"></client-logo>
          </div>
        </div>

      </section>

      <section class="svgWrapper" v-if="state.boardJobs.length">
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 232.975 733.041"
             id="svgGroup7">
          <g>
            <path id="svgGroup7__path8"
                  class="svgpath svgpath--fuschia"
                  d="M84.6,338.2v192.6" />
            <path id="svgGroup7__path7"
                  class="svgpath svgpath--mist"
                  d="M231.2,2.9c-97.3,18.3-171,103.7-171,206.3v312.9" />
          </g>
          <g>
            <path id="svgGroup7__path1"
                  class="svgpath svgpath--mist"
                  d="M231.5,2.9c-97.3,18.3-171,103.7-171,206.3V731" />
            <path id="svgGroup7__path2"
                  class="svgpath svgpath--fuschia"
                  d="M3.5,54.9c49.6,39.8,78.4,100,78.3,163.6v53.7" />
            <path id="svgGroup7__path6"
                  class="svgpath svgpath--fuschia"
                  d="M60.5,562.2v144" />
            <path id="svgGroup7__path4"
                  class="svgpath svgpath--fuschia"
                  d="M97.7,90c-24.3,35-37.2,76.7-37.2,119.3v102.8" />
            <path id="svgGroup7__path5"
                  class="svgpath svgpath--fuschia"
                  d="M84.9,368.2v256.7" />
            <path id="svgGroup7__path3"
                  class="svgpath svgpath--green"
                  d="M218.4,18c-59,18.4-106.9,61.9-131,118.8c-10.9,25.9-16.5,53.7-16.5,81.7v265.6" />
          </g>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 109.6 287.9"
             id="svgGroup7--tablet">
          <g transform="translate(107.313 2.336) rotate(90)">
            <path class="svgpath svgpath--mist"
                  d="M0,0c9.4,49.4,52.6,85,102.9,85h143.4" />
            <path class="svgpath svgpath--fuschia"
                  d="M134.5,65.5h-26.7c-31.6,0-61.5,14.3-81.3,39" />
            <path class="svgpath svgpath--fuschia"
                  d="M43,67c17.6,11.8,38.4,18.1,59.6,18H154" />
            <path class="svgpath svgpath--fuschia" d="M250.2,64H182" />
            <path class="svgpath svgpath--green"
                  d="M10.5,1.5c9.2,29.2,30.8,53,59.1,64.8c12.9,5.4,26.7,8.2,40.7,8.2h173.3" />
          </g>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 112.6 216.3"
             id="svgGroup7--mobile">
          <g transform="translate(107.313 2.336) rotate(90)">
            <path class="svgpath svgpath--mist"
                  d="M0,0c9.4,49.4,52.6,85,102.9,85H173" />
            <path class="svgpath svgpath--fuschia"
                  d="M134.5,68.5h-26.7c-31.6,0-61.5,14.3-81.3,39" />
            <path class="svgpath svgpath--fuschia"
                  d="M43,67c17.6,11.8,38.4,18.1,59.6,18H154" />
            <path class="svgpath svgpath--fuschia" d="M211.9,67H182" />
            <path class="svgpath svgpath--green"
                  d="M10.5,4.5c9.2,29.2,30.8,53,59.1,64.8c12.9,5.4,26.7,8.2,40.7,8.2h79.6" />
          </g>
        </svg>
      </section>
      <section v-if="state.boardJobs.length" class="jobs" id="jobsSection">
        <div class="jobs__intro">
          <div class="jobs__intro__description">
            <h2>Working with us</h2>
            <p>{{ state.jsonData.jobs.content }}</p>
          </div>
          <!--<img class="drag-icon" src="/src/assets/drag2.svg" alt="Drag Icon">-->
        </div>
        <carousel id="jobScroll"
                  ref="job_slick"
                  :options="slickOptions"
                  :items-to-show="state.slickOptions.itemsToShow"
                  :breakpoints="state.slickOptions.breakpoints"
                  :snap-align="state.slickOptions.snapAlign"
                  :wrap-around="state.slickOptions.wrapAround"
                  class="carousel carousel--jobs">
          <slide v-for="job in boardJobs.slice(0, 4)"
                 class="carousel__slide carousel__slide--jobs" :key="job">
            <div :style="{backgroundColor: job.job_company.colour}"
                 class="carousel__slide carousel__slide--jobs__wrapper">
              <span>
                {{ job.job_company.name }}, {{
 (job.city && job.city.length) ?
              job.city : ''
                }}
              </span>
              <h3>{{ job.title }}</h3>
              <p v-html="job.content_short"></p>
              <a @click="openJob(job.job_board, job.id, slugTransform(job.title))"
                 class="button button--white"
                 :style="{color: job.job_company.colour}">view position</a>
            </div>
          </slide>
        </carousel>
        <div class="svgWrapper">
          <svg xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 316.102 476.352"
               id="svgGroup8">
            <g transform="translate(314.102 2) rotate(180)">
              <path id="svgGroup8__path1"
                    class="svgpath svgpath--mist"
                    d="M0,0V158.917A150.406,150.406,0,0,1-150.407,309.324h-52.322"
                    transform="translate(0.426 -0.426) rotate(-180)" />
              <path id="svgGroup8__path2"
                    class="svgpath svgpath--fuschia"
                    d="M0,0A94.7,94.7,0,0,0-67.175,27.825a95,95,0,0,0,0,134.351"
                    transform="translate(216.676 -309.75) rotate(-180)" />
            </g>
          </svg>
          <router-link class="button button--purple button--main" :to="{ name: 'jobs-page' }">
            see all<br />jobs
          </router-link>
          <!-- <a class="button button--purple button--main" @click="goToItem(jsonData.site_details.menu_items.header[6].menu_item.toLowerCase(), 'jobs-page')">

           </a>-->
        </div>
      </section>
    </main>
  </div>

</template>
<script>
  import { Carousel, Slide } from 'vue3-carousel';
  import ModalComponent from './widgets/modal-component';
  import { TweenMax, Elastic, TimelineMax, Power4, Linear } from 'gsap';
  import { api } from '../utils/api-gateway';
  import { useRoute, useRouter } from "vue-router";
  import HeaderBar from './widgets/header-bar';
  import ClientLogo from './widgets/client-logo';
  import Slider from './widgets/slider';
  import FooterComponent from './widgets/footer-component';
  import NewsItem from './widgets/news-item';
  import ImageReveal from './widgets/image-reveal';
  import "custom-event-polyfill";
  import SlideEvent from 'slide-event'
  import PictureSources from './widgets/picture-sources';
  import LoadingSpinner from './widgets/loading-spinner';
  import EventBus from '../eventbus.js';
  import { getCookie } from "../utils/cookies";
  import { computed, nextTick, ref, reactive, onUpdated } from "vue";
  import { slugify } from "../utils/slugify";
  import MyPluginCPS from '../plugins/MyPluginCPS.js';
  import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
  import 'vue3-carousel/dist/carousel.css';
  import { useInjects } from '../utils/globals-inject';

  export default {
    name: 'HomePage',
    props: {
      width: Number
    },
    components: {
      //Slick,
      ModalComponent,
      ClientLogo,
      Slider,
      FooterComponent,
      TweenMax,
      NewsItem,
      ImageReveal,
      Carousel,
      Slide,
      SlideEvent,
      PictureSources,
      LoadingSpinner,
      HeaderBar
    },
    setup() {
      const route = useRoute();

      const router = useRouter();
      let { window, desktop, mobile, tablet, mouseX, mouseY, isIE, backend, hubspotSlug } = useInjects();
      let { svgAnimate, goToItem } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);

      const state = reactive({
        //loading: true,
        jsonData: [],
        homeVideo: null,
        isModalVisible: false,
        slickOptions: { /* PEOPLE / JOBS */
          // Options can be used from the plugin documentation
          swipeToSlide: true,
          slidesToShow: 2,
          dots: false,
          infinite: false,
          cssEase: 'linear',
          centerMode: false,
          initialSlide: 0,
          prevArrow: false,
          nextArrow: false,
          swipe: true,
          draggable: true,
          verticalSwiping: false,
          responsive: [
            {
              breakpoint: 982,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        },
        slickOptions2: { /* CLIENT LOGOS */
          itemsToShow: 1,
          swipeToSlide: true,
          dots: true,
          infinite: false,
          cssEase: 'linear',
          initialSlide: 0,
          prevArrow: false,
          nextArrow: false,
          autoplay: false,
          autoplaySpeed: 4000,
          swipe: true,
          draggable: true,
          responsive: [
            {
              breakpoint: 982,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        },
        //jobs: jobs,
        logosPerSlideDesktop: 99,
        logosPerSlideTablet: 9,
        logosPerSlideMobile: 6,
        isVisible: true,
        active: null,
        selectedItem: 0,
        // isIE: /MSIE|Trident/.test(window.navigator.userAgent),
        showIframe: true,
        iframeHeight: 0,
        boardJobs: [],
        jobsLocations: [],
        jobsAgencies: [],
        headerImageArr: [],
        headerImage: null,
        blobColours: ['greenBlob', 'violetBlob', 'gravityBlob'],
        float: ['vertical', 'side'],
        blob1Style: {}
      });



      const isLoaded = computed(() => {
        if (state.jsonData.hasOwnProperty('content')) {
          return true;
        }
        return false;
      });

      const totalLogoSlides = computed(() => {
        if (window.innerWidth > tablet) {
          return Math.ceil(state.jsonData.clients.length / state.logosPerSlideDesktop);
        } else if (window.innerWidth > mobile) {
          return Math.ceil(state.jsonData.clients.length / state.logosPerSlideTablet);
        } else {
          return Math.ceil(state.jsonData.clients.length / state.logosPerSlideMobile);
        }
      });

      const toggleIframe = (e) => {

        var iframe = document.getElementById('header-animation');

        if (!state.iframeHeight && state.showIframe)
          state.iframeHeight = iframe.offsetHeight;

        if (window.innerWidth < tablet) {
          state.showIframe = false;
        }
        else {
          if (window.scrollY > state.iframeHeight) {
            if (state.showIframe)
              state.showIframe = false;
          } else {
            if (!state.showIframe) {
              state.showIframe = true;
              //this.$nextTick(() => this.initAnimation());
            }
          }
        }
      };

      const next = () => {
        this.$refs.slick.next();
      };

      const prev = () => {
        this.$refs.slick.prev();
      };

      const reInit = () => {
        // Helpful if you have to deal with v-for to update dynamic lists
        this.$refs.slick.reSlick();
      };

      const visibilityChanged = (isVisible, entry) => {
        state.isVisible = isVisible
      };

      const getLogosForSlide = (slide) => {
        let offset;
        let items;

        if (window.innerWidth > tablet) {
          offset = (slide - 1) * state.logosPerSlideDesktop;
          items = state.jsonData.clients.slice(offset, offset + state.logosPerSlideDesktop);
        } else if (window.innerWidth > mobile) {
          offset = (slide - 1) * state.logosPerSlideTablet;
          items = state.jsonData.clients.slice(offset, offset + state.logosPerSlideTablet);
        } else {
          offset = (slide - 1) * state.logosPerSlideMobile;
          items = state.jsonData.clients.slice(offset, offset + state.logosPerSlideMobile);
        }
        return items;
      };

      const activeHover = (index) => {
      };

      const mouseOver = (index) => {
        if (window.innerWidth >= desktop) {
          state.active = index
        }
      };

      const mouseOut = () => {
        if (window.innerWidth >= desktop) {
          state.active = null
        }
      };

      const handleSwipe = (event, slick, direction) => {

      };

      const openJob = (board, job, link) => {
        router.push({
          name: 'job-item',
          params: {
            board: board,
            job: job,
            title: link
          }
        })
      };

      const slugTransform = (str) => {
        var slug = slugify(str);
        return slug;
      };

      const oldMounted = () => {

        var scriptI = 0;
        state.homeVideo = jwplayer("homeVideo");
        if (typeof state.homeVideo.setup === 'function') {
          state.homeVideo.setup({
            //file: this.jsonData.showreel.video,
            width: "100%",
            aspectratio: "16:9",
            file: "https://cdn.jwplayer.com/manifests/" + state.jsonData.showreel.code + ".m3u8",
            image: "https://content.jwplatform.com/thumbs/" + state.jsonData.showreel.code + ".jpg",
            customProps: { skin: { url: './css/player.css', name: 'svp' }, controls: true }
          });
        }
        let objectFitImages = require('object-fit-images');

        // Object-fit fix for IE
        objectFitImages('img.objectFitImage');

        function getTransformValues(transform) {
          var results = transform.match(/translate3d\(\s*([^ ,]+)\s*,\s*([^ ,]+)\s*,\s*([^ )]+)\s*\)/)

          if (!results) return [0, 0, 0];

          return [
            parseInt(results[1].replace('px', ''), 10),
            parseInt(results[2].replace('px', ''), 10),
            parseInt(results[3].replace('px', ''), 10),
          ];
        }

        var matches = document.querySelectorAll('.slick-list');

        if (matches.length) {
          matches.forEach(function (slickList) {

            slickList.onwheel = function (e) {

              if ((e.deltaY == 0 || e.deltaY == -0) && e.deltaX != 0 && e.deltaX != -0) {

                var slickTrack = slickList.querySelector('.slick-track');

                var transform = slickTrack.style.transform;

                var transform3d = getTransformValues(transform);

                var newX = transform3d[0] - e.deltaX;

                var limit = (slickTrack.offsetWidth - slickList.offsetWidth) * -1;

                if (newX < 0 && newX > limit) {
                  slickTrack.style.transform = 'translate3d(' + newX + 'px,' + transform3d[1] + 'px,' + transform3d[2] + 'px)';
                }

              }

            };

          });
        }

        if (window.innerWidth >= desktop && !isIE) {

          // Animate the first 3 svg lines
          if (document.querySelector("#svgGroup1__path1")) TweenMax.from('#svgGroup1__path1', 4, { scaleY: 0 });
          if (document.querySelector("#svgGroup1__path1")) TweenMax.from('#svgGroup1__path2', 3, { scaleY: 0 });
          if (document.querySelector("#svgGroup1__path1")) TweenMax.from('#svgGroup1__path3', 2, { scaleY: 0 });

          let svgPathsHome = [
            {
              id: 'svgGroup1__path4',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: "#aboutSection",
              offset: '0'
            },
            {
              id: 'svgGroup1__path5',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: "#aboutSection",
              offset: '0'
            },
            {
              id: 'svgGroup1__path6',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: "#aboutSection",
              offset: '0'
            },
            {
              id: 'svgGroup1__path7',
              tweenId: '1',
              speed: 2,
              delay: 0,
              trigger: "#aboutSection",
              offset: '0'
            },
            {
              id: 'svgGroup3__path1',
              tweenId: '5',
              speed: 2,
              delay: 0,
              trigger: ".portfolio",
              offset: '0'
            },
            {
              id: 'svgGroup3__path2',
              tweenId: '5',
              speed: 2,
              delay: 0,
              trigger: ".portfolio",
              offset: '0'
            },
            {
              id: 'svgGroup3__path3',
              tweenId: '5',
              speed: 2,
              delay: 0,
              trigger: ".portfolio",
              offset: '0'
            },
            {
              id: 'svgGroup3__path4',
              tweenId: '6',
              speed: 2,
              delay: 0,
              trigger: ".portfolio",
              offset: '520'
            },
            {
              id: 'svgGroup3__path5',
              tweenId: '6',
              speed: 2,
              delay: 0,
              trigger: ".portfolio",
              offset: '520'
            },
            {
              id: 'svgGroup3__path6',
              tweenId: '6',
              speed: 2,
              delay: 0,
              trigger: ".portfolio",
              offset: '520'
            },
            {
              id: 'svgGroup3__path7',
              tweenId: '7',
              speed: 2,
              delay: 0,
              trigger: ".button--feature",
              offset: '100'
            },
            {
              id: 'svgGroup3__path8',
              tweenId: '7',
              speed: 2,
              delay: 0,
              trigger: ".button--feature",
              offset: '100'
            },
            {
              id: 'svgGroup3__path9',
              tweenId: '7',
              speed: 20,
              delay: 0,
              trigger: ".button--feature",
              offset: '100'
            },
            {
              id: 'svgGroup3__path10',
              tweenId: '7',
              speed: 2,
              delay: 0,
              trigger: ".button--feature",
              offset: '100'
            },
            {
              id: 'svgGroup4__path1',
              tweenId: '8',
              speed: 2,
              delay: 0,
              trigger: ".portfolio__news",
              offset: '300'
            },
            {
              id: 'svgGroup4__path2',
              tweenId: '8',
              speed: 2,
              delay: 0,
              trigger: ".portfolio__news",
              offset: '300'
            },
            {
              id: 'svgGroup4__path3',
              tweenId: '8',
              speed: 2,
              delay: 0,
              trigger: ".portfolio__news",
              offset: '300'
            },
            {
              id: 'svgGroup5__path1',
              tweenId: '9',
              speed: 2,
              delay: 0,
              trigger: "#buttonReveal",
              offset: '-100'
            },
            {
              id: 'svgGroup5__path2',
              tweenId: '9',
              speed: 2,
              delay: 0,
              trigger: "#buttonReveal",
              offset: '-100'
            },
            {
              id: 'svgGroup5__path3',
              tweenId: '9',
              speed: 2,
              delay: 0,
              trigger: "#buttonReveal",
              offset: '-100'
            },
            {
              id: 'svgGroup5__path4',
              tweenId: '10',
              speed: 2,
              delay: 0,
              trigger: "#buttonReveal",
              offset: '300'
            },
            {
              id: 'svgGroup5__path5',
              tweenId: '10',
              speed: 2,
              delay: 0,
              trigger: "#buttonReveal",
              offset: '300'
            },
            {
              id: 'svgGroup6__path1',
              tweenId: '11',
              speed: 1.5,
              delay: 0,
              trigger: ".carousel--people",
              offset: '500'
            },
            {
              id: 'svgGroup6__path2',
              tweenId: '11',
              speed: 2,
              delay: 0,
              trigger: ".carousel--people",
              offset: '500'
            },
            {
              id: 'svgGroup6__path3',
              tweenId: '11',
              speed: 2,
              delay: 0,
              trigger: ".carousel--people",
              offset: '500'
            },
            {
              id: 'svgGroup6__path4',
              tweenId: '11',
              speed: 2,
              delay: 0,
              trigger: ".carousel--people",
              offset: '500'
            },
          ]

          if (state.boardJobs.length)
            svgPathsHome.push(
              {
                id: 'svgGroup7__path1',
                tweenId: '12',
                speed: 2,
                delay: 0,
                trigger: ".jobs",
                offset: '-400'
              },
              {
                id: 'svgGroup7__path2',
                tweenId: '12',
                speed: 2,
                delay: 0,
                trigger: ".jobs",
                offset: '-400'
              },
              {
                id: 'svgGroup7__path3',
                tweenId: '12',
                speed: 2,
                delay: 0,
                trigger: ".jobs",
                offset: '-400'
              },
              {
                id: 'svgGroup7__path4',
                tweenId: '12',
                speed: 2,
                delay: 0,
                trigger: ".jobs",
                offset: '-400'
              },
              {
                id: 'svgGroup7__path5',
                tweenId: '12',
                speed: 2,
                delay: 0,
                trigger: ".jobs",
                offset: '-400'
              },
              {
                id: 'svgGroup7__path6',
                tweenId: '12',
                speed: 2,
                delay: 0,
                trigger: ".jobs",
                offset: '-400'
              },
              {
                id: 'svgGroup7__path7',
                tweenId: '12',
                speed: 2,
                delay: 0,
                trigger: ".jobs",
                offset: '-400'
              },
              {
                id: 'svgGroup7__path8',
                tweenId: '12',
                speed: 2,
                delay: 0,
                trigger: ".jobs",
                offset: '-400'
              },
              {
                id: 'svgGroup8__path1',
                tweenId: '13',
                speed: 2,
                delay: 0,
                trigger: ".jobs__intro",
                offset: '400'
              },
              {
                id: 'svgGroup8__path2',
                tweenId: '13',
                speed: 2,
                delay: 0,
                trigger: ".jobs__intro",
                offset: '400'
              }
            )

          let svgTweens = {};

          // Loop through SVG PATHS array / build tweens and scenes
          svgAnimate(svgPathsHome, svgTweens);


          // Build tween & scene 2 (About description)
          if (document.querySelector("#about__intro__description")) {

            let tween2 = new TimelineMax()
              .add(TweenMax.from('.about__intro__description', 1, { y: 100, opacity: 0, ease: Power4.easeInOut }))
              .add(TweenMax.staggerFrom('.about__intro__logos__item', 0.7, { x: 150, opacity: 0, ease: Power4.easeInOut }, 0.5));

            let scene2 = new ScrollMagic.Scene({
              triggerElement: "#aboutSection",
            })
              .setTween(tween2)
            // .addIndicators({name: "2"})

            new ScrollMagic.Controller().addScene(scene2);
          }

          // Build tween & scene 3 (About button)
          if (document.querySelector("#aboutButton")) {
            let tween3 = new TimelineMax()
              .add(TweenMax.fromTo("#aboutButton", 0.5, { scale: 0.001, ease: Elastic.easeInOut }, { scale: 1.4, ease: Elastic.easeInOut }))
              .add(TweenMax.to("#aboutButton", 0.3, { scale: 1, delay: 0.2, ease: Elastic.easeInOut }))
              .add(TweenMax.fromTo("#aboutButton span", 0.5, { opacity: 0 }, { opacity: 1, delay: 0.2 }));

            let scene3 = new ScrollMagic.Scene({
              triggerElement: "#aboutIntro",
            })
              .setTween(tween3)
            // .addIndicators({name: "3"})

            new ScrollMagic.Controller().addScene(scene3);
          }

          // Build tween & scene 15 (Home page engagement section)
          if (document.querySelector(".engagement__heading")) {
            let tween15 = new TimelineMax()
              .add(TweenMax.from('.engagement__heading', 0.8, { y: 80, opacity: 0, ease: Linear.easeIn }))
              .add(TweenMax.from('.engagement__description p', 0.8, { y: 80, opacity: 0, ease: Linear.easeIn }))

            let scene15 = new ScrollMagic.Scene({
              triggerElement: "#engagementSection",
              triggerHook: 0.7, // show, when scrolled 30% into view
            })
              .setTween(tween15)
            // .addIndicators({name: "15"})

            new ScrollMagic.Controller().addScene(scene15);
          }

          // Build tween & scene 16 (Home page engagement section details)
          if (document.querySelector(".engagement__details")) {
            let tween16 = new TimelineMax()
              .add(TweenMax.from('.engagement__details', 0.8, { y: 50, opacity: 0, ease: Linear.easeIn }))

            let scene16 = new ScrollMagic.Scene({
              triggerElement: "#engagementSection",
              triggerHook: 0.5, // show, when scrolled 70% into view
            })
              .setTween(tween16)
            // .addIndicators({name: "16"})

            new ScrollMagic.Controller().addScene(scene16);
          }


          // Build tween & scene 17 (Portfolio section details)
          if (document.querySelector(".portfolio__feature--sm__wrapper") && (document.querySelector(".portfolio__feature--sm__wrapper h2"))) {
            let tween17 = new TimelineMax()
              .add(TweenMax.from('.portfolio__feature--sm__wrapper', 0.5, { opacity: 0, ease: Linear.easeInOut }))
              .add(TweenMax.from('.portfolio__feature--sm__wrapper h2', 0.7, { y: -50, opacity: 0, ease: Linear.easeInOut }))
              .add(TweenMax.from('.portfolio__feature--sm__wrapper h3', 0.7, { y: -50, opacity: 0, ease: Linear.easeInOut }))
              .add(TweenMax.from('.portfolio__feature--sm__description', 0.7, { y: -50, opacity: 0, ease: Linear.easeInOut }))
              .add(TweenMax.fromTo('.portfolio__feature--sm__wrapper .button--feature', 0.5, { scale: 0.001, ease: Elastic.easeInOut }, { scale: 1.4, ease: Elastic.easeInOut }))
              .add(TweenMax.to('.portfolio__feature--sm__wrapper .button--feature', 0.3, { scale: 1, delay: 0.2, ease: Elastic.easeInOut }))
              .add(TweenMax.fromTo('.portfolio__feature--sm__wrapper .button--feature span', 0.5, { opacity: 0 }, { opacity: 1, delay: 0.2 }));

            let scene17 = new ScrollMagic.Scene({
              triggerElement: "#portfolioSection"
            })
              .setTween(tween17)
            // .addIndicators({name: "17"})

            new ScrollMagic.Controller().addScene(scene17);
          }


          // Build tween & scene 18 (News item fade up)
          if (document.querySelector(".newsItem")) {
            let tween18 = new TimelineMax()
              .add(TweenMax.from('.newsItem', 1, { y: 50, opacity: 0, ease: Linear.easeIn }))

            let scene18 = new ScrollMagic.Scene({
              triggerElement: "#portfolioNews",
              offset: '-200'
            })
              .setTween(tween18)
            // .addIndicators({name: "18"})

            new ScrollMagic.Controller().addScene(scene18);
          }


          // Build tween 19 (News item fade up)
          if (document.querySelector(".button--portfolio")) {
            let tween19 = new TimelineMax()
              .add(TweenMax.from('.newsItem h2', 0.5, { y: 20, opacity: 0, ease: Linear.easeIn }))
              .add(TweenMax.from('.newsItem h3', 0.5, { y: 20, opacity: 0, ease: Linear.easeIn }))
              .add(TweenMax.fromTo('.button--portfolio', 0.3, { scale: 0.001, ease: Elastic.easeInOut }, { scale: 1.4, ease: Elastic.easeInOut }))
              .add(TweenMax.to('.button--portfolio', 0.3, { scale: 1, delay: 0.2, ease: Elastic.easeInOut }))
              .add(TweenMax.fromTo('.button--portfolio span', 0.5, { opacity: 0 }, { opacity: 1, delay: 0.2 }));

            let scene19 = new ScrollMagic.Scene({
              triggerElement: "#portfolioNews"
            })
              .setTween(tween19)
            // .addIndicators({name: "19"})

            new ScrollMagic.Controller().addScene(scene19);
          }


          // Build tween & scene 20 (Image reveal)
          if (document.querySelector("#imageReveal")) {
            let tween20 = new TimelineMax()
              .add(TweenMax.from('#imageReveal', 1, { y: 50, opacity: 0, ease: Linear.easeIn }))

            let scene20 = new ScrollMagic.Scene({
              triggerElement: "#portfolioNews",
              offset: '400'
            })
              .setTween(tween20)
            // .addIndicators({name: "20"})

            new ScrollMagic.Controller().addScene(scene20);
          }


          // Build tween & scene 21 (Image Reveal button)
          if (document.querySelector("#buttonReveal")) {
            let tween21 = new TimelineMax()
              .add(TweenMax.fromTo("#buttonReveal", 0.5, { scale: 0.001, ease: Elastic.easeInOut }, { scale: 1.4, ease: Elastic.easeInOut }))
              .add(TweenMax.to("#buttonReveal", 0.3, { scale: 1, delay: 0.2, ease: Elastic.easeInOut }))
              .add(TweenMax.fromTo("#buttonReveal span", 0.5, { opacity: 0 }, { opacity: 1, delay: 0.2 }));

            let scene21 = new ScrollMagic.Scene({
              triggerElement: ".imageReveal__info",
            })
              .setTween(tween21)
            // .addIndicators({name: "21"})

            new ScrollMagic.Controller().addScene(scene21);
          }

          // Build tween and scene 22 (People section)
          if (document.querySelector("#peopleSection")) {
            let tween22 = new TimelineMax()
              .add(TweenMax.from('#peopleSection h2', 0.5, { y: 30, opacity: 0, ease: Linear.easeIn }))
              .add(TweenMax.from('#peopleSection p', 0.5, { y: 30, opacity: 0, ease: Linear.easeIn }))

            let scene22 = new ScrollMagic.Scene({
              triggerElement: "#peopleSection"
            })
              .setTween(tween22)
            // .addIndicators({name: "22"})

            new ScrollMagic.Controller().addScene(scene22);
          }


          // Build tween 23 (People slider)
          if (document.querySelector(".slick-slide .carousel__slide--people") && (document.querySelector(".people__intro"))) {
            let tween23 = new TimelineMax()
              .add(TweenMax.from('.slick-slide .carousel__slide--people img', 0.5, { y: 50, opacity: 0, ease: Linear.easeIn }))
              .add(TweenMax.from('.slick-slide .carousel__slide--people .carousel__slide__info', 0.5, { y: 80, opacity: 0, ease: Linear.easeIn }))
              .add(TweenMax.fromTo('.slick-slide .carousel__slide--people .button', 0.5, { scale: 0.001, ease: Elastic.easeInOut }, { scale: 1.4, ease: Elastic.easeInOut }))
              .add(TweenMax.to('.slick-slide .carousel__slide--people .button', 0.3, { scale: 1, delay: 0.2, ease: Elastic.easeInOut }))
              .add(TweenMax.fromTo('.slick-slide .carousel__slide--people .button span', 0.5, { opacity: 0 }, { opacity: 1, delay: 0.2 }))


            let scene23 = new ScrollMagic.Scene({
              triggerElement: ".people__intro",
              offset: '100'
            })
              .setTween(tween23)
            // .addIndicators({name: "23"})

            new ScrollMagic.Controller().addScene(scene23);
          }


          // Build tween and scene 24 (Key people button)
          if (document.querySelector("#buttonPeople")) {
            let tween24 = new TimelineMax()
              .add(TweenMax.fromTo('#buttonPeople', 0.5, { scale: 0.001, ease: Elastic.easeInOut }, { scale: 1.4, ease: Elastic.easeInOut }))
              .add(TweenMax.to('#buttonPeople', 0.3, { scale: 1, delay: 0.2, ease: Elastic.easeInOut }))
              .add(TweenMax.fromTo('#buttonPeople span', 0.5, { opacity: 0 }, { opacity: 1, delay: 0.2 }));

            let scene24 = new ScrollMagic.Scene({
              triggerElement: ".carousel__slide--people",
              offset: '400'
            })
              .setTween(tween24)
            // .addIndicators({name: "24"})

            new ScrollMagic.Controller().addScene(scene24);
          }


          // Build tween and scene 25 (Logos section)
          if (document.querySelector(".logos ul li:nth-child(-n+5)")) {
            let tween25 = new TimelineMax()
              .add(TweenMax.from('.logos ul li:nth-child(-n+5)', 0.5, { y: 50, opacity: 0, ease: Linear.easeIn }))

            let scene25 = new ScrollMagic.Scene({
              triggerElement: ".logos ul li:first-child",
              offset: '-300'
            })
              .setTween(tween25)

            new ScrollMagic.Controller().addScene(scene25);
          }

          // Build tween and scene 25b (Logos section)
          if (document.querySelector(".logos ul li:nth-child(6)")) {
            let tween25b = new TimelineMax()
              .add(TweenMax.from('.logos ul li:nth-child(6), .logos ul li:nth-child(7), .logos ul li:nth-child(8), .logos ul li:nth-child(9), .logos ul li:nth-child(10)', 0.5, { y: 50, opacity: 0, ease: Linear.easeIn }))

            let scene25b = new ScrollMagic.Scene({
              triggerElement: ".logos ul li:nth-child(6)",
              offset: '-300'
            })
              .setTween(tween25b)

            new ScrollMagic.Controller().addScene(scene25b);
          }

          // Build tween and scene 25c (Logos section)
          if (document.querySelector(".logos ul li:nth-child(11)")) {
            let tween25c = new TimelineMax()
              .add(TweenMax.from('.logos ul li:nth-child(11), .logos ul li:nth-child(12), .logos ul li:nth-child(13), .logos ul li:nth-child(14), .logos ul li:nth-child(15)', 0.5, { y: 50, opacity: 0, ease: Linear.easeIn }))

            let scene25c = new ScrollMagic.Scene({
              triggerElement: ".logos ul li:nth-child(11)",
              offset: '-300'
            })
              .setTween(tween25c)

            new ScrollMagic.Controller().addScene(scene25c);
          }


          // Build tween and scene 25d (Logos section)
          if (document.querySelector(".logos ul li:nth-child(16)")) {
            let tween25d = new TimelineMax()
              .add(TweenMax.from('.logos ul li:nth-child(16), .logos ul li:nth-child(17), .logos ul li:nth-child(18), .logos ul li:nth-child(19), .logos ul li:nth-child(20)', 0.5, { y: 50, opacity: 0, ease: Linear.easeIn }))

            let scene25d = new ScrollMagic.Scene({
              triggerElement: ".logos ul li:nth-child(16)",
              offset: '-300'
            })
              .setTween(tween25d)

            new ScrollMagic.Controller().addScene(scene25d);
          }


          // Build tween and scene 26 (Jobs section)
          if (document.querySelector(".jobs__intro__description h2")) {
            let tween26 = new TimelineMax()
              .add(TweenMax.from('.jobs__intro__description h2', 0.8, { y: 50, opacity: 0, ease: Linear.easeIn }))
              .add(TweenMax.from('.jobs__intro__description p', 0.8, { y: 50, opacity: 0, ease: Linear.easeIn }))
              .add(TweenMax.from('.jobs__intro__categories', 0.8, { y: 50, opacity: 0, ease: Linear.easeIn }))

            let scene26 = new ScrollMagic.Scene({
              triggerElement: "#jobsSection",
              offset: '50'
            })
              .setTween(tween26)
            // .addIndicators({name: "26"})

            new ScrollMagic.Controller().addScene(scene26);
          }


          // Build tween 27 (Jobs slider)
          if (document.querySelector(".slick-slide .carousel__slide--jobs")) {
            let tween27 = new TimelineMax()
              .add(TweenMax.from('.slick-slide .carousel__slide--jobs', 0.5, { y: 50, opacity: 0, ease: Linear.easeIn }))
              .add(TweenMax.fromTo('.slick-slide .carousel__slide--jobs .button', 0.5, { scale: 0.001, ease: Elastic.easeInOut }, { scale: 1.4, ease: Elastic.easeInOut }))
              .add(TweenMax.to('.slick-slide .carousel__slide--jobs .button', 0.3, { scale: 1, delay: 0.2, ease: Elastic.easeInOut }))
              .add(TweenMax.fromTo('.slick-slide .carousel__slide--jobs .button span', 0.5, { opacity: 0 }, { opacity: 1, delay: 0.2 }))

            let scene27 = new ScrollMagic.Scene({
              triggerElement: "#jobsSection .jobs__intro__description p"
            })
              .setTween(tween27)
            // .addIndicators({name: "27"})

            new ScrollMagic.Controller().addScene(scene27);
          }


          // Build tween 28 (Jobs button)
          if (document.querySelector("#jobsSection .button--main")) {
            let tween28 = new TimelineMax()
              .add(TweenMax.fromTo('#jobsSection .button--main', 0.5, { scale: 0.001, ease: Elastic.easeInOut }, { scale: 1.4, ease: Elastic.easeInOut }))
              .add(TweenMax.to('#jobsSection .button--main', 0.3, { scale: 1, delay: 0.2, ease: Elastic.easeInOut }))
              .add(TweenMax.fromTo('#jobsSection .button--main span', 0.5, { opacity: 0 }, { opacity: 1, delay: 0.2 }));

            let scene28 = new ScrollMagic.Scene({
              triggerElement: "#jobsSection .carousel",
              offset: '400'
            })
              .setTween(tween28)
            // .addIndicators({name: "28"})

            new ScrollMagic.Controller().addScene(scene28);
          }
        }
      };

      const plugin = document.createElement("script");

      /*plugin.setAttribute(
        'src',
        '//js.hs-scripts.com/' + this.$root.hubSpot +'.js'
      );*/
      plugin.async = true;
      document.body.appendChild(plugin);
      if (getCookie('cookieConsent') === 'yes') {
        hubspotSlug(route.fullPath);
      }

      let url;
      //backend = backend.replace("//be", "/be");

      if (route.params.num) {
        state.preview_num = route.params.num;
        url = backend + 'preview/page/' + state.preview_num + '/home';
      } else {
        state.name = route.params.link;
        url = backend + 'home-page/';
      }

      api(url)
        .then((data) => {
          state.jsonData = data.data;

          nextTick().then(() => {
            oldMounted();
            EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
            // EventBus.$emit('header_menu_items', this.jsonData.site_details.menu_items.header);
            // EventBus.$emit('footer_menu_items', this.jsonData.site_details.menu_items.footer);
            EventBus.$emit('isLoaded', true);
            EventBus.$emit('footer', state.jsonData.site_details.tceg);
            EventBus.$emit('isPurple', false);
            EventBus.$emit('statement', state.jsonData.site_details.footer_statement);

            state.jsonData.banner_images.push(state.jsonData.banner_live_image);

            state.headerImage = state.jsonData.banner_images[0].guid;

            let i = 1;
            setInterval(function () {
              if (state.jsonData.banner_images[i] !== undefined) {
                state.headerImage = state.jsonData.banner_images[i].guid;
              }
              i++;
              if (i === state.jsonData.banner_images.length) i = 0;
            }, 2500);
            if (window.innerWidth < tablet)
              state.showIframe = false;


          });

        }).catch(error => {
          //this.loading = false;
        });

      return {
        state,
        window,
        desktop,
        mobile,
        tablet,
        isIE,
        isLoaded,
        mouseOver,
        mouseOut,
        next,
        prev,
        reInit,
        handleSwipe,
        visibilityChanged,
        slugify,
        activeHover,
        getLogosForSlide,
        toggleIframe,
        slugTransform,
        totalLogoSlides,
        svgAnimate,
        goToItem,
        openJob
      };
    }
  }</script>
