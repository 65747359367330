<template>
  <div v-if="state.isLoaded">
    <div id="burger" class="burger"
         @click="toggleMenu">
      <div class="burger__lines">
        <div class="burger__lines__top" v-bind:class="{purple: state.isPurple }"></div>
        <div class="burger__lines__mid" v-bind:class="{purple: state.isPurple }"></div>
        <div class="burger__lines__bottom" v-bind:class="{purple: state.isPurple }"></div>
      </div>
    </div>

    <div class="menu-wrap">
      <div class="menu-wrap__menu-blob"></div>
    </div>
    <ul class="menu">
      <li v-for="item in state.menu_items.header" class="menu__item" @click="closeMenu">
        <router-link :to="{path: (item.menu_item !== 'Home') ? '/'+item.link : item.link}" class="menu__item__link" active-class="active" style="cursor: pointer">
          {{ item.menu_item }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script setup>
const emit = defineEmits([
  'isLoaded',
  'menu_items',
  'footer',
  'statement'
])

  import { TweenMax, Back, Elastic } from 'gsap';
  import EventBus from '../../eventbus.js';
  import {onMounted, reactive} from "vue";

  let menuOpen = false;
  let topBg, bottomBg = '';

  /*export default {
    name: 'Navigation',
    components: {
      TweenMax
    },
    props: {
      type: Object
    },
    data() {
      return {

      }
    },
    setup(){*/
      const state = reactive({
        isLoaded: false,
        menu_items: '',
        isPurple: false
      });
      const loaded = (value) => {
        state.isLoaded = value;
      };
      const details = (value) => {
        state.menu_items = value;
      };
      const purple = (value) => {
        state.isPurple = value;
      };
      const toggleMenu = () => {
        menuOpen = !menuOpen
        if (menuOpen) {
          openMenu()
        } else {
          closeMenu(state.scrollPos)
        }
      };

  const openMenu = () => {
        let button = document.getElementById('burger');
        state.burgerButtonHeight = button.clientHeight;
        state.burgerButtonWidth = button.clientWidth;

        TweenMax.to('#burger', .1, { height: state.burgerButtonWidth * 0.83 });

        //Only execute if the elements have rendered.
    if (document.getElementsByClassName('burger__lines__top').length != 0) {
          let top = document.getElementsByClassName('burger__lines__top');
          topBg = top[0].style.backgroundColor;
          let bottom = document.getElementsByClassName('burger__lines__bottom');
          bottomBg = bottom[0].style.backgroundColor;
          TweenMax.to('.burger__lines__top', .5, { backgroundColor: '#451284', delay: .1, rotation: -45, width: 35, ease: Back.easeInOut, ease: "elastic.out(3, 1)" })
          TweenMax.to('.burger__lines__mid', .1, { delay: .3, opacity: 0 })
          TweenMax.to('.burger__lines__bottom', .5, { backgroundColor: '#451284', delay: .1, rotation: 45, ease: Back.easeInOut, ease: "elastic.out(3, 1)" })
          TweenMax.to('.menu-wrap__menu-blob', .5, { height: '2500px', width: '2500px', marginRight: '-175px', marginTop: '-680px', onComplete: showMenu });
        }

        state.scrollPos = document.documentElement.scrollTop;

        setTimeout(function () {
          document.getElementsByTagName('body')[0].classList.add("noScroll");
        }, 600);

        //CU 16/1/24 Commented out - because it breaks the burger menu on the location and news page
        if (document.URL.includes('contact') || document.URL.includes('news/')) {
          //TweenMax.to('.burger__lines__top', .5, { className: '-=cont -=news' });
          //TweenMax.to('.burger__lines__bottom', .5, { className: '-=cont -=news' });
        }
      };
      const closeMenu = (scrollTo = 0) => {
        state.blobDiv.style.backgroundPosition = '';
        state.menuDiv.removeEventListener("mousemove", setBlobPos);

        TweenMax.to('#burger', .1, { height: state.burgerButtonHeight });

        //Only execute if the elements have rendered.
        if (document.getElementsByClassName('burger__lines__top').length != 0) {
          TweenMax.to('.burger__lines__top', .75, { rotation: 0, ease: Elastic.easeInOut, width: 31, ease: "elastic.out(3, 1)", backgroundColor: topBg })
          TweenMax.to('.burger__lines__mid', .2, { delay: .2, opacity: 1, backgroundColor: topBg })
          TweenMax.to('.burger__lines__bottom', .75, { rotation: 0, ease: Elastic.easeInOut, ease: "elastic.out(3, 1)", backgroundColor: bottomBg })
          TweenMax.to('.menu', .1, { display: 'none' });
          TweenMax.to('.menu-wrap__menu-blob', .5, { height: '1px', width: '1px', marginRight: '0px', marginTop: '0px' });
        }
        document.getElementsByTagName('body')[0].classList.remove("noScroll");

        window.scrollTo(0, scrollTo);

        //CU 16/1/24 Commented out - because it breaks the burger menu on the location and news page
        if (document.URL.includes('contact') || document.URL.includes('news/')) {
         // TweenMax.to('.burger__lines__top', .5, { className: '+=cont' });
         // TweenMax.to('.burger__lines__bottom', .5, { className: '+=cont' });
        }
        menuOpen = false;
      };
      const showMenu = () => {
        TweenMax.to('.menu', .1, { display: 'flex', autoAlpha: 1 });

        state.blobDiv = document.getElementsByClassName('menu-wrap__menu-blob')[0];
        state.menuDiv = document.getElementsByClassName('menu')[0];
        state.menuDiv.addEventListener("mousemove", setBlobPos);
      };

      const setBlobPos = (e) => {
        var blobX = e.x - 175;
        var blobY = e.y - 680;
        state.blobDiv.style.backgroundPosition = blobX + 'px ' + blobY + 'px';
      }

      EventBus.$emit('isLoaded', state.loaded)
      EventBus.$emit('menu_items', state.details)
      EventBus.$emit('isPurple', state.purple)

      onMounted(() => {
        EventBus.$on('menu_items', (...args) => {
          //console.log(args);
          if(args[0] !== undefined){
            if(state.menu_items === ""){
              state.menu_items = args[0];
            }
          }
        });
        EventBus.$on('isLoaded', (...args) => {
          state.isLoaded = args[0];
        });
        EventBus.$on('isPurple', (...args) => {
          state.isPurple = args[0];
        });
      });

      /*return {
        state,
        loaded,
        details,
        purple,
        toggleMenu,
        openMenu,
        closeMenu,
        showMenu,
        setBlobPos
      }
    },
  }*/
</script>
