<template>
  <div v-if="state.isLoaded" class="workPage">

    <HeaderBar :statement="state.jsonData.site_details.banner_statement" />

    <header-component type='work'
                      class='header--work'
                      :title=state.jsonData.content.banner_small_text
                      :title2=state.jsonData.content.banner_large_text
                      :image=state.jsonData.content.banner_image>
    </header-component>
    <main>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.9 386.1" id="svgGroup50">
        <g>
          <path id="svgGroup50__path1" class="svgpath svgpath--mist" d="M217.9,5.2c-117.7,0-213,95.4-213,213C4.8,280.5,32,339.7,79.4,380.1" />
          <path id="svgGroup50__path2" class="svgpath svgpath--fuschia" d="M4.8,218.3C4.8,280.5,32,339.7,79.4,380.1" />
        </g>
      </svg>
      <ul class="workPage__workItems">
        <li v-for="(item, index) in state.jsonData.content.case_studies"
            :key="index"
            class="workPage__workItems__item"
            @click="goToItem(item.link, 'work-item')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 244.7 494.1" id="svgGroup51" v-if="index == 0">
            <g>
              <path id="svgGroup51__path4" class="svgpath svgpath--mist" d="M128.6,262.5c61.4,0,111.2,49.7,111.2,111v115" />
              <path id="svgGroup51__path1" class="svgpath svgpath--mist" d="M4.8,4.5v145.9c0,0,0,0,0,0c0,61.4,49.8,111.1,111.2,111" />
              <path id="svgGroup51__path2" class="svgpath svgpath--fuschia" d="M128.6,261.4c61.4,0,111.2,49.8,111.2,111.2c0,0,0,0,0,0" />
              <path id="svgGroup51__path3" class="svgpath svgpath--green" d="M56.4,300.4c39.9-39.8,104.5-39.8,144.4,0" />
            </g>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 482.1 403.8" id="svgGroup52" v-if="index == 3">
            <g>
              <path id="svgGroup52__path1" class="svgpath svgpath--mist" d="M5.1,4.4c6.6,45.4,27.7,87.4,60.2,119.8c44,44.1,105.1,66.6,167.2,61.8" />
              <path id="svgGroup52__path3" class="svgpath svgpath--mist" d="M261.6,186.5c62.1-4.9,123.2,17.7,167.2,61.8" />
              <path id="svgGroup52__path2" class="svgpath svgpath--fuschia" d="M261.6,186.5c32.3-2.5,64.7,2.4,94.9,14.2" />
              <path id="svgGroup52__path6" class="svgpath svgpath--mist" d="M101.9,313c47-98.4,164.8-140.1,263.2-93.1c68.7,32.8,112.4,102.1,112.3,178.2" />
              <path id="svgGroup52__path4" class="svgpath svgpath--mist" d="M101.9,313C134.7,244.4,204,200.8,280,200.8" />
              <path id="svgGroup52__path5" class="svgpath svgpath--green" d="M140.4,258.6c77.1-77.1,202.1-77.1,279.2,0" />
            </g>
          </svg>
          <p class="workPage__workItems__item__services workPage__workItems__item__services--absolute" v-if="window.innerWidth >= state.desktop">
            <span v-for="value in item.content.services"
                  class="workPage__workItems__item__services__service"
                  :style="{color: item.left_col}">
              {{value.service}}
            </span>
          </p>
          <div class="workPage__workItems__item__imageWrapper">
            <picture-sources v-if="item.banner_image && item.banner_image.sizes && item.banner_image.sizes.length"
                             :targetWidth="100"
                             :sizes="item.banner_image.sizes"
                             :imgSrc="item.banner_image.guid"
                             :imgAlt="item.case_study"
                             imgClass="objectFitImage" />
          </div>
          <div class="workPage__workItems__item__info">
            <h2 :style="{color: item.left_col}" v-if=item.content[1]>
              {{ item.content[1].summary[0].client }}
            </h2>
            <h2 :style="{color: item.left_col}" v-if='item.content[1] == null'>
              Client Name
            </h2>
            <p class="workPage__workItems__item__info__par" v-if="item.content[0]">
              {{ item.content[0].banner_image_text[0].large }}
            </p>
            <p class="workPage__workItems__item__services" v-if="window.innerWidth < state.desktop">
              <span v-for="(value,index) in item.content.services" class="workPage__workItems__item__services__service" :style="{color: item.left_col}">
                {{value.service}}<template v-if="index !== (item.content.services.length - 1)">, </template>
              </span>
            </p>
          </div>
        </li>
      </ul>
    </main>
  </div>
</template>

<script setup>
  import EventBus from '../eventbus.js';
  import { api } from '../utils/api-gateway';
  import { nextTick, onBeforeMount, onMounted, reactive } from "vue";
  import objectFitImages from "object-fit-images";
  import HeaderComponent from './widgets/header-component';
  import ModalComponent from './widgets/modal-component';
  //import VueScrollTo from 'vue-scrollto';
  import HeaderBar from './widgets/header-bar';
  import { Carousel, Slide } from 'vue3-carousel';
  import PictureSources from './widgets/picture-sources';
  import { useRoute, useRouter } from 'vue-router';
  import MyPluginCPS from '../plugins/MyPluginCPS.js';
  import { useInjects } from '../utils/globals-inject';
  const router = useRouter();
  let { window, desktop, backend, mouseX, mouseY, tablet, mobile } = useInjects();
  const { svgAnimate, goToItem } = MyPluginCPS(false,mouseX, mouseY, desktop, window, router);

  const state = reactive({
    jsonData: [],
    isLoaded: false,
    desktop: 1200,
    isIE: /MSIE|Trident/.test(window.navigator.userAgent)
  });

  onBeforeMount(() => {
    //console.log('before mounted!!!!');
  });

  onMounted(() => {
    let url;
    const route = useRoute();

    state.slug = route.params.link;

    if (route.params.num) {
      state.preview_num = route.params.num;
      state.preview_title = route.params.title;

      url = backend + 'preview/work/' + state.preview_num + '/' + state.preview_title;
    } else {
      state.slug = route.params.link;
      url = backend + 'page/work/';
    }
    api(url)
      .then((data) => {
        state.jsonData = data.data;
        EventBus.$emit('menu_items', state.jsonData.site_details.menu_items);
        EventBus.$emit('footer', state.jsonData.site_details.tceg);
        EventBus.$emit('statement', state.jsonData.site_details.footer_statement);
        url = window.location.href;
        nextTick().then(() => {
          oldMounted();
        });
        //this.$nextTick(() => oldMounted());
      }).catch(error => {
        state.loading = false;
      });

  });

  const oldMounted = () => {
    let objectFitImages = require('object-fit-images');

    // Object-fit fix for IE
    objectFitImages('img.objectFitImage');

    state.isLoaded = true;
    EventBus.$emit('isLoaded', true);

    if (window.innerWidth >= state.desktop && !state.isIE) {
      let svgPathsWorkPage = [
        {
          id: 'svgGroup50__path1',
          tweenId: '1',
          speed: 2,
          delay: 0,
          trigger: ".workPage main",
          offset: '-200'
        },
        {
          id: 'svgGroup50__path2',
          tweenId: '1',
          speed: 2,
          delay: 0,
          trigger: ".workPage main",
          offset: '-200'
        },
        {
          id: 'svgGroup51__path1',
          tweenId: '2',
          speed: 2,
          delay: 0,
          trigger: ".workPage__workItems__item:first-child .workPage__workItems__item__info",
          offset: '-200'
        },
        {
          id: 'svgGroup51__path2',
          tweenId: '2',
          speed: 2,
          delay: 0,
          trigger: ".workPage__workItems__item:first-child .workPage__workItems__item__info",
          offset: '-200'
        },
        {
          id: 'svgGroup51__path3',
          tweenId: '2',
          speed: 2,
          delay: 0,
          trigger: ".workPage__workItems__item:first-child .workPage__workItems__item__info",
          offset: '-200'
        },
        {
          id: 'svgGroup51__path4',
          tweenId: '2',
          speed: 2,
          delay: 0,
          trigger: ".workPage__workItems__item:first-child .workPage__workItems__item__info",
          offset: '-200'
        },
        {
          id: 'svgGroup52__path1',
          tweenId: '3',
          speed: 2,
          delay: 0,
          trigger: ".workPage__workItems__item:nth-child(4) .workPage__workItems__item__imageWrapper",
          offset: '-200'
        },
        {
          id: 'svgGroup52__path2',
          tweenId: '3',
          speed: 2,
          delay: 0,
          trigger: ".workPage__workItems__item:nth-child(4) .workPage__workItems__item__imageWrapper",
          offset: '-200'
        },
        {
          id: 'svgGroup52__path3',
          tweenId: '3',
          speed: 2,
          delay: 0,
          trigger: ".workPage__workItems__item:nth-child(4) .workPage__workItems__item__imageWrapper",
          offset: '-200'
        },
        {
          id: 'svgGroup52__path4',
          tweenId: '3',
          speed: 2,
          delay: 0,
          trigger: ".workPage__workItems__item:nth-child(4) .workPage__workItems__item__imageWrapper",
          offset: '-200'
        },
        {
          id: 'svgGroup52__path5',
          tweenId: '3',
          speed: 2,
          delay: 0,
          trigger: ".workPage__workItems__item:nth-child(4) .workPage__workItems__item__imageWrapper",
          offset: '-200'
        },
        {
          id: 'svgGroup52__path6',
          tweenId: '3',
          speed: 2,
          delay: 0,
          trigger: ".workPage__workItems__item:nth-child(4) .workPage__workItems__item__imageWrapper",
          offset: '-200'
        }
      ]

      let svgTweensWorkPage = {};

      // Loop through SVG PATHS array / build tweens and scenes
      svgAnimate(svgPathsWorkPage, svgTweensWorkPage);
    }
  }

</script>
