<template>
  <component :is="item.website ? 'a' : 'span'" :href="item.website || ''" class="agency" target="_blank" rel="noreferrer noopener">
    <img :src=item.logo alt="Agency Logo" class="agency__logo">
    <h3 class="agency__name" :style="{color: item.colour}">{{ item.name }}</h3>
    <p class="agency__summary">{{ item.summary }}</p>
  </component>
</template>
<script >export default {
    name: 'agency',
    components: {
    },
    props: {
      item: Object
    },
    data() {
      return {

      }
    },
    methods: {

    }
  }</script>
