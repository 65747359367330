<template>
  <div id="testimonialVideo">

  </div>
</template>
<script >
  export default {
    name: 'video-component',
    components: {

    },
    props: {
      videoID: String,
    },
    data() {
      return {

      }
    },
    created() {

    },
    unmounted()
 {

    },
    mounted() {
      let testimonialVideo = jwplayer("testimonialVideo");

      testimonialVideo.setup({
        file: "https://cdn.jwplayer.com/manifests/" + this.videoID + ".m3u8",
        width: "100%",
        aspectratio: "16:9",
        image: "https://content.jwplatform.com/thumbs/" + this.videoID + ".jpg",
        customProps: { skin: { url: './css/player.css', name: 'svp' }, controls: true }

      });
    },
    methods: {

    }
  }
 </script>
