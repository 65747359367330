<template>
  <div class="testimonial container" :style="{backgroundImage: 'url(' + $props.testimonials.row_background.guid + ')'}" >
    <div class="testimonial__heading ">{{$props.testimonials.section_heading}}</div>
    <div class="testimonial__boxes">
      <div class="testimonial__box" v-for="testimony in $props.testimonials.testimonials">
        <div class="testimonial__box__ratingWrapper">
          <div class="testimonial__box__rating" v-for="stars in parseInt(testimony.rating)">
            <img src="../../assets/star.svg" alt="Star Icon" class="testimonial__box__image"/>
          </div>
        </div>
        <div class="testimonial__box__feedback">{{testimony.feedback}}</div>
        <div class="testimonial__box__client_name">{{testimony.client_name}}</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { computed, reactive } from "vue";
  import { useInjects } from '../../utils/globals-inject';
  export default {
    name: 'testimonial',
    components: {
    },
    props: {
      testimonials: {
        section_heading: Text,
        row_background: {
          guid: Text,
        },
        testimonials: {
          feedback: Text,
          rating: Text,
          client_name: Text
        }
      }
    },
    setup(props) {

      let { window, tablet } = useInjects();

      const state = reactive({

      });

    }
  }</script>

