<template>
  <div :id="id" class="loading-spinner">
    <img id="inner1" src="/src/assets/outer-ring.svg" class="outer" alt="Outer-Ring">
    <img src="/src/assets/inside-ring.svg" class="inside" alt="Inside-Ring">
    <img src="/src/assets/middle-ring.svg" class="middle" alt="Middle-Ring">
  </div>
</template>
<script >
  export default {
    name: 'LoadingSpinner',
    props: {
      id: String,
    }
  }</script>
