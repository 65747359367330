<template>
  <div id="siteWrapper" class="siteWrapper" @mousemove="mouseMove()">
    <router-view v-slot="{Component, route}">
      <transition name="slide">
        <component :is="Component" />
      </transition>
    </router-view>
    <footer-component></footer-component>
    <navigation></navigation>
    <resize-observer @notify="handleResize" :showTrigger="true" />
  </div>
</template>

<script>
  import FooterComponent from './components/widgets/footer-component';
  import Navigation from './components/widgets/navigation';
  import {  onMounted, onUnmounted } from "vue";
  import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
  import { TweenMax, TimelineMax } from "gsap";
  import { useInjects } from './utils/globals-inject';

  export default {
    components: {
      Navigation,
      FooterComponent
    },
    setup() {
      let { window, desktop, mouseX, mouseY, posX, posY, cursorFollower } = useInjects();

      const handleResize = ({ width, height }) => {
        window.innerWidth = width;
        window.innerHeight = height;
        //console.log("resized", window.innerWidth);
      }

      const mouseMove = (e) => {
        if (typeof e !== "undefined") {
          mouseX = e.clientX;
          mouseY = e.clientY;
        }
      }

      //window.addEventListener('resize', handleResize());
      //handleResize();

      onMounted(() => {
        ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
        if (window.innerWidth >= desktop) {

          cursorFollower = document.getElementById("mouseCursorFollower");
          if (cursorFollower != null) {
            TweenMax.to({}, 0.016, {
              repeat: -1,
              onRepeat: function () {
                posX += (mouseX - posX) / 9;
                posY += (mouseY - posY) / 9;

                TweenMax.set(cursorFollower, {
                  css: {
                    left: posX - 12,
                    top: posY - 12
                  }
                });
              }
            });
          }
          window.addEventListener('mousemove', mouseMove());
        }
      });

      onUnmounted(() => {
        //window.removeEventListener('resize', handleResize());
        window.removeEventListener('mousemove', mouseMove());
      });

      return {
        handleResize,
        mouseMove
      }
    
    }
  }
</script>

<style lang="scss">

  @import "scss/settings/variables";
  @import "scss/settings/extensions";

  .slide-leave-active {
    transition: opacity 0.5s ease;
    opacity: 0;
    animation: slide-out 0.5s ease-out forwards;
  }

  .slide-leave {
    opacity: 1;
    transform: translateX(0);
  }

  .slide-enter-active {
    animation: slide-in 0.5s ease-out forwards;
  }

  @keyframes slide-out {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-30px);
    }
  }

  @keyframes slide-in {
    0% {
      transform: translateY(-30px);
    }

    100% {
      transform: translateY(0);
    }
  }

</style>
